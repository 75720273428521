@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.page-tasks-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  // height: 93%;
  width: 100%;
  padding: 0 12px 12px 12px;

  &.tasks-list-modify {
    // height: calc(100% - 55px);
    border-top: 1px solid #d9dae1;
  }

  .header-tasks-list {
    z-index: 20;
    position: sticky;
    top: 0;
    padding-top: 12px;
    margin-bottom: 24px;
    background-color: $white;

    &__left-content {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
    }

    .types-views {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .preview-option {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
          width: 40px;
          height: 40px;
          background: #ebf2ff;
          border-radius: 39px;
          transition: all 0.25s;

          > svg {
            rect {
              stroke: $blue-selected-4;
            }

            line {
              stroke: $blue-selected-4;
            }
          }
        }
      }
    }

    &__input-search {
      // flex-grow: 1;
      // flex-shrink: 0;
      // flex-basis: 150px;
      // max-width: 270px;
      // // max-width: 340px;

      flex-shrink: 0;
      flex-basis: 280px;

      input::placeholder {
        font-size: 14px;
      }
    }

    &__btn-filter {
      width: 48px;
      height: 48px;
      border: 1px solid $gray-100;
      border-radius: 5px;
      margin-left: 8px;

      > svg {
        width: 24px;
        height: 24px;
        fill: $primary-light;
      }
    }

    &__date-range-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__filter {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        padding: 4px 8px;
        background-color: $white-50;
        border: 1px solid $gray-100;
        border-radius: 24px;
        white-space: nowrap;
        transition: all 0.25s;
        cursor: pointer;

        &:hover {
          background-color: $gray-75;
          border-color: transparent;
        }

        &.active {
          background-color: $blue-selected-4;
          border-color: transparent;
        }
      }
    }

    &__right-content {
      display: flex;
      gap: 30px;

      .types-views {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .preview-option {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active {
            width: 40px;
            height: 40px;
            background: #ebf2ff;
            border-radius: 39px;
            transition: all 0.25s;

            > svg {
              rect {
                stroke: $blue-selected-4;
              }

              line {
                stroke: $blue-selected-4;
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        height: 40px;
        right: 24px;
      }
    }

    .preview-options-tasks {
      display: flex;
      align-items: center;
      gap: 12px;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;

        > svg {
          fill: $primary-dark;
        }

        &.active {
          background-color: $primary-dark;

          > svg {
            fill: $white;
          }
        }
      }
    }

    .btn-create-task {
      width: 130px;
    }
  }

  .excel-table {
    height: 24px;
    margin-left: 24px;
    cursor: pointer;
  }

  .container-sections-tasks {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 92%;
    padding: 10px;
    max-height: 100%;
    overflow: auto;

    .loading-task-list {
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }

    .empty-state-task-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10vh 0;

      &__img {
        margin-bottom: 15px;
      }
    }

    .section-tasks-filters {
      display: flex;

      .section-tasks-filters-status {
        margin-right: auto;
      }
    }

    .section-tasks-list {
      // overflow: auto;

      &__category {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 100%;
        height: 192px;
      }

      .tasks-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-right: 46px;
        margin-bottom: 36px;

        .tasks-list-header {
          display: flex;
          margin-left: 34px;

          &__title {
            text-transform: capitalize;
            margin-right: 24px;
          }
        }

        .tasks-list-categories {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
    }

    .section-tasks-kanban {
      flex: 1 1;
      height: 100%;
      width: 100%;
      // padding-top: 20px;
      overflow-x: auto;

      &__board-container {
        display: flex;
        height: 100%;
        max-width: 400px;
        // padding: 10px 30px;
        gap: 13px;

        .board {
          display: flex;
          width: 290px;
          min-width: 290px;
          max-height: 100%;
          background: $white-50;
          flex-basis: 290px;
          flex-direction: column;
          border-radius: 5px;
          gap: 30px;
          overflow: auto;

          &__header {
            display: flex;
            position: sticky;
            height: 40px;
            top: 0px;
            z-index: 15;
            gap: 10px;
            padding: 10px;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            background: $white-50;
            border-radius: 8px 8px 0px 0px;

            &__text {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;

              .description-status {
                max-width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                direction: ltr;
              }
            }

            &__add-task {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 3px;

              > svg {
                cursor: pointer;
              }

              &__remove-board {
                display: flex;

                > svg {
                  transform: rotate(270deg);
                }
              }
            }
          }

          &__cards {
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin-top: -25px;
            gap: 10px;
            // overflow-y: auto;

            .container-card {
              background: #ffffff;
              border-radius: 5px;
            }
          }
        }

        &__frame-container {
          flex-basis: 290px;
          min-width: 290px;

          .new-frame {
            width: 100%;

            .color-select {
              position: relative;
              display: flex;
              align-items: center;
              cursor: pointer;

              .color-preview {
                width: 36px;
                height: 36px;
                border: 2px solid #ccc;
                border-radius: 4px;
                margin-right: 10px;
                transition: border-color 0.3s;

                &:hover {
                  border-color: #007bff;
                }
              }

              .color-picker {
                position: absolute;
                top: 45px;
                z-index: 10;
                border: 1px solid #ddd;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                background-color: white;
                padding: 10px;
              }
            }

            .editable-edit {
              display: flex;
              flex-direction: column;
              background-color: $white-50;
              border-radius: 5px;
              padding: 10px;
              gap: 10px;

              &__footer {
                display: flex;
                align-items: center;
                gap: 8px;

                > button {
                  cursor: pointer;
                  border-radius: 5px;
                  height: 40px;
                  outline: none;
                  background-color: $blue-selected-4;
                  color: $white;
                  border: none;
                  transition: 100ms ease;
                  padding: 10px;
                  border: 1px solid $blue-selected-4;

                  &:hover {
                    background-color: $secondary-02-light;
                  }

                  &:disabled {
                    background-color: $secondary-02-light;
                    cursor: not-allowed;
                  }
                }

                &__close {
                  cursor: pointer;

                  > svg {
                    fill: $gray-200;
                  }
                }
              }
            }

            .add-frame {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 290px;
              height: 48px;
              background: $white-50;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .section-tasks-timeline {
      flex: 1 1;
      height: 100%;
      width: 100%;
      padding-top: 40px;
      // overflow-x: auto;
      overflow-y: hidden;

      &__timeline-container {
        height: 100%;
        // max-width: 100%;
        max-width: 800px;
        display: flex;
        // flex-direction: column;
        position: relative;

        .date-filter-container {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12%;
            height: 38px;
            left: 0%;
            right: 0%;
            top: 0%;
            bottom: 0%;
            margin-top: -45px;
            background: #f4f4f4;
          }
        }

        // .columns {
        //   display: flex;
        //   gap: 10px;
        //   flex-direction: column;
        //   width: 100%;
        //   height: 100%;
        //   overflow: auto;
        //   background: linear-gradient(
        //     /* to right,
        //     #f4f4f4 14%,
        //     #fff 14%,
        //     #fff 25%,
        //     #f4f4f4 25%,
        //     #f4f4f4 38%,
        //     #fff 38%,
        //     #fff 50%,
        //     #f4f4f4 50%,
        //     #f4f4f4 63%,
        //     #fff 63%,
        //     #fff 74%,
        //     #f4f4f4 74%,
        //     #f4f4f4 86%,
        //     #fff 77% */
        //       to right,
        //     #f4f4f4 12%,
        //     #fff 12%,
        //     #fff 25.1%,
        //     #f4f4f4 25.1%,
        //     #f4f4f4 37.1%,
        //     #fff 37.1%,
        //     #fff 50.3%,
        //     #f4f4f4 50.3%,
        //     #f4f4f4 62.3%,
        //     #fff 62.3%,
        //     #fff 75.5%,
        //     #f4f4f4 75.5%,
        //     #f4f4f4 87.5%,
        //     #fff 77%
        //   );

        //   .category-task-container {
        //     display: flex;
        //     justify-content: flex-end;
        //     padding-right: 30px;
        //     position: relative;
        //     margin: 10px 0;
        //     width: 50%;

        //     &:nth-child(odd) {
        //       align-self: flex-end;
        //       justify-content: flex-start;
        //       padding-left: 30px;
        //       padding-right: 0;
        //     }

        //     .overdue-task {
        //       display: flex;
        //       width: fit-content;
        //       padding: 2px 8px;
        //       margin-right: 8px;
        //       border-radius: 4px;
        //       box-shadow: 0px 4px 23px rgb(0 0 0 / 10%);
        //       background-color: $red;
        //       transition: all 0.25s;
        //     }

        //     .task {
        //       display: flex;
        //       gap: 12px;
        //       width: 800px;
        //       height: 40px;
        //       padding: 0 8px;
        //       background-color: $white;
        //       border-bottom: 1px solid $gray-75;
        //       border: 1px solid rgba(0, 0, 0, 0.1);
        //       box-shadow: 0px 4px 23px rgb(0 0 0 / 10%);
        //       transition: all 0.25s;

        //       .task-title {
        //         display: flex;
        //         flex-grow: 1;
        //         align-items: center;
        //         padding: 8px 0;

        //         &__title {
        //           flex-grow: 1;
        //           display: flex;

        //           &__text {
        //             flex-grow: 1;
        //             width: 0px;
        //             min-width: 100px;
        //             padding: 9px 0;
        //             @include text-overflow;
        //           }
        //         }
        //       }

        //       .task-tags {
        //         display: flex;
        //         align-items: center;
        //         gap: 5px;

        //         > svg {
        //           fill: $gray-180;
        //         }
        //       }

        //       .task-profiles {
        //         display: flex;
        //         align-items: center;
        //         width: unset;
        //         gap: 5px;

        //         .profile-list {
        //           flex-grow: 1;
        //           justify-content: space-between;
        //         }
        //       }

        //       .task-deadline {
        //         display: flex;
        //         align-items: center;
        //       }

        //       &:hover {
        //         background-color: $gray-50;
        //       }

        //       &--scheme-blue {
        //         border-left: 4px solid $primary-dark;
        //       }

        //       &--scheme-yellow {
        //         border-left: 4px solid $yellow;
        //       }

        //       &--scheme-green {
        //         border-left: 4px solid $green;
        //       }

        //       &--scheme-red {
        //         border-left: 4px solid $red;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .page-tasks-list {
    .container-sections-tasks {
      .section-tasks-kanban {
        &__board-container {
          .board {
            max-height: 600px;
          }
        }
      }

      .section-tasks-timeline {
        &__timeline-container {
          .columns {
            .category-task-container {
              position: relative;
              flex-direction: column;
              gap: 1px;
              margin-left: 34px;
              margin-right: 34px;
              padding-top: 15px;

              .task {
                width: 700px;
              }
            }
          }
        }
      }
    }
  }
}
