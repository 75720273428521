.table-create-cases-v2 {
  overflow-y: auto;
  margin: 0px 16px;
  min-height: calc(100% - 71px);
  .pagination-table {
    display: none;
  }
  &__table {
    thead button.order-button {
      display: none;
    }
    thead .flex-container {
      width: 203px;
    }
  }

  &__select {
    .select-search-btn {
      background-color: #ffffff4f;
      svg.select-search-btn__iconBase {
        fill: #455cc7;
      }
    }
  }
  &__selects-container {
    display: flex;
    gap: 14px;
    padding-bottom: 16px;
  }
}
