@import "./../../../../styles/colors";

.modal-create-shift-worker {
    .modal-create-shift-worker-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__section1,
        &__section2 {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .modal__footer {
        display: flex;
        align-items: flex-start;
        flex-direction: row-reverse;
        gap: 16px;
        padding: 15px 0;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
