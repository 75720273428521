@import "./../../../../styles/colors";

.modal-update-type {
    .modal {
        &__body {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        &__footer {
            justify-content: flex-end;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        grid-row-gap: 16px;
        row-gap: 16px;
        padding: 4px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track-piece {
            width: 0px;
            height: 0px;
        }

        &__first,
        &__second,
        &__third {
            display: flex;
            justify-content: space-between;
            gap: 16px;
        }

        &__second {
            .field-container-radio {
                display: flex;
                align-items: center;
                gap: 70px;
                margin-top: 10px;

                .container-input-radio {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > label {
                        display: flex;
                        align-items: center;
                        color: #131313;
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 112.5%;
                        letter-spacing: 0.08px;
                        margin-left: 5px;

                        > input {
                            cursor: pointer;
                            width: 18px;
                            height: 18px;

                            &:disabled {
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

        &__third {
            .container-buttons {
                display: flex;
                gap: 15px;
            }

            .remove-reason,
            .add-reason {
                display: flex;
                align-items: center;
                margin-top: 15px;
                margin-bottom: 10px;
                gap: 10px;

                > svg {
                    cursor: pointer;
                }
            }

            .remove-reason {
                > svg {
                    path {
                        stroke: $red;
                    }
                }
            }
        }

        .divisor {
            width: 100%;
            height: 0px;
            left: 1px;
            top: 272px;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
