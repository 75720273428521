@import "./../../../../styles/colors";

.drawer {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;
    // transition to hide (bg)
    transition: opacity 0.15s 0.25s;

    &__div {
        position: absolute;
        left: -320px;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        background: white;
        width: 439px;
        // transition to hide (drawer)
        transition: left 0.4s;
        box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 16px;

        &__header {
            display: flex;
            flex-direction: column;
            padding-bottom: 16px;
            border-bottom: 1px solid $gray-75;

            &__title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > div {
                    height: 24px;

                    > svg {
                        fill: $gray-200;
                        cursor: pointer;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: auto;
        }

        &__footer {
            display: flex;
            padding: 24px;
            margin-top: auto;
            border-top: 0.5px solid $gray-100;
        }

        &__clickable {
            cursor: pointer;
            display: flex;
        }

        &__bubble {
            background-color: $gray-500;
            color: white;
            width: 32px;
            height: 26px;
            padding: 4px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__content-list {
            padding: 12px;
        }

        &__list {
            &__item {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 4px;
                cursor: pointer;
            }
        }

        &.dark-mode{
            background-color: #222338;

            .typography-h2{
                color: $white;
            }
        }
    }
}

.drawer_title {
    text-align: center;
    width: 200px;
}

.drawer-visible {
    opacity: 1;
    pointer-events: auto;
    // transition to show (bg)
    transition: opacity 0.15s;

    .drawer-div-visible {
        left: 0;
        // transition to show (drawer)
        transition: left 0.4s 0.15s;
    }
}

.drawer-search {
    margin-top: 12px;
    margin-bottom: 12px;
}

@keyframes drawerAni {
    0% {
        right: 100%;
    }

    100% {
        right: 0;
    }
}
