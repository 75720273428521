@import "./../../../styles/colors";

.page-login {
  &.page-split {
    .page-split__section {
      &--a {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        padding: 36px;
        background-color: $white;

        .logo {
          display: inline-flex;
          align-items: center;
          gap: 16px;

          .oficial_mark {
            position: relative;
            bottom: 20px;
            right: 15px;
          }
        }

        .main {
          display: flex;
          flex-direction: column;
          // padding: 0 62px;
          // margin-top: 97px;
          margin: auto;

          &__header {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-right: 61px;

            h1 {
              font-size: 32px;
            }
          }

          &__content {
            display: flex;
            flex-direction: column;
            margin-top: 17%;

            .signin-form {
              display: flex;
              flex-direction: column;
            }

            .field-container-wrapper {
              display: flex;
              flex-direction: column;
              gap: 22px;

              .field-container {
                &__title {
                  color: $gray-500;
                }

                .symbol-warning {
                  color: $secondary-02-light;
                }

                .input-wrapper__left-icon {
                  svg {
                    fill: $gray-200;
                  }
                }
              }
            }

            .btn-login {
              flex-grow: 1;
              margin-top: 22px;

              &--loading {
                pointer-events: none;
              }
            }

            .btn-forgot-password {
              align-self: flex-end;
              margin-top: 8px;

              &:focus {
                outline: none;
                text-decoration: underline;
              }
            }

            .msg-create-account {
              margin-top: 23px;
              text-align: center;
              // color: $gray-200;

              &__btn {
                color: $primary-light;
                white-space: nowrap;

                &:focus {
                  outline: none;
                  text-decoration: underline;
                }
              }
            }
          }

          .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            bottom: 0;
            padding-bottom: 30px;
            gap: 12px;
          }
        }
      }
      &--b {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color: $gray-75;
        background: url("../../../assets/images/BG_IngWeb.png") lightgray 50% / cover no-repeat;

        .swiper-carousel {
          position: relative;
          min-width: 423px;
          max-width: 500px;
          width: 55%;
          height: 100vh;
          margin: 80px 0;

          .swiper-slide {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px 40px 20px;

            &__img {
              flex-grow: 1;
              position: relative;
              justify-content: center;
              display: flex;
              width: 100%;
              min-height: 430px;
              max-height: 450px;
              margin-top: auto;

              .slideshow-img1 {
                position: absolute;
                top: 0;
                right: 0;
                animation: floating 4s 1s infinite ease-in-out;
              }

              .slideshow-img2 {
                position: absolute;
                bottom: 0;
                animation: floating 6s infinite ease-in-out;
              }

              @keyframes floating {
                0% {
                  padding-bottom: 0;
                }
                50% {
                  padding-bottom: 5px;
                }
                100% {
                  padding-bottom: 0;
                }
              }
            }

            &__text {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 20px;
              margin-top: auto;
              text-align: center;
            }
          }

          .swiper-pagination-bullet {
            width: 78px;
            height: 2px;
            border-radius: 5px;
            background-color: $primary-dark;
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 5;
            height: 100%;
            width: 30px;
          }

          &::before {
            left: 0;
            background: rgb(233, 235, 245);
            background: linear-gradient(90deg, rgba(233, 235, 245, 1) 0%, rgba(252, 176, 69, 0) 100%);
          }

          &::after {
            right: 0;
            background: rgb(233, 235, 245);
            background: linear-gradient(270deg, rgba(233, 235, 245, 1) 0%, rgba(252, 176, 69, 0) 100%);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .page-login.page-split {
    .page-split__section--a {
      flex-grow: 1;
      min-width: 300px;

      .main {
        padding: 0 5%;
        margin-top: 20%;

        &__header {
          padding-right: 5%;
        }

        &__content {
          margin-top: 10%;
        }
      }
    }

    .page-split__section--b {
      display: none;
    }
  }
}
