@import "./../../../../styles/colors";

.typography-h6 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;

  &.text-comment {
    font-size: 14px;
    line-height: 20px;
  }

  &.header-column{
    white-space: nowrap;
    line-height: 20px;
    text-transform: uppercase;
  }

  // WEIGHTS
  &--300 {
    font-weight: 300;
  }

  &--400 {
    font-weight: 400;
  }

  &--500 {
    font-weight: 500;
  }

  &--600 {
    font-weight: 600;
  }

  &--bold {
    font-weight: bold;
  }

  // COLORS
  &--color-primary-dark {
    color: $primary-dark;
  }

  &--color-primary-light {
    color: $primary-light;
  }

  &--color-blue-selected-4 {
    color: $blue-selected-4;
  }

  &--color-blue-selected-5 {
    color: $blue-selected-5;
  }

  &--color-gray-125 {
    color: $gray-125;
  }
  
  &--color-gray-175 {
    color: $gray-175;
  }

  &--color-gray-180 {
    color: $gray-180;
  }

  &--color-gray-200 {
    color: $gray-200;
  }

  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-400 {
    color: $gray-400;
  }

  &--color-gray-600 {
    color: $gray-600;
  }

  &--color-white {
    color: $white;
  }

  &--color-primary-dark {
    color: $primary-dark;
  }

  &--color-green {
    color: $green;
  }

  &--color-yellow {
    color: $yellow;
  }

  &--color-red {
    color: $red;
  }

  &--color-orange {
    color: $orange;
  }

  &--color-purple {
    color: $purple;
  }
}
