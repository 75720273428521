@import "./../../../../styles/colors";

.modal-create-case {
  overflow: auto;
  max-height: 90%;

  .modal {
    &__body {
      display: flex;
      flex-direction: column;
      gap: 14px;
      overflow: auto;
      padding: 0 4px;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }

    &__footer {
      justify-content: flex-end;
    }
  }

  &__content {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .container-two-collumn {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .container-full-line {
      display: grid;
      grid-template-columns: repeat(3, minmax(150px, 500px));
      // display: flex;
      // justify-content: space-between;
      gap: 16px;
      width: 100%;

      // .field-container-case-channel {
      //   width: 48%;
      // }
    }

    .grid-span-2 {
      grid-column: span 2;
    }

    .create-case-select {
      .select-search-btn {
        padding: 12px 8px;
        // border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .modal-create-case__dropdown {
      width: 100%;

      .dropdown-channel-btn,
      .dropdown-priority-btn {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .field-container-case-client-list {
    }

    .field-container-case-request-type {
    }

    .field-container-case-variable {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      &-control {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px;
        background: $white;
        // background: rgba(230, 230, 230, 0.2);
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;

        svg {
          transform: rotate(0deg);

          path {
            fill: $gray-180;
          }
        }
      }

      &-fields {
        width: 100%;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(100px, 31%));
        grid-row-gap: 16px;
        row-gap: 16px;
        padding: 16px 8px;
        gap: 16px;

        .custom-field-container {
          .field-container__header {
            .field-container__icon-container {
              > svg {
                height: 17px;
                width: 17px;
              }
            }
          }
        }

        .field-below-textarea {
          grid-column: 1 / -1; 
          width: 100%;
        }

        .textarea-container {
          grid-column: 1 / -1;
          width: 100%;
        }
      }

      &.active-variable {
        .field-container-case-variable-control {
          background: #ebf2ff;
          border-radius: 4px 4px 0px 0px;

          svg {
            transform: rotate(-180deg);

            path {
              fill: $blue-selected-4;
            }
          }
        }

        .field-container-case-variable-fields {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-width: 0px 1px 1px 1px;
          border-radius: 0px 0px 4px 4px;
        }
      }
    }

    .field-container-case-subject {
    }

    .field-container-case-desc {
      &__input {
        min-height: 110px;
        max-height: 200px;
        // font-size: 16px;
      }
    }

    .field-container-case-date-time {
      &__container {
        display: flex;
        gap: 8px;
      }
    }

    .field-container-case-priority {
      &__list {
        > button {
          // height: 40px;

          .dropdown-priority-btn-container {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .dropdown-priority-list {
          &.active {
            top: 33px;
          }

          > button:first-child {
            padding-top: 8px;
          }
        }
      }
    }

    .field-container-case-status {
      .dropdown-status-list {
        &.active {
          top: 34px;
        }
      }

      &__container {
        display: flex;

        .dropdown-status {
          display: flex;
          align-items: center;
          // height: 40px;
        }
      }
    }

    .dropzone-container {
      grid-column: span 2;
    }

    .disabled-field {
      pointer-events: none;
      opacity: 0.31;
    }
  }

  &__btn-confirm {
    // flex-grow: 1;
    display: flex;
  }

  &__btn-cancel.btn-primary {
    // flex-grow: 1;
    display: flex;
    background: $white;

    border: 1px solid $red-2;
    border-radius: 5px;

    &:hover {
      background: $white-50;
    }
  }
}

.addcontactWarning {
  .span-warning {
    display: flex;
    gap: 4px;
    margin-top: 8px;
    margin-bottom: 16px;

    > h5 {
      width: 95%;
    }
  }

  .buttons-addContactWarning {
    width: 245px;
    display: flex;
    gap: 8px;
  }
}

.modal-add-contact {
  gap: 24px;
  overflow: unset;

  .section-addLink-contact {
    gap: 16px;
    display: flex;
    flex-direction: column;

    .container-addContact {
      width: 100%;

      .select-search-list {
        max-height: 200px;
      }
    }
  }

  .modal__footer {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 700px) {
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .modal-create-case {
    width: 100%;
    height: 100%;
    max-height: max-content;

    &__content {
      .container-full-line {
        width: 100%;
      }

      .grid-span-2,
      .field-container {
        width: 100%;
      }

      .modal-create-case__dropdown {
        width: 100%;
      }
    }
  }
}
