@import "./../../../../styles/colors";

.typography-hyperlink {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;


  &.menu-tabs__hyperlink{
    font-size: 14px;
    line-height: 22px;

    @media only screen and (max-width: 700px) {
      font-size: 14px;
    }
  }

  

  // WEIGHTS
  &--bold {
    font-weight: bold;
  }

  &--400 {
    font-weight: 400;
  }

  &--500 {
    font-weight: 500;
  }

  &--700 {
    font-weight: 700;
  }

  // COLORS
  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-200 {
    color: $gray-200;
  }

  &--color-gray-180 {
    color: $gray-180;
  }

  &--color-primary-dark {
    color: $primary-dark;
  }
}
