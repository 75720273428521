.tippy-box[data-theme~='ingdesk-dark'] {
    display: flex;
    align-items: center;
    gap: 4px;

    background: rgba(24, 24, 27, 0.733333);
    border-radius: 4px;

}

.tippy-box[data-theme~='ingdesk-dark'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: rgba(24, 24, 27, 0.733333);
}
.tippy-box[data-theme~='ingdesk-dark'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: rgba(24, 24, 27, 0.733333);
}
.tippy-box[data-theme~='ingdesk-dark'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: rgba(24, 24, 27, 0.733333);
}
.tippy-box[data-theme~='ingdesk-dark'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: rgba(24, 24, 27, 0.733333);
}

