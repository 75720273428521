@import "./reset";
@import "./colors";
@import "./fonts";
@import "./tippy";
@import "./animation";
@import "./mixins";
@import "./icons";

* {
  box-sizing: border-box;
}

body {
  background: $white;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

#root {
  width: 100%;
  height: 100vh;
}

.container-root-pages{
  height: calc(100% - 53px);
  display: flex;

  .page-modify{
    // width: calc(100% - 60px);
    border-top: 1px solid #d9dae1;

  }

  &__main {
    width: calc(100% - 60px);
    height: 100%;
    background: #E5E5E5;
    // padding: 16px 0 0 16px;

    @media screen and (max-width: 700px) {
      padding: 0;
    }
  }
}

// CUSTOM SCROLLBAR
/* width */
* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* track */
  &::-webkit-scrollbar-track {
    background: $gray-100;
    border-radius: 100px;
  }

  /* handle */
  &::-webkit-scrollbar-thumb {
    background: $blue-selected-4;
    border-radius: 100px;
  }
}
