@import "./../../../styles/colors";

.profile-list {
  display: flex;
  gap: 8px;
  height: 22px;

  &__profiles {
    display: flex;

    gap: 6px;

    &__img:first-child {
      margin-left: 0;
    }

    &__img {
      margin-left: -10px;
      width: 25px;
      height: 25px;
      // width: 22px;
      // height: 22px;

      > h2 {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__img--0 {
      z-index: 5;
    }
    &__img--1 {
      z-index: 4;
    }
    &__img--2 {
      z-index: 3;
    }
    &__img--3 {
      z-index: 2;
    }
  }

  &__popover {
    display: flex;
    position: relative;
    gap: 8px;

    
    // position: fixed;
    // top: 51%;
    // left: 16%;

    .btn-add-elements {
      transition: all 0.25s;

      &--error {
        svg {
          path {
            stroke: $red;
          }
        }
      }

      &:focus-within {
        &--error {
          svg {
            path {
              stroke: $primary-dark;
            }
          }
        }
      }
    }

    &__view {
      width: 24px;
      height: 24px;

      > button {
        // width: 24px;
        // height: 24px;

        width: 19px;
        height: 16px;
        font-weight: 500;
        font-family: "Inter";
        font-style: normal;
        font-size: 14px;
        line-height: 112.5%;
        top: 4px;
        color: #757575;
      }
    }

    > button {
      width: 24px;
      height: 24px;
    }
  }

  .popover-profile {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // position: absolute;
    // top: 38px;
    // left: -245px;
    // z-index: 10;
    //padding: 16px;
    width: 253px;
    height: 330px;
    background-color: $white;
    // border: 1px solid $primary-dark;
    // border-radius: 8px;

    // &.popover-profile--add {
    //   left: -225px;
    // }

    // &.popover-profile--view {
    //   left: -245px;
    // }

    // &.active {
    //   display: flex;
    // }

    .input-container {
      .input-wrapper__input {
        height: 40px;
      }
      svg {
        fill: $secondary-02-light;
        stroke: none;
      }
    }
    &__view {
      .popover-profile__content {
        cursor: default;

        &.active {
          cursor: default;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-right: 6px;
      max-height: 288px;
      overflow: auto;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 4px 8px;
      cursor: pointer;

      &.active {
        background-color: $gray-75;
        border-radius: 4px;
        cursor: pointer;
      }

      svg {
        cursor: pointer;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $primary-dark;
      }
    }
  }

  .list-profile__after {
    padding: 10px;
    height: 10px;
    background-color: $primary-dark;
    transform: rotate(45deg);
    position: absolute;
    bottom: -30px;
    left: calc(50% - 10px);
    z-index: 2;

    // &.list-profile__after--add {
    //   left: calc(50% + 2px);
    // }

    // &.list-profile__after--view {
    //   left: calc(50% - 20px);
    // }

    // &.list-profile__after--view-only {
    //   left: calc(50% - 8px);
    // }
  }

  &--view-only {
    .popover-profile__content.active {
      cursor: default;
    }
  }
}
