@import "./../../../styles/colors";

.delete {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    > svg {
      fill: $red;
    }
  }

  &__popover {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 8px 20px 8px;
    width: 300px;

    &__text {
      width: 193px;
      align-self: center;
      margin-top: -28px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      gap: 16px;
      align-self: center;

      .btn-delete {
        background-color: $red;
        transition: background-color 0.25s;

        &:hover {
          background-color: $red;
          opacity: 0.6;
        }
      }
    }

    > svg {
      align-self: flex-end;
      cursor: pointer;
    }
  }
  .tippy-arrow {
    display: none;
  }

  .tippy-content {
    padding: 0;
  }

}
