@import "./../../../../styles/colors";

.modal-create-company {
    overflow: auto;

    .container-footer {
        display: flex;
        margin-top: 10px;
        grid-gap: 12px;
        gap: 12px;
    }

    .modal {
        &__body {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        &__footer {
            justify-content: flex-end;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .modal-create-icon {
            // position: absolute;
            border-radius: 9999px;
            width: 80px;
            height: 80px;
            left: 24px;
            top: 80px;
            background: rgba(0, 0, 0, 0.1);

            .icon-shop {
                margin-top: 15px;
                margin-left: 15px;
            }
        }

        .container-full-line {
            display: flex;
            justify-content: space-between;
            gap: 16px;
        }
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
