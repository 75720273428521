@import "./../../../../styles/colors";

.case-details-task {
  .container-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    background-color: $red-light2;
    border-radius: 50%;
  }

  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: $gray-180;
  }

  &__container-button {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    padding-top: 16px;
    border-top: 1px solid $gray-100;
    justify-content: flex-end;

    .button-add-task {
      font-size: 16px;
      margin-left: auto;
      margin-right: 32px;
    }
  }

  .container-empty-state {
    display: flex;
    background-color: $blue-selected-3;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
}
