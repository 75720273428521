@import "./../../../styles/colors";

.page-solicitation {
    background-color: #ffffff;
    padding: 24px;
    height: 100%;
    width: 100%;

    .header-solicitation {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 0 0 16px 0;
        border-bottom: 1px solid $gray-75;
        background-color: #ffffff;
        position: sticky;
        z-index: 20;
        top: 0;

        &__title {
            display: flex;
            align-items: center;
            gap: 16px;

            .header-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                margin-left: 10px;
            }
        }

        &__option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            margin-left: auto;
        }

        .input-search-motives {
            text-transform: lowercase;
        }
    }

    .page-solicitation-body {
        display: flex;
        gap: 18px;
        height: 94%;

        .emptySate-solicitation {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .container-sections-solicitation {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 35px;

            .panel-registration {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // padding: 0px;
                gap: 10px;
                width: 315px;
                flex-grow: 1;
                margin-top: 10px;

                .indicatorArrow {
                    margin-right: 10px;

                    &.active {
                        > svg {
                            transform: rotate(180deg);
                            cursor: pointer;
                        }
                    }
                }
            }

            border-right: 1px solid rgba(0, 0, 0, 0.1);
            width: 420px;

            &__footer {
                width: 370px;
                height: 87px;
                background: #ffffff;

                .buttons-container-solicitation {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 16px;
                    width: 322px;
                    height: 40px;
                    margin-top: 20%;
                    margin-left: 100px;

                    &__btn-cancel.btn-primary {
                        display: flex;
                        background: $white;

                        border: 1px solid $red-2;
                        border-radius: 5px;

                        &:hover {
                            background: $white-50;
                        }
                    }
                }
            }
        }

        .solicitation-list-content {
            overflow: auto;
            flex-grow: 1;
            margin-top: 24px;
            width: 100%;
            height: 90%;
            padding: 10px;

            .card {
                margin-top: 20px;

                .card-header {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px 16px;
                    gap: 16px;
                    width: 100%;
                    height: 40px;
                    background: #ffffff;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 4px;

                    > svg {
                        //* SÓ PARA DEMONSTRAÇÃO
                        transform: rotate(360deg);
                        cursor: pointer;
                    }

                    &.active {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 8px 16px;
                        gap: 16px;
                        width: 100%;
                        height: 40px;
                        background: #ebf2ff;
                        border-radius: 4px 4px 0px 0px;

                        > svg {
                            //* SÓ PARA DEMONSTRAÇÃO
                            transform: rotate(180deg);
                            fill: #455cc7;
                            cursor: pointer;
                        }
                    }

                    &__title {
                        width: 100%;
                        height: 19px;
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 120%;
                        color: #455cc7;
                    }

                    &__btn-options {
                        > svg {
                            transform: rotate(270deg);
                        }
                    }
                }

                .height_160 {
                    height: 160px;
                }

                .height_93 {
                    height: 93px;
                }

                .height_270 {
                    height: 270px;
                }

                .card-container-content {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 16px;
                    width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 4px;

                    &__content {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 0px;
                        gap: 16px;
                        width: 100%;

                        .text {
                            width: 243.33px;
                            height: 40px;
                            font-family: "Inter";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #757575;
                        }
                    }

                    &__content-reasons {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 0px;
                        gap: 8px;
                        width: 100%;
                        height: 144px;
                        margin-top: 20px;

                        .container-reasons {
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 12px 16px;
                            gap: 12px;
                            width: 100%;
                            height: 120px;
                            background: rgba(255, 255, 255, 0.31);
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 5px;

                            &__tags {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                padding: 0px;
                                width: 100%;

                                .list-tags {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                    padding: 0px;
                                    grid-gap: 6px;
                                    gap: 6px;
                                    width: 100%;
                                    flex-wrap: wrap;
                                    max-height: 6rem;
                                    overflow-x: hidden;
                                    overflow-y: auto;

                                    .tag-item {
                                        display: flex;
                                        align-items: center;
                                        grid-gap: 4px;
                                        gap: 4px;
                                        padding: 4px 4px 4px 8px;
                                        background-color: #f4f4f4;
                                        border-radius: 24px;
                                        min-width: max-content;
                                        margin-right: 8px;
                                        margin-bottom: 4px;

                                        .close {
                                            height: 20px;
                                            width: 20px;
                                            background: #bfc3ca;
                                            color: #fff;
                                            border-radius: 50%;
                                            display: inline-flex;
                                            justify-content: center;
                                            align-items: center;
                                            margin-left: 0.5em;
                                            cursor: pointer;

                                            svg {
                                                width: 13px;
                                                height: 13px;
                                            }
                                        }

                                        .text {
                                            font-family: "Inter", sans-serif;
                                            font-style: normal;
                                            font-size: 14px;
                                            line-height: 112.5%;
                                            display: flex;
                                            align-items: flex-end;
                                            color: #455cc7;

                                            border-top: 2px solid transparent;
                                            border-bottom: 2px solid transparent;
                                        }
                                    }

                                    .tags-input {
                                        flex-grow: 1;
                                        padding: 0.5em 0;
                                        border: none;
                                        outline: none;
                                        margin-left: 10px;
                                        font-family: "Inter", sans-serif;
                                        font-size: 14px;
                                        color: #27282e;
                                        transition: all 0.25s;
                                    }

                                    .tags-input::placeholder {
                                        color: #bcbfd0;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .options {
                            position: absolute;
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            display: none;
                            max-height: 160px;
                            overflow-y: auto;
                            border: 0.05em solid #777;
                            border-radius: 0.25em;
                            width: 600px;
                            // top: calc(100% + 0.25em);
                            background-color: white;
                            z-index: 100;
                            overflow: auto;

                            &.active {
                                display: block;
                            }
                        }
                    }

                    .margintop_20 {
                        margin-top: 20px;
                    }

                    .buttons-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                        padding: 0px;
                        gap: 8px;
                        width: 100%;
                        height: 40px;

                        &__btn-cancel.btn-primary {
                            display: flex;
                            background: $white;

                            border: 1px solid $red-2;
                            border-radius: 5px;

                            &:hover {
                                background: $white-50;
                            }
                        }
                    }

                    .options-motives {
                        .autoContainer {
                            align-items: space-between;
                            justify-content: center;
                            width: 300px;
                            max-height: 100px;
                            background-color: #fff;
                            box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
                            border-radius: 0 0 5px 5px;
                            margin-top: -130px;
                            overflow: hidden;
                            overflow-y: auto;

                            .autoContainer::-webkit-scrollbar {
                                display: none;
                            }

                            .option {
                                display: flex;
                                align-items: center;
                                min-height: min-content;
                                padding: 10px;
                                cursor: pointer;
                                text-align: left;

                                &__span {
                                    font-family: "Inter", sans-serif;
                                    font-style: normal;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: #828292;
                                }

                                &:hover {
                                    background-color: #ebf2ff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .page-solicitation {
        .header-solicitation {
            margin-bottom: 18px;
        }
    }
}

@media screen and (max-height: 700px) {
    .page-solicitation {
        .container-sections-solicitation {
            .solicitation-list-content {
                .emptySate-solicitation {
                    position: absolute;
                    width: 199px;
                    height: 168px;
                    left: 900px;
                    top: 360px;
                }
            }

            // .panel-registration {
            //     overflow: auto;
            //     padding: 7px;

            //     &::-webkit-scrollbar {
            //         width: 3px;
            //     }
            // }

            height: 540px;
        }
    }
}

@media screen and (max-height: 630px) {
    .page-solicitation {
        .container-sections-solicitation {
            .panel-registration {
                max-height: 400px;
                overflow: auto;
                height: 300px;
                padding: 10px 10px;

                &::-webkit-scrollbar {
                    width: 3px;
                }
            }
        }
    }
}
