@import "../../styles/colors";

.modal-create-batch-cases {
  height: 100%;
  background-color: #ffffff;
  border-top: 1px solid #d9dae1;
  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 73px);
    &__file-input {
      &__section {
        &__drop-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          h6 {
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            color: #757575;
            padding-bottom: 15px;
          }

          .btn-create-batch-cases {
            margin-top: 15px;
            &:disabled {
              border: 1px solid #d9dae1;
            }
          }
          &__file-example {
            padding-top: 48px;
            display: flex;
            flex-direction: column;
            &__download-file {
              border: 1px solid #0000001a;
              border-radius: 4px;
              padding: 8px 16px;
              margin-top: 16px;

              .modal-create-batch-cases__content__file-input__file-selected {
                padding-top: 0px;
                gap: 42px;
                button {
                  font-family: "Inter", sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 15.75px;
                  color: #346aff;
                }
              }
            }
            &__title {
              font-family: "Inter", sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
              text-align: center;
              color: #757575;
            }
          }
        }
      }
      &__section.empty {
        height: 100%;
      }
      &__section.container.empty {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
      }
      &__drop-zone {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        padding: 16px 154px;
        border-radius: 4px;
        border: 1px dashed #e5e5e5;
        background-color: #fefefe;
        cursor: pointer;

        h5 {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #111214;
        }

        span {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #455cc7;
          text-decoration: underline;
        }

        &--error {
          border: 1px dashed $red-2;
        }

        &.empty {
          height: 100%;
        }
      }

      &__file-selected {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        gap: 293px;
        .colored-svg {
          svg {
            path {
              fill: #757575;
            }
          }
        }

        h4 {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 15.75px;
          color: #131313;
        }

        span {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 13.5px;
          color: #757575;
        }

        &__file-info {
          display: flex;
          align-items: flex-start;
          gap: 16px;
        }
        &__container-file-info {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        &__buttons {
          display: flex;
          align-items: center;
          gap: 8px;
          &__download {
            display: flex;
            align-items: center;
            gap: 4px;
            span {
              font-family: "Inter", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 15.75px;
              color: #455cc7;
            }
          }
        }
      }
      &.empty {
        height: 100%;
      }
    }
  }
  .modal__footer {
    justify-content: flex-end;
  }
  .table-create-cases {
    margin: 0px 12px;
  }

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    border-bottom: 1px solid #0000001a;

    &__title {
      display: flex;
      height: 40px;
      align-items: center;
      align-self: stretch;
      gap: 8px;

      > svg {
        cursor: pointer;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
          background: $white-50;
        }
      }
    }
  }

  &__loading-importation {
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .paperplane-container {
      height: 21%;
    }
    &__percentage {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #455cc7;
    }
  }
}

.create-batch-cases-success {
  height: calc(100% - 73.09px);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    &__title {
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #131313;
    }
    &__subtitle {
      font-family: "Inter", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #757575;
      &__download {
        display: block;
        text-align: center;
        color: blue;
      }
    }
  }
}
