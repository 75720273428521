@import "./../../../styles/colors";

.field-container-edit {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;

  .field-container-edit-title {
    display: flex;
    gap: 8px;

    &__icons {
      display: flex;
      gap: 8px;
      padding-right: 4px;
      align-items: center;

      > button {
        height: 18px
      }
    }

    &__buttons{
      display: flex;
      gap: 8px;
      margin-top: 12px;
    }

    &__btn-cancel{
      display: flex;
      background: #ffffff;
      border: 1px solid #c90000;
      border-radius: 5px;

      &:hover {
        background: #f4f4f4;
      }
    }
  }

  .field-container-edit-content {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 8px 8px 8px 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    // height: calc(100% - 16px);


    &.normal-flex{
      flex-direction: row;
      padding: 0;
    }

    > textarea {
      flex-grow: 1;
      width: 100%;
      border-radius: 4px;
      // border: 1px solid $gray-200;
      border: none;
      padding: 16px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $gray-300;
      //height: content;
      resize: none;
      background-color: #fff;
      outline: unset;
      overflow-y: auto;
    }

    > textarea:focus {
      border-color: $primary-dark;
    }
  }
}