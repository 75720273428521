@import "./../../../styles/colors";

.attach-file {
  > input {
    display: none;
  }

  &__text {
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    > svg {
      width: 24px;
      height: 24px;
      stroke: $gray-200;
    }
  }

  &__icon-communicated {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    :hover {
      stroke: $primary-dark; 
    }

    > svg {
      width: 16px;
      height: 16px;
      // stroke: $gray-200;
    }
  }

  > svg {
    width: 24px;
    height: 24px;
    stroke: $gray-200;
    cursor: pointer;
  }
}