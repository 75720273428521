@import "./../../../styles/colors";

.filter-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  // height: 32px;
  padding: 4px 4px 4px 8px;
  background-color: $white-50;
  // border: 1px solid $primary-dark;
  // box-shadow: 0px 0px 0px 1px $primary-dark;
  border-radius: 24px;
  min-width: max-content;
  margin-right: 8px;
  margin-bottom: 4px;

  &.escalate-details{
    margin: 0;

    svg{
      width: 16px;
      height: 16px;
    }
  }

  .image-profile {
    margin-right: 8px;
  }

  &__alertIcon {
    cursor: pointer;
  }

  &__amount {
    display: flex;
  }

  &__priority {
    height: 16px;
    margin-bottom: 2px
  }

  &__channel {
    margin-right: 6px;
  }

  &__icon-filter{
    display: flex;
  }

  &__label{
    line-height: 112.5%;
    display: flex;
    align-items: flex-end;
  }

  &__btn-close {
    // animação de saída
    transition: opacity 0.25s 1.5s, max-width 0.25s 0.5s;
    background-color: $gray-125;
    border-radius: 50%;
  }

  &__profile {
    margin-right: -4px
  }

  &__icon {
    margin-right: 6px;
    margin-top: 2.5px;

    > svg {
      height: 20px;
      width: 20px;
      margin-right: 4px;
      fill: $primary-dark;
    }
  }

  &:hover {

    .channel {
      margin-left: 4px;
      width: 20px;
      height: 20px;

      svg {
        margin-left: 0px;
        width: 12px;
        height: 12px;
      }      
    }

    .filter-tag__btn-close {
      // animação de entrada
      transition: opacity 0.25s, max-width 0.25s    
    }

     svg {
      // margin-left: 8px;
      opacity: 1;
      max-width: 16px;
      // transition: opacity 0.25s, max-width 0.25s;
    }
  }

  .filter-tag__btn-close{
    width: 16px;
    height: 16px;
    fill: $white;
    opacity: 0;
    max-width: 0;
    // transition: opacity 0.25s, max-width 0.25s;
    cursor: pointer;
  }

  .image-profile {
    width: 24px;
    height: 24px;
  }
}
