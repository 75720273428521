@import "./../../../../styles/colors";
@import "./../../../../styles/mixins";

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  min-width: 350px;
  width: var(--width);
  height: var(--height);
  padding: 16px;
  background: $white;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    animation: fadeIn 0.01s;
    transition: opacity 0.15s;
    background: rgba(0, 0, 0, 0.51);
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;

   
    &__title {
      display: flex;
      justify-content: space-between;
      gap: 12px;

      &-action {
        display: flex;
        gap: 24px; 
      }

      h1{
        line-height: 32px;
      }
    }
  }

  &__footer {
    display: flex;
    gap: 12px;

  }

  &--animated {
    animation: growUp 0.15s;
    // animation: slideUp 0.5s;
  }

  // SIZES
  &--small {
    max-width: 512px;
    width: 45vw;
    min-width: 512px;
  }
  &--medium {
    max-width: 850px;
    width: 45vw;
    min-width: 330px;
  }
  &--large {
    max-width: 70vw;
    min-width: 635px;
  }
  &--full {
    max-width: 95vw;
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    margin-top: 50px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes growUp {
  0% {
    margin-top: 10px;
    transform-origin: center;
    transform: scale(0.9);
  }
  100% {
    margin-top: 0;
    transform-origin: center;
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .modal {
    &__small {
      max-width: 512px;
      width: 45vw;
      min-width: 280px;
    }
  }
}
