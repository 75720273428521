@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.select-search {
  position: relative;
  display: flex;
  flex-direction: column;
  // height: 38px;

  &-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 38px;
    // padding: 12px 16px;
    padding: 12px 8px;
    background-color: $white;
    // border: 1px solid $gray-125;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;

    & .btn_tags-input {
      display: flex;
      flex-wrap: wrap;
    }

    &__input {
      flex-grow: 1;
      display: flex;
      border: none;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: $gray-400;
      max-width: calc(100% - 28px);
      background: transparent;

      &.listInput {
        cursor: pointer;
        &::placeholder {
          color: $gray-125;
        }
      }

      &.hidden {
        display: none;
      }

      // &:disabled {
      //   background-color: $white;
      // }

      &::placeholder {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: $gray-175;
        // color: $gray-180;
      }
    }

    &__icon {
      cursor: pointer;

      & path {
        fill: $gray-180;
      }
    }

    &__iconBase {
      cursor: pointer;
      position: absolute;
      right: 10px;

      &.transform {
        transform: rotate(180deg);
      }
    }

    &__icon-disabled {
      cursor: default;
    }

    &--disabled {
      border-color: $gray-75;
      background-color: #d6d6d6;
      cursor: default;
    }
  }

  // &.create-case-select{
  //   .select-search-btn{
  //     padding: 12px 8px;
  //     border: 1px solid rgba(0, 0, 0, 0.1);
  //   }
  // }

  &-list {
    position: absolute;
    top: calc(100% + 6px);
    z-index: 10;
    display: flex;
    flex-direction: column;
    // gap: 8px;
    width: 100%;
    // max-height: 280px;
    // padding: 8px;
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s;
    transform: scaleY(0) translateY(-50px);
    transform-origin: top;
    overflow-y: auto;

    &__inputDiv {
      display: flex;
      padding: 12px 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      gap: 12px;
    }

    &__sentinel {
      flex-shrink: 0;
      height: 5px;
    }

    &__item {
      display: flex;
      gap: 12px;
      align-items: center;
      flex-shrink: 0;
      flex-basis: 50px;
      max-width: 100%;
      // padding: 6px 8px;
      padding: 12px;
      border-radius: 4px;
      text-align: left;
      transition: all 0.25s;

      .item-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 0;

        .captions-container {
          display: flex;
          gap: 3px;
        }

        &__label,
        &__caption {
          max-width: 100%;
          @include text-overflow;
        }

        &__caption {
          &.second-caption {
            max-width: 75%;
          }
        }
      }

      &:hover {
        background-color: $blue-selected-3;
      }

      &.active {
        background-color: $blue-selected-3;
        pointer-events: none;
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 28px 0;

      > svg {
        width: 50%;
        max-height: 180px;
        height: 10%;
      }

      > .typography-h6 {
        text-align: center;
      }
    }

    &__contact-empty {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      padding: 28px 0;

      .container-icon {
        padding: 36px;
        background-color: $blue-selected-3;
        border-radius: 50%;
      }

      .typography-h5 {
        width: 95%;
        text-align: center;
      }
    }

    .loading-container {
      display: flex;
      justify-content: center;
    }
  }

  &--error {
    &.create-case-select {
      .select-search-btn {
        border-color: $red;
      }
    }
    .select-search-btn {
      border-color: $red;
    }
  }

  .select-disabled {
    border-color: $gray-75;
    background-color: #d6d6d6;
    cursor: default;
    // background-color: $gray-75;
  }

  &--active {
    .select-search-btn {
      border-color: $blue-selected-4;
    }

    .select-search-list {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1) translateY(0);
      &__custom-field {
        padding: 14px 12px;
        color: #455cc7;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
}
.select-search-popover {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "contact company"
    "date status"
    "subject subject";
  gap: 8px;
  width: 262px;
  padding: 3px 7px 3px 7px;

  .select-search-popover-contact {
    align-items: flex-start;
    grid-area: contact;

    .typography-h5 {
      max-width: 120px;
      @include text-overflow;
    }
  }

  .select-search-popover-company {
    align-items: flex-start;
    grid-area: company;
  }

  .select-search-popover-date {
    align-items: flex-start;
    grid-area: date;
  }

  .select-search-popover-status {
    display: flex;
    align-items: flex-end;
    grid-area: status;
  }

  .select-search-popover-subject {
    align-items: flex-start;
    grid-area: subject;

    .typography-paragraph {
      word-break: break-word;
      text-align: start;
    }
  }
}
