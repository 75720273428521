@import "./../../../styles/colors";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 8px 11.5px;
  height: 40px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;

  &-more-filters{
    gap: 8px;
    padding: 8px 20px;
    border: 1px solid $blue-selected-4;
    border-radius: 5px;
  }

  &.btn-primary {
    padding: 8px 20px;
    color: $white;
    border-radius: 5px;
    background-color: $blue-selected-4;
    border: 1px solid $blue-selected-4;
    transition: background-color 0.25s;

    &:hover {
      background-color: $secondary-02-light;
    }

    &:focus-visible {
      background-color: $secondary-03-dark;
    }

    &.btn-icon--fill {
      svg {
        width: 24px;
        height: 24px;
        fill: $white;
      }
    }

    &.btn-icon--stroke {
      svg {
        width: 24px;
        height: 24px;
        stroke: $white;
      }
    }

    &:disabled {
      background-color: $gray-100;
      pointer-events: none;
    }
  }

  &.btn-secondary {
    padding: 8px 20px;
    color: $white;
    border-radius: 5px;
    background-color: $blue-selected-4;
    border: 1px solid $blue-selected-4;
    transition: background-color 0.25s;
    font-size: 14px;

    &:hover {
      background-color: $secondary-02-light;
    }

    &:focus-visible {
      background-color: $secondary-03-dark;
    }

    &.btn-icon--fill {
      svg {
        width: 24px;
        height: 24px;
        fill: $white;
      }
    }

    &.btn-icon--stroke {
      svg {
        width: 24px;
        height: 24px;
        stroke: $white;
      }
    }

    &:disabled {
      background-color: $gray-100;
      pointer-events: none;
    }
  }

  &.btn-tertiary {
    padding: 8px 20px;
    color: $red-2;
    border-radius: 5px;
    background-color: $white;
    border: 1px solid $red-2;
    transition: background-color 0.25s;
    font-size: 14px;

    &:hover {
      background-color: #e99999;
    }

    &:focus-visible {
      background-color: $secondary-03-dark;
    }

    &.btn-icon--fill {
      svg {
        width: 24px;
        height: 24px;
        fill: $white;
      }
    }

    &.btn-icon--stroke {
      svg {
        width: 24px;
        height: 24px;
        stroke: $white;
      }
    }

    &:disabled {
      background-color: $gray-100;
      pointer-events: none;
    }
  }
  
  &.btn-fourthiary{
    padding: 8px 20px;
    color: $blue-selected-4;
    border-radius: 5px;
    background-color: $white;
    border: 1px solid $blue-selected-4;
    transition: background-color 0.25s;
    font-size: 14px;

    &:hover {
      background-color: $gray-125;
    }

    &:focus-visible {
      background-color: $secondary-03-dark;
    }

    &.btn-icon--fill {
      svg {
        width: 24px;
        height: 24px;
        fill: $white;
      }
    }

    &.btn-icon--stroke {
      svg {
        width: 24px;
        height: 24px;
        stroke: $white;
      }
    }

    &:disabled {
      background-color: $gray-100;
      pointer-events: none;
    }
  }
}
