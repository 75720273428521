@import "./../../../../styles/colors";

.modal-send-communicated {
  overflow: auto;

  
  .modal {
    &__body {
      display: flex;
      flex-direction: column;
      gap: 14px;
      height: 100%;
    }

    &__footer{
      justify-content: flex-end;
    }
  }

  &__content {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 100%;

    .modal-send-communicated__send-content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;
        border: 1px solid $gray-175;
        border-radius: 4px;
        // padding: 8px 16px;

        .audio-recorder {
          display: flex;
          justify-content: center;
          gap: 22px;
          // padding: 8px 10px;
          background-color: $gray-50;
          // border: 1px solid $gray-175;
          // border-radius: 50px;
          padding: 8px 16px;
          // background: $white;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          border-radius: 8px;
        
          > svg {
            fill: $gray-200;
            cursor: pointer;
          }
        
          .buttons-action {
            display: flex;
            align-self: flex-end;
            gap: 16px;
        
            &__cancelButton {
              svg{

                fill: $red-2;
              }
            }
        
            &__sendButton {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0;
        
              background: none;
              border-radius: 24px;
        
              color: $white;

              svg{
                fill: $blue-selected-4;
              }
        
            }
        
            &__sendButton:disabled{
              cursor: default;
              background: $gray-150;
            }
        
            &__sendButton h6,
            &__cancelButton h6 {
              font-weight: 500;
              line-height: 20px;
              display: flex;
              align-items: center;
            }
          }
        
          &_audioComment{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 12px;
        
            &__recordBar{
              width: 325px;
              height: 6px;
        
              background: rgba(0, 0, 0, 0.1);
              border-radius: 18px;
            }
        
            &__timeProgress{
              font-size: 10px;
              line-height: 16px;
              display: flex;
              align-items: center;
        
              color: $gray-180;
            }
          }
        
          .audio-recorder-send {
            display: flex;
            align-items: center;
            gap: 8px;
        
            > button {
              cursor: pointer;
            }
        
            .audio-recorder-send-time {
              width: 30px;
            }
          }
        }

        .quill-toolbar-mail{
          padding: 8px 12px;

          .case-communications__container-templates{
            display: flex;
            flex-direction: column;
            padding: 16px;
            grid-gap: 16px;
            gap: 16px;
            width: 350px;

            .container-templates-header{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
      
              .header-area{
                display: flex;
                gap: 8px;
              }
      
              h6{
                text-align: start;
              }
            }
      
            .container-templates-body{
              display: flex;
              flex-direction: column;
              gap: 14px;
              max-height: 150px;
              overflow: auto;
      
              .categorie-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
      
                svg{
                  fill: #292D32;
                }
              }
            }
          }
        }

        .ql-attachment {
          display: flex;
          position: absolute;
          margin-left: 127px;
          top: 9px;
        }

        .communicated-carousel {
          background-color: $white;
        }

        // .attach-file {
        //   position: absolute;
        //   top: 10px;
        //   right: 16px;
        // }

        .communicated-carousel {
          border-top: 1px solid $gray-100;
          padding-top: 8px;
        }
    }

    .modal-send__container-recipients__recipient {
      display: flex;
      align-items: center;
      margin-top: 8px;
      width: 100%;
      border: 1px solid $gray-175;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 2px;
      margin-right: 10px;
      flex-wrap: wrap;
      min-height: 35px;
      border-radius: 5px;

      // &-button {
      //   display: flex;
      // }
    }

    .container-two-collumn {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .container-full-line {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      
    }

    .grid-span-2 {
      grid-column: span 2;
    }

    .create-case-select{
      .select-search-btn{
        padding: 12px 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .modal-create-case__dropdown{
      width: 208px;

      .select-btn, .dropdown-channel-btn, .dropdown-priority-btn {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .field-container-case-client-list {

    }

    .dropzone-container {
      grid-column: span 2;
    }
  }

  &__btn-confirm {
    // flex-grow: 1;
    display: flex;

  }

  &__btn-cancel.btn-primary {
    // flex-grow: 1;
    display: flex;
    background: $white;

    border: 1px solid $red-2;
    border-radius: 5px;

    &:hover{
      background: $white-50 ;
    }
  }
}

@media screen and (max-width: 700px) {
  .ReactModal__Body--open{
    overflow: hidden;
  }
  .modal-send-communicated {
    width: 100%;
    height: 100%;

    &__content {
      .container-full-line {
        width: 100%;
  }
    
      .grid-span-2 {
        width: 100%;
      }

      .modal-create-case__dropdown{
        width: 100%;
      }
    }
  }

  
}
