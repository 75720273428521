@import "./../../../../styles/colors";

.time-rangeInput.MuiFormControl-root.MuiTextField-root {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 38px;

    &.error-input {
        border-color: #f7404e;
    }

    &.disabled {
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
            background-color: #dbdde2;
        }
    }

    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
        padding: 9px 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: $gray-180;

        border: none;

        @media screen and (max-width: 700px) {
            font-size: 12px;

            // &::placeholder{
            //     // text-align: center;
            //     // font-size: 12px;
            // }
        }
    }

    .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium {
        margin-left: 0;
    }

    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
        padding: 8px 8px 8px 0px;
    }

    &.error-time {
        .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd {
            color: $red;
        }
    }

    svg {
        path {
            fill: #757575;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}
