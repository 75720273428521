@import "../../styles/_colors.scss";

.pages-register-typeSolicitation{

  height: 100%;
  // width: 100%;
  background-color: $white;

  &__header{
    width: 100%;
    padding: 16px 24px;

    display: flex;
    align-items: center;
    gap: 16px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__main {
    height: 93%;

    &.page-split {
      .page-split__section {
        &--a {
          flex-basis: 27.5%;
          // flex-grow: 0;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 24px 24px 32px 24px;
          background-color: $white;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
  
          .main {
            display: flex;
            flex-direction: column;
            padding: 0 62px;
            margin-top: 97px;
  
            &__content {
              display: flex;
              flex-direction: column;   
              gap: 16px;       
              
              .select-btn{
                border: 1px solid rgba(0, 0, 0, 0.1);
              }
              
              &-footer{
                display: flex;
                justify-content: flex-end;
                gap: 16px;
              }
  
            }
          }
        }


        &--b {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: $white;
          flex-basis: 70%;
  
          .typeSolicitation-list-container__empty-state{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            .container-icon{
              padding: 40px;
              background-color: $blue-selected-3;
              border-radius: 50%;
            }

            h4{
              width: 65%;
              text-align: center;
            }
          }
          
        }
      }
    }
  }
}


@media only screen and (max-width: 950px) {
  .page-login.page-split {
    .page-split__section--a {
      flex-grow: 1;
      min-width: 300px;

      .main {
        padding: 0 5%;
        margin-top: 20%;

        &__header {
          padding-right: 5%;
        }

        &__content {
          margin-top: 10%;
        }
      }
    }

    .page-split__section--b {
      display: none;
    }
  }
}
