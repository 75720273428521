@import "../../styles/colors";

.type-management {
    background-color: #ffffff;
    padding: 0px 10px;
    height: 100%;
    width: 100%;

    &__empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__header {
        margin-top: 25px;
        background-color: #ffffff;

        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-right: 1px solid transparent;
            width: 42rem;
        }

        &__organization {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }

        &__options {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }

    .typ-management-empty-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 91%;
        width: 100%;

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            flex-shrink: 0;
            border-radius: 120px;
            background: #ebf2ff;
        }

        &__button-create-type {
            margin-top: 15px;
        }

        > h4 {
            margin-top: 25px;
        }
    }

    &__body {
        display: flex;
        height: 100%;

        .organizations-container-section {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            max-height: 430px;
            overflow: auto;
            margin-top: 15px;

            .organization-list {
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;

                > div {
                    width: 190px;
                    cursor: pointer;
                }

                &__organization {
                    display: flex;
                    padding: 24px 20px;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;
                    border-radius: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    background: $white;
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
                }
            }
        }

        .typ-container-section {
            display: flex;
            width: 100%;
            // height: 100vh;

            &.overall-width {
                width: 100%;
            }

            &__left {
                &__header {
                    .title {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        cursor: pointer;
                    }
                }
            }

            &__left,
            &__middle,
            &__right {
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                width: 35%;
                // width: 42rem;
                // flex: 1;
                // max-height: 600px;
                // overflow-y: auto;

                .search-types {
                    margin-top: 25px;
                    padding: 0px 15px;
                }

                &__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 25px;
                    padding: 0px 15px;

                    .options {
                        display: flex;
                        gap: 10px;
                        margin-right: 1rem;
                    }
                }

                .types-list {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    overflow: auto;
                    max-height: 90%;
                    padding: 0px 10px;
                    margin-right: 0.3rem;
                    margin-top: 35px;

                    > div {
                        display: flex;
                        width: 100%;
                        gap: 5px;
                    }

                    .droppable {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }

                    &__content {
                        display: flex;
                        // width: 423px;
                        width: 100%;
                        padding: 10px 16px;
                        align-items: center;
                        border-radius: 8px;
                        border: 1px solid #e3e3e3;
                        cursor: default;

                        &.active {
                            background: #ebf2ff;
                        }

                        &__items {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            gap: 8px;
                            flex-shrink: 0;

                            .button-drag-drop {
                                cursor: grab;

                                &.active {
                                    > svg {
                                        circle {
                                            fill: $blue-selected-4;
                                        }
                                    }
                                }
                            }

                            .data-container {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;

                                &__text {
                                    display: flex;
                                    // width: 16rem;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    flex-shrink: 0;

                                    .sla-information {
                                        display: flex;
                                        align-items: center;
                                    }
                                }

                                &__menu {
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;

                                    .icon-more-vertical {
                                        > svg {
                                            transform: rotate(270deg);
                                            cursor: pointer;
                                        }

                                        &.active {
                                            > svg {
                                                path {
                                                    stroke: $blue-selected-4;
                                                }
                                            }
                                        }
                                    }

                                    .icon-arrow-up {
                                        cursor: pointer;

                                        > svg {
                                            transform: rotate(90deg);

                                            path {
                                                fill: $gray-180;
                                            }
                                        }

                                        &.active {
                                            > svg {
                                                path {
                                                    fill: $blue-selected-4;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.active {
                            background: #ebf2ff;
                        }
                    }
                }
            }

            &__right {
                border-right: none !important;
            }

            .typ-management-empty-state-reason,
            .typ-management-empty-state-subreason {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;

                &__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    height: 120px;
                    flex-shrink: 0;
                    border-radius: 120px;
                    background: #ebf2ff;
                }

                > h4 {
                    margin-top: 25px;
                }
            }

            &__empty-state {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // width: 100%;

                &__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    height: 120px;
                    flex-shrink: 0;
                    border-radius: 120px;
                    background: #ebf2ff;
                }

                > h4 {
                    margin-top: 25px;
                }
            }

            .conatiner-emptyState-subreason {
                display: flex;
                flex-direction: column;
                width: 35%;
            }

            .conatiner-emptyState-reason {
                display: flex;
                flex-direction: column;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                width: 35%;
            }
        }
    }
}

@media screen and (max-height: 800px) {
    .type-management {
        &__header {
            > div {
                width: 28.9rem;
            }
        }

        &__body {
            .typ-container-section {
                &__left,
                &__middle,
                &__right {
                    // // width: 460px;
                    // width: 28.9rem;

                    .types-list {
                        max-height: 500px;
                    }
                }

                // .typ-management-empty-state-subreason {
                //     width: 28.9rem;
                // }
            }
        }
    }
}
