// CUSTOM THEME
div[data-tippy-root] {
  transform: translate(-35.0035px, 307px);
}

.tippy-box[data-theme~="default"] {
  background-color: $gray-300;
  color: $white;
  text-align: center;

  &[data-placement^="top"],
  &[data-placement^="bottom"],
  &[data-placement^="left"],
  &[data-placement^="right"] {
    & > .tippy-arrow::before {
      border-top-color: $gray-300;
    }
  }
}

.tippy-box[data-theme~="popover-default"] {
  min-width: 97px;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 8px;
  color: $gray-300;
  text-align: center;
  cursor: default;
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12)) drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));

  .tippy-content {
    padding: 0px;
  }

  &[data-placement^="top"] {
    & > .tippy-arrow::before {
      border-top-color: $white;
      bottom: -8px;
    }
  }

  &[data-placement^="bottom"] {
    & > .tippy-arrow::before {
      border-bottom-color: $white;
      top: -8px;
    }
  }

  &[data-placement^="left"] {
    & > .tippy-arrow::before {
      border-left-color: $white;
      right: -8px;
    }
  }

  &[data-placement^="right"] {
    & > .tippy-arrow::before {
      border-right-color: $white;
      left: -8px;
    }
  }
}

.tippy-box[data-theme~="popover-comment"] {
  min-width: 97px;
  background-color: $white;
  //border: 1px solid $primary-dark;
  border-radius: 8px;
  color: $white;
  text-align: center;
  cursor: default;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.25);

  .tippy-content {
    padding: 8px 16px 20px 16px;
  }

  .tippy-arrow {
    display: none;
  }
}
