.box-status{

    &__green { 
        width: 8px;
        height: 8px;
        background-color: #008908;
        border-radius: 8px;
    }

    &__gray { 
        width: 8px;
        height: 8px;
        background-color: #757575;
        border-radius: 8px;
    }

    &__red { 
        width: 8px;
        height: 8px;
        background-color: #C90000;
        border-radius: 8px;
    }

    &__orange { 
        width: 8px;
        height: 8px;
        background-color: #E27121;
        border-radius: 8px;
    }

    &__marine { 
        width: 8px;
        height: 8px;
        background-color: #00ACC1;
        border-radius: 8px;
    }

    &__blue { 
        width: 8px;
        height: 8px;
        background-color: #455CC7;
        border-radius: 8px;
    }

    &__purple { 
        width: 8px;
        height: 8px;
        background-color: #5E35B1;
        border-radius: 8px;
    }

    &__yellow { 
        width: 8px;
        height: 8px;
        background-color: #F2BD00;
        border-radius: 8px;
    }
}
