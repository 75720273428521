.paperplane-container {
  display: flex;

  &--full-page {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  &.intern-page{
    width: 100%;
  }


  // &.loading-contact-list{
  //   width: 100%;
  // }
}
