@import "./../../../styles/colors";

.file-preview-image {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > img {
    width: 100%;
    height: 97px;
  }
}

.file-preview-download {
  cursor: pointer;
}

.file-preview-audio {
  .rhap_main-controls {
    flex: 1 1 auto;
  }
  
  .rhap_additional-controls {
    flex: 0 0 auto;
  }
}

.file-preview {
  display: flex;
  gap: 4px;

  &__icon {
    margin-right: 12px;
    max-height: 42px;
    position: relative;

    > svg {
      width: 32px;
      height: 42px;
    }

    &__type {
      position: absolute;
      bottom: 5px;
      right: 3px;
      font-family: "Poppins", sans-serif;
      font-size: 10px;
      font-weight: 600;

      &--blue {
        color: $primary-dark
      }

      &--red {
        color: $red
      }

      &--yellow {
        color: $yellow
      }

      &--green {
        color: $green
      }

      &--gray {
        color: $gray-200
      }
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .typography-h5 {
      max-width: 125px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .typography-h6 {
      max-width: 125px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__size {
    min-width: fit-content;
  }

  &__download {
    display: flex;
    gap: 7px;
    margin-left: auto;
  }

  .typography-hyperlink {
    cursor: pointer;
  }

  &__delete {
    svg {
      width: 14px;
      height: 14px;
      fill: $gray-85;
      cursor: pointer;
    }
  }
}