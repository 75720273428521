@import "./../../../styles/colors";

.container-comment-principal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  &.reverse-row {
    flex-direction: row-reverse;
  }

  &.padding-top {
    padding-top: 20px;
  }

  .image-profile {
    &.flex-end {
      align-self: flex-end;
    }
  }

  .container-message-arrow {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    right: 0;
    left: 0;
    position: absolute;
    bottom: 0;

    &__icon {
      width: 12px;
      height: 8px;
      background-color: red;
      transform: matrix(0, -1, -1, 0, 0, 0);
    }
  }
}

.comment-container-02 {
  .container-comment-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 1em 0 3em;
  }
}

.header-comment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  &__details-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 313px;
    height: 40px;

    .user-information {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  > svg {
    fill: #757575;
    cursor: pointer;
  }

  .popover-comment {
    display: flex;
    flex-direction: column;
    padding: 0 6px;

    &__item {
      padding: 4px 0;
      cursor: pointer;
      width: 100%;

      &:hover {
        background-color: $gray-75;
      }
    }
  }
}

.comment {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  // gap: 4px;
  position: relative;
  background: #ebf6eb;
  border-radius: 8px;

  &.blue-color {
    background: #ebf2ff;
  }

  &.comment-length-normal {
    width: 357px;
  }

  &.comment-length-alternate {
    min-width: 40%;
    max-width: 78%;
  }

  .popover-comment {
    display: flex;
    flex-direction: column;
    // gap: 4px;
    // align-items: flex-start;
    padding: 0 6px;

    // &__item:last-child {
    //   border-bottom: unset;
    //   padding-bottom: unset;
    // }

    &__item {
      // padding-bottom: 4px;
      // border-bottom: 1px solid $gray-75;
      // cursor: pointer;
      padding: 4px 0;
      cursor: pointer;
      width: 100%;

      &:hover {
        background-color: $gray-75;
      }
    }
  }

  .comment-information {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .comment-information-user {
      display: flex;
      align-items: center;
      gap: 10px;

      &__image {
        width: 22px;
        height: 22px;
      }
    }

    .comment-information-date {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .comment-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 2px;

    &__response {
      display: flex;
      // padding-bottom: 4px;
      // border-bottom: 1px solid $gray-175;

      .response-divider {
        width: 4px;
        height: 100%;
        background: $blue-selected-4;
        border-radius: 4px 0px 0px 4px;
      }

      .response-content {
        background: $white;
        width: 100%;
        padding: 8px;
      }

      .typography-paragraph {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        // max-height: 31px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .typography-h6 {
        display: flex;
        align-items: center;
      }
    }

    &__text {
      display: flex;
      justify-content: space-between;

      .typography-h4 {
        > div {
          word-break: break-word;

          // .comment-filtered--marked {
          //   background-color: yellow;
          // }
          // mark {
          //   background-color: $yellow;
          // }
        }
      }

      .comment-content__text-edit {
        width: 100%;
        padding: 8px;
        border: 0;
        min-height: fit-content;
        resize: none;
        // font-size: 16px;
        // padding-right: 5px;
      }

      .comment-content__text-edit-label {
        width: 100%;

        input {
          width: 100%;
          height: fit-content;
          padding: 0;
          border: 0;
          font-size: 12px;
        }
      }
    }

    .rhap_main {
      height: 56px;

      .rhap_time {
        top: 35px;
      }
    }

    .comment-content-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .comment-content-buttons-attachment-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        flex-grow: 1;
        // border-top: 1px solid $gray-175;
        // padding-top: 8px;

        &.column {
          flex-direction: column;
        }

        .attach-file__icon > svg {
          width: 16px;
          height: 16px;
          stroke: none;
          fill: $blue-selected-4;
        }

        .comment-content-add-file {
          display: flex;
          gap: 4px;
        }

        .comment-content-edit-buttons {
          display: flex;
          align-items: center;
          gap: 10px;

          svg {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .comment-icon__menu {
    min-width: 16px;
    height: 16px;
    fill: $gray-180;
    cursor: pointer;
  }

  .comment-carousel {
    background-color: $white;

    .column-attach-list {
      h5 {
        max-width: 80px;
      }
    }
  }

  .comment-content__files {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // justify-content: space-between;

    .bubble-media__video-frame{
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .comment-attachment {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 1px solid $gray-175;
    padding-top: 8px;

    .comment-attachment-container {
      display: flex;
      justify-content: space-between;

      > svg {
        min-width: 24px;
        height: 24px;
        fill: #bcbfd0;
        cursor: pointer;
      }

      .file-preview {
        width: 100%;
      }
    }
  }

  .comment-footer {
    align-self: flex-end;
    padding-right: 10px;

    > h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  @keyframes animation {
    from {
      border-color: $gray-175;
      background-color: #fff;
    }
    50% {
      border-color: $primary-dark;
      background-color: $gray-50;
    }
    to {
      border-color: $gray-175;
      background-color: #fff;
    }
  }

  &.comment-active {
    .comment-content {
      animation-name: animation;
      animation-duration: 1.5s;
      animation-iteration-count: initial;
    }
  }
}
