@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.card-task-draggable {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px;
  background: #fff;
  cursor: pointer;

  // border-radius: 4px;
  // border: 1px solid $gray-200;
  //height: 197px;

  &--disabled {
    cursor: default;
  }

  .icon--active {
    svg {
      fill: $primary-dark;
    }
  }

  .card-task-draggable-header {
    display: flex;
    align-items: center;
    gap: 16px;

    &__date {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &__notification {
      margin-left: auto;
    }

    &__menu {
      height: 24px;
      // margin-left: auto;
      cursor: pointer;

      > svg {
        transform: rotate(270deg);
        width: 20px;
        height: 20px;
      }
    }
  }

  .card-task-draggable-content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__title {
      height: 20px;
      > h6 {
        display: -webkit-box;
        -webkit-line-clamp: 1; /** número de linhas que você quer exibir */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        font-size: 14px;
      }
    }

    &__description {
      height: 32px;
      > h6 {
        display: -webkit-box;
        -webkit-line-clamp: 2; /** número de linhas que você quer exibir */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
  }

  .card-task-draggable-tags {
    display: flex;
    gap: 4px;
  }

  .card-task-draggable-footer {
    display: flex;
    justify-content: space-between;

    &__status {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      .color-bar {
        width: 20px;
        height: 20px;
        border-radius: 999px;
      }
    }

    .card-task-draggable-profile {
      display: flex;
      gap: 8px;
    }

    .dropdown-status {
      bottom: 10px;
    }
  }

  .popover-card-draggable {
    // display: flex;
    // flex-direction: column;
    // gap: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 128px;
    // height: 96px;
    background: #ffffff;
    border-radius: 8px;

    &__item:last-child {
      border-bottom: unset;
      padding-bottom: unset;
    }

    &__item {
      // padding-bottom: 4px;
      // border-bottom: 1px solid $gray-75;
      // cursor: pointer;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px;
      gap: 1px;
      width: 128px;
      height: 32px;
      cursor: pointer;

      &__option {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        color: #757575;

        &__remove {
          color: #c90000;
        }
      }
    }
  }
}

.card-case-draggable {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px;
  background: #fff;
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  .icon--active {
    svg {
      fill: $primary-dark;
    }
  }

  .card-case-draggable-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &__date {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &__ticket {
      // padding-top: 1rem;
      cursor: pointer;
    }

    &__notification {
      margin-left: auto;
    }

    &__menu {
      height: 24px;
      // margin-left: auto;
      cursor: pointer;

      > svg {
        transform: rotate(270deg);
        width: 20px;
        height: 20px;
      }
    }
  }

  .card-case-draggable-content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__title {
      height: 20px;
      > h6 {
        display: -webkit-box;
        -webkit-line-clamp: 1; /** número de linhas que você quer exibir */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        font-size: 14px;
      }
    }

    &__description {
      height: 32px;
      > h6 {
        display: -webkit-box;
        -webkit-line-clamp: 1; /** número de linhas que você quer exibir */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }

    .subcontent {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .card-case-draggable-footer {
    display: flex;
    justify-content: space-between;

    &__status {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;

      .color-bar {
        width: 20px;
        height: 20px;
        border-radius: 999px;
      }
    }

    .card-case-draggable-profile {
      display: flex;
      // gap: 8px;

      .image-profile {
        margin-left: -4px;
      }

      &__icon {
        height: 25px;
        width: 25px;
      }
    }

    .dropdown-status {
      bottom: 10px;
    }
  }
}
