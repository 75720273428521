@import "./../../../styles/colors";

.popover-menu-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 6px;

  &__item {
    padding: 4px 0;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    h5{
      display: flex;
      gap: 5px;
    }
  }
}

.popover-icon{
  width: 16px;
  height: 16px;
  path {
    fill: $red;

  }
}
