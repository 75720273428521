@import "./../../../styles/colors";

.button-search {
  display: flex;
  gap: 8px;
  // height: 22px;

  .popover-button-search {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 253px;
    height: 330px;
    background-color: $white;

    .input-container {
      .input-wrapper__input {
        height: 40px;
      }
      svg {
        fill: $secondary-02-light;
        stroke: none;
      }
    }

    &__value {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      .user-name{
        display: flex;
        align-items: center;

        .create-user{
          position: relative;
          left: 5px;
        }
      }

      .image-profile {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .icon-down{
        width: 24px;
        height: 24px;
      }

      .icon-add {
        height: 24px;
        svg {
          fill: $gray-175;
          width: 24px;
          height: 24px;
        }
      }
    }


    &__list {
      display: flex;
      flex-direction: column;
      // gap: 8px;
      // padding-right: 6px;
      max-height: 288px;
      overflow: auto;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 8px;
      // padding: 4px 8px;
      padding: 16px;
      cursor: pointer;

      .content-text{
        text-align: initial;
      }

      &.active {
        background-color: $blue-selected-3;
        border-radius: 4px;
        cursor: pointer;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $primary-dark;
      }
    }
  }

}