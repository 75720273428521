@import "./../../../../styles/colors";

.modal-create-staggered {
    .modal {
        &__footer {
            justify-content: flex-end;
        }
    }

    .container-footer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
