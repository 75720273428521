@import "../../../styles/colors";

.image-profile {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: $blue-selected-4;
  cursor: default;
  user-select: none;
}

.image-profile__img {
  width: 36px;
  height: 36px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.profile-list-user{
  width: 18px;
}

.icon-history-color {
  fill: #455cc7;
}

.icon-profile_audio{
  width: 32px;
  height: 32px;
  background: #455CC7;
}

.icon_audio{
  width: 16px;
    height: 16px;
}

.new-create-user{
  width: 24px;
  height: 24px;

  h5 {
    font-size: 12px;
  }
}
