@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.multiple-select {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 40px;

  &-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;
    padding: 11px 8px;
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 4px;
    cursor: text;

    &__input {
      flex-grow: 1;
      display: flex;
      border: none;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      color: $gray-400;
      max-width: calc(100% - 28px);

      &::placeholder {
        color: $gray-175;
      }
    }

    &__icon {
      cursor: pointer;
    }
  }

  &-list {
    position: absolute;
    top: 48px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 280px;
    padding: 8px;
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s;
    transform: scaleY(0) translateY(-50px);
    transform-origin: top;
    overflow-y: auto;

    &__sentinel {
      flex-shrink: 0;
      height: 5px;
    }

    &__item {
      display: flex;
      gap: 12px;
      align-items: center;
      flex-shrink: 0;
      flex-basis: 50px;
      max-width: 100%;
      padding: 6px 8px;
      border-radius: 4px;
      text-align: left;
      transition: all 0.25s;

      .item-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 0;

        &__label,
        &__caption {
          max-width: 100%;
          @include text-overflow;
        }
      }

      &:hover {
        background-color: $gray-75;
      }

      &.active {
        background-color: $blue-selected-2;
        pointer-events: none;
      }
    }

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 28px 0;

      > svg {
        width: 50%;
        max-height: 180px;
        height: 10%;
      }

      > .typography-h6 {
        text-align: center;
      }
    }

    .loading-container {
      display: flex;
      justify-content: center;
    }
  }

  &--error {
    .multiple-select-btn {
      border-color: $red;
    }
  }

  &--active {
    .multiple-select-btn {
      border-color: $primary-dark;
    }

    .multiple-select-list {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1) translateY(0);
    }
  }
}
.multiple-select-popover {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "contact company"
    "date status"
    "subject subject";
  gap: 8px;
  width: 262px;
  padding: 3px 7px 3px 7px;

  .multiple-select-popover-contact {
    align-items: flex-start;
    grid-area: contact;

    .typography-paragraph {
      max-width: 120px;
      @include text-overflow;
    }
  }

  .multiple-select-popover-company {
    align-items: flex-start;
    grid-area: company;
  }

  .multiple-select-popover-date {
    align-items: flex-start;
    grid-area: date;
  }

  .multiple-select-popover-status {
    display: flex;
    align-items: flex-end;
    grid-area: status;
  }

  .multiple-select-popover-subject {
    align-items: flex-start;
    grid-area: subject;
    

    .typography-paragraph {
      word-break: break-word;
      text-align: start;
    }
  }
}