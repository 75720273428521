@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.task-details {
  background-color: #ffffff;
  padding: 0 20px 0 20px;
  height: 100%;
  width: 100%;

  &.tasks-details-modify {
    // height: calc(100% - 55px);
    border-top: 1px solid #d9dae1;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    //transitionjustify-content: space-between;
    padding: 10px 0 8px 0;
    border-bottom: 1px solid $gray-75;
    background-color: #ffffff;
    position: sticky;
    z-index: 10;
    top: 0;

    .task-details-header__title {
      display: flex;
      align-items: center;
      gap: 12px;
      width: calc(50% - 10px);

      &__text {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        &__value {
          display: flex;
        }

        .task-details__title-task {
          display: flex;
          //flex-grow: 1;

          > h2 {
            //flex-grow: 1;
            max-width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            direction: ltr;
          }
        }

        > input {
          padding: 0;
          border: 0;
          min-width: 15px;
          max-width: 500px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          color: $gray-500;
        }

        &__buttons {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        svg {
          cursor: pointer;
        }
      }

      > svg {
        flex-shrink: 0;
        cursor: pointer;
        fill: $gray-175;
        flex-shrink: 0;
      }
    }

    .task-details-header__options {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      width: calc(50% - 10px);
    }

    .task-details-header__profile {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-right: auto;

      > svg {
        min-width: 24px;
        fill: $gray-85;
      }

      &__img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }

  .task-details__title {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
      fill: $gray-200;
    }

    &__icons {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      gap: 10px;
      background-color: #ffffff;
      // position: absolute;
      // bottom: 0;
      // right: 16px;

      svg {
        cursor: pointer;
      }
    }
  }

  /* ======================== NEW STYLE================================== */
  &__body {
    display: flex;
    gap: 18px;
    height: 94%;
    flex-grow: 1;

    &__client {
      overflow: auto;
      flex-grow: 1;
      margin-top: 24px;
      width: 100%;
      // height: 90%;
      max-height: 100%;
      padding: 0 10px;

      &.margin-top-auto {
        margin-top: 10px;
      }

      &__details-content {
        padding-top: 25px;

        .container-attachments {
          &__list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 5px;

            .attachment {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 8px 0px;
              gap: 8px;
              width: 100%;
              height: 48px;

              .icon-archive {
                width: 45px;
                height: 45px;
                object-fit: cover;
              }

              &__progress-bar {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 8px;
                width: 100%;
                height: 28px;

                &__header {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 215px;
                  width: 100%;
                  height: 16px;

                  .file-information {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 16px;
                    // width: 173px;
                    width: 300px;
                    height: 16px;

                    &__name-archive {
                      &__text {
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        direction: ltr;
                      }
                    }
                  }

                  .button-download {
                    width: 67px;
                    height: 16px;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 112.5%;
                    text-align: right;
                    color: #346aff;
                  }
                }

                .progress {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0px;
                  width: 100%;
                  height: 4px;
                  background: #edf2ff;
                  border-radius: 100px;

                  // .line {
                  //   width: 100%;
                  //   height: 4px;
                  //   background: #455cc7;
                  //   border-radius: 100px;
                  // }
                }
              }

              .delete-archive {
                cursor: pointer;
              }
            }
          }

          &__add-archive {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            width: 100%;
            height: 40px;
            background: #fefefe;
            border: 1px dashed #b4b9c1;
            border-radius: 4px;

            > svg {
              cursor: pointer;
            }

            > input {
              display: none;
            }

            &__text {
              width: 135px;
              height: 18px;
              display: block;
              cursor: pointer;
            }
          }
        }

        .container-comments {
          // padding-top: 10%;
          padding-top: 5%;

          &__header {
            display: flex;
            position: sticky;
            flex-direction: column;
            top: 0;
            left: 0;
            z-index: 15;
            background-color: $white;
            gap: 8px;

            &.border-bottom {
              border-bottom: 3px solid rgba(0, 0, 0, 0.1);
              transition: 500ms linear;
              transition-property: font-size;
            }

            &__content {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .ordination {
              display: flex;
              gap: 6px;

              > svg {
                transform: rotate(270deg);
                cursor: pointer;

                &.active {
                  transform: rotate(450deg);
                  cursor: pointer;
                }
              }
            }
          }

          .comment-content-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            padding-top: 0.5rem;
            gap: 8px;

            &__add-comment {
              display: flex;
              width: 100%;
              gap: 10px;
            }

            &__responses {
              width: 100%;
              padding-top: 0.7rem;
              // padding: 20px;

              .container-response-list {
                display: flex;
                flex-grow: 1;
                padding: 3px 10px;
                gap: 8px;
                align-items: center;

                > svg {
                  cursor: pointer;
                }
              }
            }
          }

          &__list {
            width: 100%;

            &__empty-state {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 10px;
              height: 15rem;
              width: 100%;

              .container-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 120px;
                height: 120px;
                background-color: $blue-selected-3;
                border-radius: 50%;

                &.red-color {
                  background-color: $red-light2;
                }
              }
            }
          }
        }
      }
    }

    .task-details-panel {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 20px;
      overflow: auto;
      overflow-x: hidden;

      &__panel {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
        padding-top: 15px;

        .task-details-client-date-container {
          display: flex;
          gap: 8px;
        }

        .task-details-header__profile {
          display: flex;
          gap: 8px;
        }

        .task-details-tag {
          display: flex;
          gap: 8px;
          margin-top: 5px;
        }
      }

      border-left: 1px solid rgba(0, 0, 0, 0.1);
      width: 600px;

      &::-webkit-scrollbar {
        width: 1px;
      }
    }

    &-description {
      grid-area: description;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .task-details-description {
        display: flex;
        flex-direction: column;

        &__textarea {
          width: 100%;
          padding: 16px 16px;
          // height: 216px;
          resize: none;
          border: 1px solid $gray-175;
          border-radius: 8px;
          background-color: $white;
          outline: 0;

          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #757575;
        }

        textarea:focus {
          border-color: $primary-dark;
        }
      }
    }
  }

  /* ======================== OLD STYLE================================== */
  //QUANDO ESTIVER COM TODOS ELEMENTOS, RETIRAR O TEMPLETE ROWS E HEIGHT
  //AJEITAR O TEMPLATE AREAS TBM
  &__main {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-template-rows: 63px 63px calc(100% - 250px) 50px;
    height: calc(100% - 60px);
    // calc(100% - 57px);
    grid-template-areas:
      "client deadline"
      "description tags"
      "description comments"
      "description comments";
    gap: 20px;
    padding: 16px 5px;

    .task-details-client {
      grid-area: client;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      //margin-right: 75px;

      // &__name {
      //    h5 {
      //     width: 200px;
      //     @include text-overflow;
      //   }
      // }

      &__company {
        .typography-h5 {
          display: flex;
          align-items: center;
          height: 40px;
        }
      }
    }

    .task-details-deadline {
      grid-area: deadline;
      display: flex;
      justify-content: space-between;

      .task-details-client-date-container {
        display: flex;
        gap: 8px;
      }

      .task-details-client-date {
        display: flex;
        gap: 16px;
      }
    }

    .task-details-communication {
      grid-area: comunication;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &__list {
        display: flex;
        gap: 8px;
      }
    }

    .task-details-tag {
      grid-area: tags;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .btn-to-view {
        width: 40px;
        text-align: center;
        color: $primary-dark;
      }
    }

    &-description {
      grid-area: description;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;

      .task-details-description {
        display: flex;
        flex-direction: column;

        textarea {
          padding: 16px 16px;
          height: 400px;
          resize: none;
          border: 1px solid $gray-175;
          border-radius: 8px;
          background-color: $white;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          line-height: 18px;
          color: $gray-400;
          outline: 0;
        }

        textarea:focus {
          border-color: $primary-dark;
        }
      }
    }

    .task-details-comment {
      grid-area: comments;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    .task-details-files {
      grid-area: files;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .task-details {
    &__header {
      flex-direction: column;
      align-items: flex-start;

      .task-details-header__title {
        width: 100%;
      }

      .task-details-header__options {
        width: 100%;

        .task-details-header__profile {
          margin-right: unset;
        }
      }
    }
    .task-details-header__title {
      &__text {
        margin-right: 4px;

        .task-details__title-task {
          > h2 {
            max-width: 430px;
          }
        }

        > input {
          max-width: 430px;
        }
      }
    }

    .task-details-header__options {
      gap: 8px;

      .delete-task {
        margin-left: 0px;
      }
    }

    &__main {
      grid-template-columns: calc(100%);
      grid-template-rows: 63px 63px auto 63px 400px;
      grid-template-areas:
        "client"
        "deadline"
        "description"
        "tags"
        "comments";
    }
    // grid-template-areas:
    //   "client"
    //   "deadline"
    //   "comunication"
    //   "description"
    //   "tags"
    //   "comments"
    //   "files";
    // }
  }
}

/* ======================== NEW STYLE================================== */
// @media screen and (max-height: 800px) {
//   .task-details {
//     &__body {
//       height: 91%;

//       .task-details-panel {
//         &__panel {
//           flex-grow: 1;
//           display: flex;
//           flex-direction: column;
//           align-items: flex-start;
//           align-items: center;
//           gap: 13px;
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 800px) {
  .task-details {
    padding-top: 10px;

    &__header {
      flex-direction: row;
    }

    &__body {
      flex-direction: column-reverse;
      height: fit-content;

      &__client {
        margin-top: 5px
      }

      .task-details-panel {
        width: 100%;
        border-left: none;
        width: 100%;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &__panel {
          width: 100%;
          gap: 15px;

          .task-details-profile-list,
          .task-details-status,
          .task-details-case-vinculation,
          .task-details-client__company,
          .task-details-date,
          .task-details-remider,
          .task-details-client__name,
          .task-details-tags-categories {
            width: 100%;
          }
        }
      }
    }
  }
}
