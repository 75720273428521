@import "./../../../styles/colors";

.page-contact {
    background-color: #ffffff;
    padding: 24px;
    height: 100%;
    width: 100%;

    &.contact-modify {
        border-top: 1px solid #d9dae1;
    }

    .page-contact-header {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 0 0 16px 0;
        border-bottom: 1px solid $gray-75;
        background-color: #ffffff;
        position: sticky;
        z-index: 20;
        top: 0;

        &__title {
            display: flex;
            align-items: center;

            > svg {
                cursor: pointer;
            }

            .page-contact-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                margin-left: 10px;
            }
        }

        &__options {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            width: 100%;

            .add-contact-company {
                margin-left: auto;
                display: flex;
                gap: 15px;
            }
        }
    }

    .page-contact-body {
        display: flex;
        gap: 18px;
        height: 94%;

        .emptySate-contact-list,
        .emptySate-company-list {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .container-sections-contact {
        display: flex;
        width: 350px;
        justify-content: space-between;
        flex-direction: column;
        padding: 20px 0;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        .panel-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 323px;
            height: 48px;

            &__options-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 4px;
                width: 271px;
                height: 48px;

                .person-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    width: 133px;
                    height: 48px;

                    .underline {
                        &.active {
                            box-sizing: border-box;
                            width: 133px;
                            height: 0px;
                            border: 1px solid #455cc7;
                            margin-top: 8px;
                        }
                    }

                    .person {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        gap: 10px;
                        width: 133px;
                        height: 48px;

                        &__text {
                            height: 24px;
                            font-family: "Inter";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            color: #757575;
                            cursor: pointer;

                            &.active {
                                height: 24px;
                                font-family: "Inter";
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: #455cc7;
                            }
                        }
                    }
                }
            }

            &__border {
                width: 323px;
                height: 0px;
                border: 1px solid #b4b9c1;

                margin-top: -15px;
            }
        }

        .panel-filters {
            display: flex;
            overflow: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 6px;
            width: 315px;
            flex-grow: 1;
            margin-top: 10px;
        }

        &__text-information {
            height: 20px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #757575;
        }

        .loading-company-list,
        .loading-contact-list {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 1200px;
            top: 40px;
        }

        &__footer {
            background: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: auto;

            .division {
                width: 100%;
                height: 0px;
                border: 1px solid rgba(0, 0, 0, 0.1);
            }

            .buttons-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;
                width: 322px;
                height: 40px;
                margin-top: 10px;

                .clean-icon {
                    path {
                        fill: $red-2;
                    }
                }

                .disabled-icon {
                    path {
                        fill: $gray-125;
                    }
                }

                .buttons-aplyfilters {
                    font-size: 16px;
                    line-height: 24px;
                }

                .disabled-aplyfilters {
                    background-color: #d9dae1;
                    pointer-events: none;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }

    .contact-content-main {
        flex-grow: 1;
        overflow: auto;
        margin-top: 20px;

        .table-company,
        .table-contact {
            width: 100%;

            .case-btn-options {
                > svg {
                    transform: rotate(270deg);
                }
            }

            .container-actions {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }

            .separator-options-contact {
                width: 90px;
            }

            .separator-name-company {
                width: 900px;
            }

            .separator-options-company {
                width: 90px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .page-contact {
        .header-contact {
            margin-bottom: 18px;

            &__left-content {
                gap: 16px;
            }

            .btn-add-company {
                padding: 0;
                border: none;
                border-radius: 0;

                .typography-h4 {
                    display: none;
                }
            }

            .btn-add-contact {
                padding: 0;
                border: none;
                border-radius: 0;
                background-color: #fff;

                > svg {
                    stroke: $blue-selected-4;
                }

                .typography-h4 {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-height: 780px) {
    .page-contact {
        .container-sections-contact {
            height: auto;

            .contact-container-panel {
                .panel-filters {
                    max-height: 300px;
                }
            }

            .loading-company-list,
            .loading-contact-list {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 930px;
                top: 80px;
            }

            .emptySate-contact-list,
            .emptySate-company-list {
                position: absolute;
                left: 900px;
                top: 300px;
            }

            .contact-content-main {
                width: 930px;
                left: 535px;
            }

            footer {
                top: 610px;
                height: 0;
            }
        }
    }
}

@media screen and (max-height: 630px) {
    .page-contact {
        .container-sections-contact {
            .loading-company-list,
            .loading-contact-list {
                left: 930px;
                top: 80px;
            }

            .emptySate-contact-list,
            .emptySate-company-list {
                left: 850px;
            }

            .contact-content-main {
                width: 800px;
                left: 535px;
                height: 300px;
            }

            height: 485px;

            .panel-filters {
                max-height: 400px;
                overflow: auto;
                height: 300px;
                padding: 10px 10px;

                &::-webkit-scrollbar {
                    width: 2px;
                }
            }

            footer {
                top: 540px;
                height: 0;
            }
        }
    }
}
