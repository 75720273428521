@import "./../../../styles/colors";

.page-status {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $white;
    padding: 25px 10px;
    gap: 30px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        &__title {
            display: flex;
            height: 40px;
            align-items: center;
            align-self: stretch;
            gap: 8px;

            > svg {
                cursor: pointer;
            }
        }

        &__save-or-cancel {
            display: flex;
            align-items: center;
            gap: 10px;

            .btn-cancel-status.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $red-2;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }
        }
    }

    .status-card {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 4px;
        background: #fefefe;
        box-shadow:
            0px 1px 1px 0px rgba(21, 21, 24, 0.18),
            0px 0px 1px 0px rgba(25, 25, 28, 0.25);
        overflow: auto;

        &__loading {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }

        // .status-card-content {
        //     display: flex;
        //     padding: 16px;
        //     flex-direction: column;
        //     align-items: center;
        //     gap: 16px;
        //     align-self: stretch;

        //     &__details {
        //         display: flex;
        //         // align-items: flex-end;
        //         align-items: center;
        //         gap: 16px;
        //         align-self: stretch;

        //         .grdrag-container {
        //             padding-top: 30px;

        //             > svg {
        //                 width: 18px;
        //                 height: 18px;
        //                 stroke: $gray-180;
        //                 cursor: grab;
        //             }
        //         }

        //         &__actions {
        //             display: flex;
        //             align-items: center;
        //             padding-top: 30px;
        //             gap: 16px;

        //             .remove-status {
        //                 cursor: pointer;

        //                 path {
        //                     stroke: $red-2;
        //                 }

        //                 &.disabled {
        //                     cursor: not-allowed;

        //                     path {
        //                         stroke: #BFC3CA
        //                     }
        //                 }
        //             }

        //             .add-status {
        //                 cursor: pointer;

        //                 path {
        //                     stroke: $blue-selected-4;
        //                 }
        //             }
        //         }
        //     }
        // }

        .status-card-content {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            align-self: stretch;

            &__details {
                display: flex;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                .grdrag-container {
                    display: flex;
                    align-items: center;

                    &.padding-top {
                        padding-top: 20px;
                    }

                    > svg {
                        width: 18px;
                        height: 18px;
                        stroke: $gray-180;
                        cursor: grab;
                    }
                }

                .field-container {
                    flex-grow: 1;
                }

                &__actions {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    &.padding-top {
                        padding-top: 20px;
                    }

                    .remove-status {
                        cursor: pointer;

                        path {
                            stroke: $red-2;
                        }

                        &.disabled {
                            cursor: not-allowed;

                            path {
                                stroke: #bfc3ca;
                            }
                        }
                    }

                    .add-status {
                        cursor: pointer;

                        path {
                            stroke: $blue-selected-4;
                        }
                    }
                }
            }
        }
    }
}
