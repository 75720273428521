@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.category {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn-expand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    transform: rotate(180deg);
    transition: all 0.25s;
    // border: 1px solid;
    // border-radius: 50%;

    > svg {
      // width: 12px;
      // height: 12px;

      width: 24px;
      height: 24px;

      path {
        fill: $gray-180;
      }
    }
  }

  &__title {
    display: flex;

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding: 2px 8px;
      margin-right: 8px;
      margin-left: 34px;
      border-radius: 4px;
      @include drop-shadow-soft;
      transition: all 0.25s;
    }
  }

  &__btn-new-task {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
      width: 16px;
      height: 16px;
      fill: $blue-selected-4;
      /* fill: $gray-300; */
    }
  }

  &__container-tasks {
    position: relative;
    display: none;
    flex-direction: column;
    gap: 1px;
    margin-left: 34px;
    @include drop-shadow-soft;

    .task {
      display: flex;
      gap: 12px;
      height: 40px;
      padding: 0 8px;
      background-color: $white;
      /* border-left: 4px solid; */
      border-bottom: 1px solid $gray-75;
      transition: all 0.25s;

      &__title {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 8px 0;

        .container-title {
          flex-grow: 1;
          display: flex;

          &__text {
            flex-grow: 1;
            width: 0px;
            min-width: 100px;
            padding: 9px 0;
            @include text-overflow;
          }
        }
      }

      &__tags {
        display: flex;
        align-items: center;
        gap: 5px;

        > svg {
          fill: $gray-180;
        }

        .tag-list {
          &__buttons {
            &__view {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      &__features {
        display: flex;
        gap: 5px;

        .icon-notification,
        .icon-attachment {
          display: flex;
          justify-content: center;
          align-items: center;

          > svg {
            width: 24px;
            height: 24px;
          }

          .fill {
            fill: $gray-85;
          }

          .stroke {
            stroke: $gray-85;
          }

          &.active {
            .fill {
              fill: $primary-dark;
            }

            .stroke {
              stroke: $primary-dark;
            }
          }
        }
      }

      &__deadline {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
      }

      .container-client-name {
        display: flex;
        justify-content: center;
        align-items: center;

        &__text {
          /* width: 150px; */
          width: 120px;
          text-align: center;
          @include text-overflow;
        }
      }

      &__profiles {
        display: flex;
        width: fit-content;
        // width: 170px;

        &__content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }

        .profile-list {
          flex-grow: 1;
          justify-content: space-between;

          &__popover {
            &__view {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      &__btn-options {
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;

        > svg {
          /* fill: $gray-175; */
          transform: rotate(270deg);
        }
      }

      &:hover {
        background-color: $gray-50;
      }
    }
  }

  // COLOR SCHEMES
  &--scheme-blue {
    .category {
      &__btn-expand {
        border-color: $primary-dark;

        > svg {
          fill: $primary-dark;
        }
      }

      &__title {
        &__content {
          background-color: $primary-dark;
        }
      }

      /* &__container-tasks {
        .task {
          border-left-color: $primary-dark;
        }
      } */
    }
  }

  &--scheme-yellow {
    .category {
      &__btn-expand {
        border-color: $yellow;

        > svg {
          fill: $yellow;
        }
      }

      &__title {
        &__content {
          background-color: $yellow;
        }
      }

      /* &__container-tasks {
        .task {
          border-left-color: $yellow;
        }
      } */
    }
  }

  &--scheme-green {
    .category {
      &__btn-expand {
        border-color: $green;

        > svg {
          fill: $green;
        }
      }

      &__title {
        &__content {
          background-color: $green;
        }
      }

      /* &__container-tasks {
        .task {
          border-left-color: $green;
        }
      } */
    }
  }

  &--active {
    .category {
      &__btn-expand {
        // transform: rotate(180deg);
        transform: rotate(0deg);

        > svg {
          path {
            fill: $blue-selected-4;
          }
        }
      }

      &__title {
        border-radius: 4px 4px 0 0;
        box-shadow: none;
      }

      &__container-tasks {
        display: flex;
      }
    }
  }
}
