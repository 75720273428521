@import "../../../../styles/colors";

.page-update-user {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $white;
    padding: 25px 10px;
    gap: 30px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        &__title {
            display: flex;
            height: 40px;
            align-items: center;
            align-self: stretch;
            gap: 8px;

            > svg {
                cursor: pointer;
            }
        }

        &__save-or-cancel {
            display: flex;
            align-items: center;
            gap: 10px;

            .btn-reset-user.btn-primary,
            .btn-cancel-user.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $blue-selected-4;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }

            .btn-remove-user.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $red-2;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }
        }
    }

    .section-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 15px;
        padding: 0 8px;
        overflow: auto;

        .basic-data-section,
        .join-organization-section,
        .staggered-views-section {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background: #fefefe;
            box-shadow:
                0px 0px 1px 0px rgba(25, 25, 28, 0.25),
                0px 1px 1px 0px rgba(21, 21, 24, 0.18);

            .basic-data-section-header,
            .join-organization-section-header,
            .staggered-views-section-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                align-self: stretch;

                &__content {
                    display: flex;
                    padding: 16px;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;
                }

                &__division {
                    width: 100%;
                    height: 1px;
                    background-color: #e5e7eb;
                }
            }

            .join-organization-section-header {
                &__content {
                    justify-content: space-between;
                }

                &__clear-fields {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;

                    &.cursor-notAllowed {
                        cursor: not-allowed;
                    }

                    .clean-icon {
                        path {
                            fill: $red-2;
                        }
                    }

                    .disabled-icon {
                        path {
                            fill: $gray-125;
                        }
                    }
                }
            }

            .staggered-views-section-header {
                &__content {
                    justify-content: space-between;

                    > button {
                        display: flex;
                        gap: 8px;
                    }
                }
            }

            .basic-data-content-container,
            .join-organization-content-container,
            .staggered-views-content-container {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;

                    .columns-data {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        width: 100%;
                        align-items: flex-start;
                        align-self: stretch;
                        gap: 16px;
                    }
                }
            }

            .basic-data-content-container {
                &__content {
                    .user-association {
                        display: flex;
                        padding-top: 30px;
                        gap: 4px;
                    }
                }
            }

            .join-organization-content-container {
                &__content {
                    .first-column-data {
                        display: flex;
                        width: 100%;
                        align-items: flex-start;
                        gap: 16px;
                        align-self: stretch;

                        &__actions {
                            display: flex;
                            align-items: center;
                            padding-top: 30px;
                            gap: 16px;

                            .remove-association {
                                cursor: pointer;

                                path {
                                    stroke: $red-2;
                                }

                                &.disabled {
                                    cursor: not-allowed;

                                    path {
                                        stroke: #bfc3ca;
                                    }
                                }
                            }

                            .add-association {
                                cursor: pointer;

                                path {
                                    stroke: $blue-selected-4;
                                }
                            }
                        }
                    }

                    &__division {
                        width: 100%;
                        height: 1px;
                        background: rgba(0, 0, 0, 0.1);
                    }

                    .permission-user-create-case {
                        display: flex;
                        gap: 10px;
                        padding: 5px 0;

                        .radio-label {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            .custom-radio {
                                cursor: pointer;
                                width: 18px;
                                height: 18px;

                                &:checked::before {
                                    background-color: $blue-selected-4;
                                }
                            }
                        }
                    }
                }
            }

            .staggered-views-content-container {
                &__content {
                    .table-staggered {
                        .staggered-name {
                            width: 100%;
                        }

                        .staggered-toView {
                            border-left: 1px solid #e3e3e3;
                            border-bottom: 1px solid #e3e3e3;
                        }
                    }

                    .staggered-loading {
                        display: flex;
                        height: 100%;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.checkbox-container input[type="checkbox"] {
    display: none;
}

.checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 3.6px solid $gray-180;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    transition:
        background-color 0.3s,
        border-color 0.3s;

    &:hover {
        border-color: $blue-selected-4;
    }
}

.checkmark.checked {
    background-color: $blue-selected-4;
    border: 3.6px solid $blue-selected-4;
}

.check-icon {
    color: #fff;
    font-size: 25px;
}
