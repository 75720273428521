.tooltip-text{
    padding: 8px 16px;

    background: rgba(24, 24, 27, 0.733333);
    border-radius: 4px;

    display: flex;
    align-items: center;
    gap: 5px;

}

.text-style{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}