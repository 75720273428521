@import "./../../../styles/colors";

.carousel-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  //background-color: $gray-50;
  border-radius: 8px;
  //margin: 0 32px;

  // &.carousel-tag--left {
  //   justify-content: flex-start;
  // }

  .carousel-tag-list {
    display: flex;
    gap: 8px;

    .carousel-tag-list-tag {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
      }

      &__text {
        max-width: 65px;
        width: 65px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
      }

      &__icon {
        fill: $red;
        width: 18px;
        height: 18px;
      }
    }
  }

  > svg {
    fill: $gray-100;
    min-width: 24px;
  }

  .carousel-tag__icon {
    fill: $gray-200;
    cursor: pointer;
  }
}