@import "./../../../../styles/colors";

.case-historic {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  max-height: 100%;

  .case-historic-filters {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-100;

    &__selects {
      display: flex;
      gap: 8px;
      width: 100%;

      .field-container {
        width: 100%;
      }

      .case-historic-filters-campos {
        .typography-paragraph {
          font-size: 12px;
          line-height: 18px;
        }

        button {
          border-bottom: 1px solid $gray-100;
        }
      }
    }

    &__tags {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0;
    overflow: auto;
    padding-right: 16px;
    padding-bottom: 5px;

    .case-historic-empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      height: 100%;

      .container-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        background-color: $red-light2;
        border-radius: 50%;
      }
    }
  }
}
