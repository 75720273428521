@import "./../../styles/colors";

.page-template {
  padding: 10px 0px 0px 14px;
  background-color: $gray-80;
}

.page-template-container {
    display: flex;
    flex-direction: column;
    // height: 500px;
    padding: 14px 14px 0px 14px;
    margin-left: 4%;
    gap: 16px;
    background-color: $white;

    .header-template-container {
      display: flex;
    }

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-left: -20px;

        &-date-range {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            input {
              padding-left: 16px;
              padding-bottom: 3px;
              width: 234px;
              height: 36px;
              border-radius: 5px;
              border: 1px solid $gray-125;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: $gray-180; 
            }

            &__icon {
              margin: 8px;
              display: flex;
              position: absolute;
            }
        }

        &-add-template {
            display: flex;
            align-items: center;
            // gap: 12px;
        }
    }

    &__actions-categories {
      display: grid;
      grid-template-columns: 38% 62%;

      &__table {
        display: grid;
        height: 100%;
        
        &-values {
          height: calc(100% - 100px);
          // max-height: 55%;
          overflow: auto;
          
          &__box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            :hover {
              background-color: $gray-80; 
            }

            svg {
              transform: rotate(270deg);
              margin-right: 10px;
            }
          }
        }

        &-title {
          display: flex;
          align-items: center;
          width: 100%;
          height: 48px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: $blue-selected-4;
          padding: 14px;
        }

        &-rows_primary {
          display: flex;
          align-items: center;
          width: 100%;
          height: 48px;
          background-color: $white-50; 
          padding: 14px;
          cursor: pointer;
        }

        &-rows_secondary {
          display: flex;
          align-items: center;
          width: 100%;
          height: 44px;
          padding: 14px;
          cursor: pointer;
        }
      }

      &__empty {
        display: grid;
        align-items: center;
        justify-content: center;
      }

      &__info {
        // display: grid;
        padding: 14px;

        &__header {
          display: flex;
          justify-content: space-between;

          &-input {
            width: 270px;
          }

          &-button {
            margin-top: 26px;
          }
        }

        &__box {
          display: flex;
          flex-direction: column;
          
          &-container {
            align-items: center;
            height: 40px;
            width: 100%;
            border: 1px solid $gray-80;
            border-radius: 4px;
            margin: 16px 16px 0px 0px;

            &__items {
              display: flex;
              padding-left: 22px;
              gap: 22px;
              height: 100%;
              align-items: center;
            }
          }
        }
      }
    }
}