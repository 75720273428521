@import "../../styles/colors";
@import "../../styles/mixins";

.nav-bar {
  background-color: #ffffff;
  height: 100%;
  position: relative;
  // margin-top: 19px;
  margin-right: 0px;
  float: left;
  z-index: 50;
  // padding-top: 19px;
  // min-width: 59px;
  width: 60px;
  // max-width: 185px;
  box-shadow: 2px 0 4px rgb(0 0 0 / 10%);
  
 

  .nav-bar-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding-top: 19px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: all 0.3s ease-in-out 0s;

    &.dark-mode{
      background-color: $secondary-06-dark;
    }

    &.exhib-navbar, &:hover{
      position: absolute;
      box-shadow: 10px 10px 30px rgb(0 0 0 / 20%);
      width: 185px;

      span {
        visibility: visible;
        transition: all 0.3s ease-in-out 0s;
      }
    }

    .nav-bar-container__option {
      display: flex;
      // margin-bottom: 24px;
      // margin-left: 12px;
      cursor: pointer;

      svg {
        width: 55px;
      }

      &:hover {
        path {
          fill: $primary-hover;
          color: $primary-hover;
        }
    
        &:nth-child(2) path {
          stroke: $primary-hover;
          fill: none;
        }

      }
    }

    span {
      visibility: hidden;
      width: 0;
      margin-top: 5px;
      font-size: 14px;
      font-family: "Inter";
      color: $gray-200;
      line-height: 16px;
      font-weight: 500;
    

      &:hover {
        // background-color: rgb(246, 242, 242);  
        color: $primary-hover;
        .nav-bar-container__option-text {
          color: $primary-hover;
        }

        .text_option-inactive {
          color: $primary-hover;
        }
      }
    }

    .text_option {
      display: none;
      font-size: 14px;
      font-family: "Inter";
      font-style: normal;
      line-height: 16px;
      color: $primary-light;
    }

    .icon_option-active path {
      fill: $primary-light;
    }

    .icon_option-twoactive path {
      stroke: $primary-light;
    }

    .icon_option-twoactive path:hover {
      stroke: $primary-light;
    }

    .text_option-active {
      margin-top: 5px;
      font-size: 14px;
      font-family: "Inter";
      font-style: normal;
      line-height: 16px;
      color: $primary-light;
      font-weight: 500;
    }

  }
}

.nav-bar-border {
  margin-top: 0px;
  float: left;
  width: 0.2%;
  height: inherit;
  // height: calc(100vh - 61px);
  background-color: $gray-80;
  opacity: 30%;
}
