.custom-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 112.5%;
    fill: #FFFFFF;
  }

  .tooltip-text-radial{
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 10px;

    background: rgba(24, 24, 27, 0.733333);
    border-radius: 4px;

    h6{
      display: flex;
      align-items: flex-end;
      white-space: nowrap;
    }

  }