@import "./../../styles/colors";

.page-dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 77px 48px 77px;
  gap: 16px;
  background-color: $white-100;
  overflow: auto;
  transition: all 0.3s ease-in-out 0s;

  .header-dashboard-container {
    z-index: 20;
    position: sticky;
    top: 0;
    // flex-direction: column;
    // gap: 24px;
    padding-top: 24px;
    // margin-right: 16px;
    background-color: $white-100;

    &.dark-mode {
      background-color: $secondary-05-dark;

      h2 {
        color: $white;
      }
    }

    &__left-content {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 18px;
      width: 100%;

      &.title-modify {
        h2 {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      h2 {
        color: $blue-selected-4;

        span {
          color: $gray-125;
        }
      }
    }

    &__right-content {
      width: 100%;

      .header-dashboard-container__tabs {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .menu-tabs-list {
          justify-content: flex-start;
          gap: 10px;
        }
      }
    }

    &__date-range-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .dateRangeInput.MuiFormControl-root.MuiTextField-root {
        width: 155px;

        .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
          background-color: $white;
        }

        &.dark-mode {
          .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
            border: 1px solid #bfc3ca;
            color: $white;
          }

          .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary {
            color: $white;
          }

          .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
            background-color: transparent;
          }

          .MuiButtonBase-root.MuiIconButton-root {
            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }

      // input {
      //   padding-left: 16px;
      //   padding-bottom: 3px;
      //   width: 234px;
      //   height: 36px;
      //   border-radius: 5px;
      //   border: 1px solid $gray-125;
      //   font-family: 'Inter';
      //   font-style: normal;
      //   font-weight: 400;
      //   font-size: 14px;
      //   color: $gray-180;

      //   &.dark-mode{
      //     background-color: transparent;
      //     color: $white;

      //   }
      // }

      // &__icon {
      //   margin: 8px;
      //   display: flex;
      //   align-items: center;
      //   position: absolute;

      //   .dark-mode{
      //     path {
      //       fill: white;
      //     }
      //   }
      // }
    }

    &__button-actions {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-left: auto;

      &__download {
        cursor: pointer;
      }

      .btn {
        .btn-more-filters__content {
          display: flex;
          align-items: center;
          gap: 8px;

          .total-filters {
            display: flex;
            width: 18px;
            height: 18px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background-color: $yellow-2;
            border-radius: 999px;

            color: $black;
          }
        }
        // svg {
        //   path {
        //     fill: $white;
        //   }
        // }
      }
      .dark-mode {
        path {
          fill: white;
        }
      }

      .btn.dark-mode {
        background-color: transparent;
        border-color: $white;

        &:hover {
          background-color: #bfc3ca;
        }
      }
    }
  }

  &__cardsBox {
    // display: grid;
    // grid-template-columns: 1fr 1.52fr;
    // gap: 16px;
    // grid-template-rows: 1.5fr;

    // #### NEW STYLE ###
    display: flex;
    width: 100%;
    gap: 16px;

    // #### NEW STYLE ###
    &__wrapper {
      display: flex;
      width: 100%;
      gap: 16px;
    }
  }

  // #### NEW STYLE ###
  &__statusCard {
    width: 100%;
  }

  &__cardsOne {
    // display: grid;
    // // grid-template-columns: 25% 25% 25% 25%;
    // grid-template-columns: repeat(5, 1fr);
    // gap: 16px;

    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
  }

  &__cardsTwo {
    display: grid;
    width: 100%;
    // margin-right: 16px;
  }

  &__cardsThree {
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: 0.5fr 1.5fr;
    // width: 100%;
    // gap: 16px;

    // #### NEW STYLE ###
    display: flex;

    &-container {
      // display: grid;
      // gap: 16px;
      // grid-template-columns: repeat(3, 1fr);

      // #### NEW STYLE ###
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 16px;

      &.column-2 {
        grid-template-columns: repeat(2, 1fr);
        min-height: 300px;
      }
    }
  }

  &__cardsFour {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  &__cardsFive {
    display: grid;
    // grid-template-columns: 75% 24%;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  &__mode-dark {
    position: fixed;
    bottom: 32px;
    right: 0;
    // display: flex;
    // justify-content: flex-end;
    // margin: -34.5px -48px -34.5px 0px;

    .btn.btn-primary {
      background-color: transparent;
      border: none;

      &.darkMode {
        svg {
          path {
            fill: $white;
          }
        }
      }

      svg {
        width: 35px;
        height: 35px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.page-dashboard-container.dark-mode {
  background-color: $secondary-05-dark;

  .card-dashboard,
  .card-dashboard__solicited,
  .card-dashboard__varied,
  .card-dashboard__resolution,
  .card-dashboard__activity,
  .card-dashboard__perfomance {
    background-color: $secondary-06-dark;
  }

  .card-dashboard__solicited {
  }
}

.page-dashboard-details {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-top: 16px;
  height: inherit;

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;

    &-icon {
      cursor: pointer;

      path {
        fill: $blue-selected-4;
      }

      &.dark-mode {
        path {
          fill: $white;
        }
      }
    }

    h1 {
      line-height: 32px;
    }
  }

  &__graphic {
    height: 100%;

    &.divisor-graphic {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 100px;
      padding-top: 28px;
      height: 100%;
      width: 100%;

      .subtitles_graphic {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .graphic-container {
        width: 60%;
        height: 100%;
      }
    }

    &-box {
      display: flex;
      align-items: center;
      gap: 8px;

      h5 {
        white-space: nowrap;
      }
    }
  }
}

.page-dashboard__mode-dark {
  // display: flex;
  // justify-content: flex-end;
  // float: right;
  margin: 10px 34.5px 34.5px 34.5px;
}
