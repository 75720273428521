// @import "./../../../../styles/colors";

// .modal-create-task {
//   // overflow: auto;

//   .modal {
//     &__body {
//       // display: flex;
//       // flex-direction: column;
//       gap: 14px;
//     }
//   }

//   &__container-footer {
//     position: absolute;
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     // padding: 0px;
//     gap: 8px;
//     // height: 40px;
//     right: 24px;
//     top: 607px;

//     .line-top {
//       position: absolute;
//       // width: 1040px;
//       width: 65rem;
//       height: 0px;
//       // margin-top: -25px;
//       margin-top: -1.7rem;
//       right: 0;
//       border: 1px solid rgba(0, 0, 0, 0.1);
//       transform: rotate(180deg);
//     }
//   }

//   &__header {
//     display: flex;
//     align-items: center;
//     gap: 16px;
//   }

//   &__content {
//     display: grid;
//     grid-template-columns: 1fr 350px;
//     // grid-template-columns: 1fr 300px;
//     // max-height: 500px;
//     column-gap: 8px;
//     row-gap: 14px;
//     gap: 30px;
//     padding: 10px;
//     // overflow-y: hidden;
//     // overflow-x: hidden;

//     .container01 {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       gap: 18px;
//       flex-grow: 1;
//     }

//     .container02 {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       gap: 23px;
//       flex-grow: 1;
//     }

//     .field-container-task-name {
//       grid-column: span 2;
//     }

//     .field-container-task-desc {
//       grid-column: span 2;

//       &__input {
//         // min-height: 90px;
//         // max-height: 110px;
//         min-height: 135px;
//         max-height: 145px;

//         // border: 1px solid #bfc3ca;
//         border-radius: 5px;
//       }
//     }

//     .field-container-task-user {
//       &__container {
//         display: flex;
//         gap: 4px;
//         padding-top: 4px;
//       }
//     }

//     .field-container-task-archive {
//       &__container {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         padding: 8px;
//         gap: 8px;
//         width: 670px;
//         height: 40px;
//         background: #fefefe;
//         border: 1px dashed #b4b9c1;
//         border-radius: 4px;

//         > svg {
//           cursor: pointer;
//         }

//         &__text {
//           font-family: "Inter";
//           font-style: normal;
//           font-weight: 500;
//           font-size: 16px;
//           line-height: 112.5%;
//           color: #111214;
//         }
//       }
//     }

//     .field-container-status {
//       &__container {
//         display: flex;
//         padding-top: 4px;
//       }
//     }

//     .field-container-date-time {
//       &__container {
//         display: flex;
//         gap: 8px;
//         padding-top: 4px;
//       }
//     }

//     .field-container-reminder {
//       grid-row: span 2;
//       display: flex;
//       flex-direction: column;
//       height: 140px;

//       &__container-list {
//         display: flex;
//         flex-direction: column;
//         gap: 4px;
//         max-height: 88px;
//         overflow-y: auto;
//         padding-top: 4px;

//         .reminder-item {
//           display: flex;
//           align-items: center;
//           gap: 8px;
//           padding-right: 8px;

//           &__btn-remove {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 12px;
//             height: 12px;
//             background-color: $primary-dark;
//             border-radius: 50%;

//             > svg {
//               fill: $white;
//             }
//           }
//         }
//       }

//       .btn-add-reminder {
//         display: flex;
//         justify-content: flex-start;
//         width: fit-content;
//         margin-top: 8px;
//       }
//     }

//     .field-container-tags {
//       &__container {
//         display: flex;
//         gap: 4px;
//         padding-top: 4px;
//       }
//     }

//     .dropzone-container {
//       grid-column: span 2;
//     }
//   }

//   &__btn-confirm {
//     // flex-grow: 1;
//     display: flex;
//   }

//   &__btn-cancel.btn-primary {
//     display: flex;
//     background: $white;

//     border: 1px solid $red-2;
//     border-radius: 5px;

//     &:hover {
//       background: $white-50;
//     }
//   }
// }

// @media screen and (max-width: 650px) {
//   .ReactModal__Body--open {
//     overflow: hidden;
//   }
//   .modal-create-task {
//     width: 100%;
//     height: 100%;
//     &__content {
//       display: block;
//       label + label {
//         padding-top: 5px;
//       }
//       &.modal-create-task__content .field-container-reminder {
//         height: auto;
//       }
//     }
//   }
// }

@import "./../../../../styles/colors";

.modal-create-task {
  .modal {
    &__body {
      gap: 14px;
    }
  }

  &__container-footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    right: 24px;
    top: 607px;

    .line-top {
      position: absolute;
      width: 65rem;
      height: 0px;
      margin-top: -1.7rem;
      right: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: rotate(180deg);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 350px;
    column-gap: 8px;
    row-gap: 14px;
    gap: 30px;
    padding: 10px;

    .container01 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
      flex-grow: 1;
    }

    .container02 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 23px;
      flex-grow: 1;
    }

    .field-container-task-name {
      grid-column: span 2;
    }

    .field-container-task-desc {
      grid-column: span 2;

      &__input {
        min-height: 135px;
        max-height: 145px;

        border-radius: 5px;
      }
    }

    .field-container-task-user {
      &__container {
        display: flex;
        gap: 4px;
        padding-top: 4px;
      }
    }

    .field-container-task-archive {
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 670px;
        height: 40px;
        background: #fefefe;
        border: 1px dashed #b4b9c1;
        border-radius: 4px;

        > svg {
          cursor: pointer;
        }

        &__text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 112.5%;
          color: #111214;
        }
      }
    }

    .field-container-status {
      &__container {
        display: flex;
        padding-top: 4px;
      }
    }

    .field-container-date-time {
      &__container {
        display: flex;
        gap: 8px;
        padding-top: 4px;
      }
    }

    .field-container-reminder {
      grid-row: span 2;
      display: flex;
      flex-direction: column;
      height: 140px;

      &__container-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-height: 88px;
        overflow-y: auto;
        padding-top: 4px;

        .reminder-item {
          display: flex;
          align-items: center;
          gap: 8px;
          padding-right: 8px;

          &__btn-remove {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            background-color: $primary-dark;
            border-radius: 50%;

            > svg {
              fill: $white;
            }
          }
        }
      }

      .btn-add-reminder {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        margin-top: 8px;
      }
    }

    .field-container-tags {
      &__container {
        display: flex;
        gap: 4px;
        padding-top: 4px;
      }
    }

    .dropzone-container {
      grid-column: span 2;
    }
  }

  &__btn-confirm {
    display: flex;
  }

  &__btn-cancel.btn-primary {
    display: flex;
    background: $white;

    border: 1px solid $red-2;
    border-radius: 5px;

    &:hover {
      background: $white-50;
    }
  }
}

@media screen and (max-width: 800px) { // ou 650px
  .modal-create-task {
    width: 100%;
    height: 100%;

    &__container-footer {
      right: 12px;
      top: auto;
      bottom: 12px;
    }

    &__content {
      display: block;
      gap: 20px;
      padding: 10px;

      .container01,
      .container02 {
        width: 100%;
      }

      .container02 {
        padding-top: 10px;
      }

      .field-container-task-name,
      .field-container-task-desc,
      .field-container-task-user,
      .field-container-tags,
      .field-container-select-case,
      .field-container-select-contact,
      .field-container-status,
      .field-container-date-time,
      .field-container-reminder {
        width: 100%;
      }

      .field-container-reminder {
        &__container-list {
          max-height: none;
          overflow-y: auto;
        }
      }
    }

    &__btn-cancel.btn-primary,
    &__btn-confirm {
      width: 100%;
    }
  }
}

@media screen and (max-height: 800px) {
  .modal-create-task {
    &__content {
      overflow: auto;
      max-height: 400px;
    }
  }
}

@media screen and (min-height: 801px) {
  .modal-create-task {
    &__content {
      overflow: auto;
      max-height: 700px;
    }
  }
}

// @media screen and (max-width: 600px) {
//   .modal-create-task {
//     width: 100% !important;
//     height: 100% !important;
//     top: 0 !important;
//     left: 0 !important;
//     right: 0 !important;
//     bottom: 0 !important;

//     &__container-footer {
//       position: static;
//       flex-direction: column;
//       align-items: center;
//       gap: 16px;
//       padding-top: 20px;
//     }

//     &__content {
//       display: flex;
//       flex-direction: column;
//       gap: 20px;

//       .field-container-task-name,
//       .field-container-task-desc,
//       .field-container-task-user,
//       .field-container-task-archive,
//       .field-container-status,
//       .field-container-date-time,
//       .field-container-reminder,
//       .field-container-tags,
//       .dropzone-container {
//         width: 100%;
//       }
//     }

//     &__btn-confirm,
//     &__btn-cancel.btn-primary {
//       width: 100%;
//       max-width: 300px;
//     }
//   }
// }
