@import "./../../../styles/colors";

.comment-image-container{
    width: 100%;
    height: 301px;

    &.row-divider{
        display: flex;
        gap: 16px;
        height: 100%;

        img {
            width: 47%;
        }
    }

    img {
        width: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    .column-divider{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        img {
            width: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }

    &__quantity{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        width: 153px;
        height: 143px;
        position: absolute;
        top: 50px;
        right: 20px;


        background: #131313;
        opacity: 0.35;

        &.more-four{
            top: 210px;
        }

        .lenght-content{
            font-size: 32px;
            line-height: 40px;
        }

    }

}

.modal-media{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 90px;
    position: fixed;
    background: rgba(20, 20, 20, 0.72);
    inset: 0;
    z-index: 100;
    
    &.hidden{
        display: none;
    }

    &__container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: growUp 0.5s;
        transition: opacity 0.15s;
    }

    &__header{
        display: flex;
        justify-content: flex-end;

        .btn-download{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: #33A0FF;
        }

        svg.modal-icon_close{
            margin-left: 120px;
            cursor: pointer;
            width: 40px;
            height: 40px;

            path {
                fill: $white;
            }
        }  
    }

    &__body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80%;
    
        .fit-container{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 80%;
            height: 100%;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    
        .btn-modal-image{
            width: 50px;
            height: 50px;
            border: 5px solid white;
            border-radius: 15px;

            &:disabled{
                border: none;
                cursor: default;
            }

            .modal-image__icon{
                width: 100%;
                height: 100%;
                cursor: pointer;
                
                fill: $white;
        
                &.disabled{
                    display: none;
                }
            }
        }
    }

    &__footer{
        display: flex;
        justify-content: center;
    
        .footer-img{
            width: 100%;
            display: flex;
            justify-content: space-between;
    
            &__carrousel{
                display: flex;
                gap: 12px;
                max-width: 80%;
                overflow-y: hidden;
                overflow-x: auto;


                .img-footer {
                    width: 96.8px;
                    height: 74px;

                    &.img-choose{
                        border: 2px solid black;
                    }

                    img{
                        object-fit: cover;
                    }
                }
                
            }
        }
    }

    
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    0% {
      margin-top: 50px;
    }
    100% {
      margin-top: 0;
    }
  }
  
  @keyframes growUp {
    0% {
      margin-top: 10px;
      transform-origin: center;
      transform: scale(0.9);
    }
    100% {
      margin-top: 0;
      transform-origin: center;
      transform: scale(1);
    }
  }

  @media screen and (max-width: 700px) {
    .modal-media{
        padding: 15px 20px;
    }
  }