@import "./../../../../styles/colors";

.table-case-interactions-container {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  
  align-items: center;
  height: 0px;
  overflow: auto;

  .css-wjh20t-MuiPagination-ul{
    flex-wrap: nowrap;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    max-width: 487px;
    text-align: center;

    .icon_empty-space{
      width: 120px;
      height: 120px;
      padding: 36px;
      background: $blue-selected-3;
      border-radius: 50%;

      &.red-color{
        background: $red-light2;
      }
    }
  }

  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-case-interactions-audit {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      gap: 8px;

      > svg {
        cursor: pointer;
        fill: $primary-dark;
      }
    }

    .comment-list-search {

      input {
        border: 1px solid $gray-200;
      }
    }

    &__information {
      display: flex;
      align-items: center;
      padding: 16px;
      background-color: $white;
      border-radius: 8px;
      gap: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      .table-case-interactions-audit-channel {
        display: flex;
        align-items: center;
        gap: 8px;

        .image-profile{
          width: 40px;
          height: 40px;
        }
        .channel{
          position: absolute;
          width: 16px;
          height: 16px;
          top: 80px;
          left: 40px;
        }
      }

      .table-case-interactions-audit-contact-container {
        display: flex;
        flex-direction: column;

        .table-case-interactions-audit-contact {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          .icon-arrows{
            width: 16px;
            height: 16px;

             path{
              stroke: $gray-180;
             }
          }
        }

        .table-case-interactions-audit-date {
          // align-self: flex-end;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
      overflow-y: auto;
      border: 1px solid $gray-175;
      border-radius: 10px;
      padding: 8px 16px;

      .comment-container{
        .image-profile{
          align-self: flex-end;
        }
      }

    }

    &__voice {
      background-color: $white;
      border: 1px solid $gray-175;
      border-radius: 8px;
      padding: 16px;

      .rhap_time{
        top: 25px;
      }
    }
  }
}

.table-case-interactions {
  tbody {
    tr {
      td {
        .cell-container {
          align-items: center;
        }
      }

      .cell-audit{
        .cell-container {
          justify-content: center;
        }
      }
    }
  }

  .cell-channel {
    .cell-container {
      align-items: center;
      gap: 8px;

      .channel-tooltip-contact-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: left;

        &__data {
          cursor: text;
        }
      }
    }
  }

  .cell-comments-count {
    .cell-container {
      .interactions-comments-count {
        .typography-h6 {
          color: $primary-dark;
          font-weight: 600;
        }

        &.comments-count--empty {
          .typography-h6 {
            color: $gray-200;
            font-weight: 400;
          }
        }
      }
    }
  }

  .cell-date {
    .cell-container {
      white-space: nowrap;
    }
  }

  .cell-audit {
    .cell-container {
      .btn-audit {
        width: 24px;
        height: 24px;
        margin-right: 25px;

        > svg {
          width: 100%;
          height: 100%;
          // fill: $primary-dark;
        }

        &--disabled {
          cursor: default;

          &.chat{
          > svg {
              path {
                fill: $gray-125;
              }
            }
          }

          &.voz{
            > svg {
              path {
                stroke: $gray-125;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .table-case-interactions-container {
    align-items: normal;
  }
  
}