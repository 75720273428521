@import "./../../../styles/colors";

.dropdown-select {
  position: relative;
  width: 170px;

  .dropdown-select-btn {
    width: inherit;
    height: 40px;
    border: 1px solid $gray-200;
    border-radius: 4px;
    background-color: #ffffff;
    cursor: pointer;
    transition: border-radius 0.25s;

    .dropdown-select-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      gap: 12px;
      padding: 8px 16px;
    }

    // svg {
    //   fill: $primary-dark;
    // }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-color: $primary-dark;
      border-right-color: $primary-dark;
      border-left-color: $primary-dark;
      border-bottom: 0;

      .dropdown-select-btn-container {
        height: 39px;
        border-bottom: 1px solid $gray-100;
        padding: 8px 8px;
        margin: 0 8px;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-select-list {
    position: absolute;
    z-index: 15;
    display: flex;
    flex-direction: column;
    // min-width: 150px;
    width: inherit;
    max-height: 144px;
    border: 1px solid $primary-dark;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    overflow: auto;
    top: 30px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.25s, opacity 0.25s, top 0.25s;

    &__item {
      display: flex;
      align-items: center;
      min-height: 38px;
      padding: 10px 8px;
      margin: 0 8px;
      border-bottom: 1px solid $gray-100;
      cursor: pointer;
      text-align: center;

      &:hover,
      &.active {
        background-color: $gray-75;
      }
    }

    &__item:last-child {
      border-bottom: 0;
    }

    &.active {
      top: 40px;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s, top 0.25s;
    }
  }
}
