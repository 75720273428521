// gray scale
$black: #000000;
$gray-700: #757575;
$gray-600: #131313;
$gray-550: #111214;
$gray-500: #0e0e58;
$gray-400: #27282e;
$gray-300: #3e3f44;
$gray-250: #75757599;
$gray-200: #828292;
$gray-180: #757575;
$gray-175: #bcbfd0;
$gray-160: #B4B9C1;
$gray-150: #b4b4b4;
$gray-125: #bfc3ca;
$gray-100: #d9dae1;
$gray-85: #c2cfe0;
$gray-80: #e5e5e5;
$gray-75: #e9ebf5;
$gray-50: #f6f7f8;
$white-100: #F4F5FA ;
$white-50: #f4f4f4;
$white: #ffffff;

// primary
$primary-light: #3758cc;
$primary-dark: #5b78ff;
$primary-hover: #3C51B1;
$primary-pressed: #003659;
$blue-selected-1: #81aef1;
$blue-selected-2: #a4d9ff;
$blue-selected-3: #ebf2ff;
$blue-selected-4: #455cc7;
$blue-selected-5: #0065FF;

// secondary
$secondary-01-light: #e6e9f7;
$secondary-02-light: #9eaae1;
$secondary-03-dark: #374899;
$secondary-04-dark: #1b244c;
$secondary-05-dark: #1B1C31; 
$secondary-06-dark: #222338; 

// information color
$red: #f7404e;
$red-2: #c90000;
$green: #45c777;
$green-2: #008908;
$yellow: #ffbb00;
$yellow-2: #F2BD00;
$azul-tag: #81aef1;
$green-light: #adffd0;
$green-light2: #EBF6EB;
$blue-light: #bdeeff;
$blue-light1: #00ACC1;
$blue-light2: #EDF2FF;
$blue-light3: #EBF2FF;
$blue-light4: #1E88E5;
$yellow-light: #fffcb8;
$yellow-light2: #FEFAEB;
$purple: #9b3fc8;
$purple-light: #f3daff;
$orange: #ff6b00;
$orange-light: #ffe2c8;
$red-light: #ffe0e0;
$red-light2: #FBEBEB;
