@import "./../../../styles/colors";
@import "../../../styles//animation";

.order-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 24px;
  height: 24px;

  .order-button__icon_display {
    display: none; 
  }

  // &--asc {
  //   .order-button__icon-asc {
  //     fill: $primary-dark;
  //   }
  // }

  // &--desc {
  //   .order-button__icon-desc {
  //     fill: $primary-dark;
  //   }
  // }

  &--asc {
    .order-button__icon-asc {
      transition: 0.2s;
    }
    }

  &--desc {
    .order-button__icon-desc {
      transform: rotate(180deg);
      transition: 0.2s;
    }
    
  }
}
