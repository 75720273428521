@import "../../styles/colors";
@import "../../styles/mixins";

.task-header {
  background-color: #ffffff;
  height: 53px;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out 0s;

  &.dark-mode{
    background-color: $secondary-06-dark;
  }

  .task-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 8px;
      padding-top: 8px;
      border-color: black;
      border-width: 10px;

      .task-header-container-box {
        display: flex;
        

        &__logo{
          margin-left: 24px;

          .oficial_mark{
            position: relative;
            bottom: 20px;
          }
        }
  
        &__title {
          margin-left: 24px;
          font-size: 24px;
          color: $primary-light
        }
      }

      .task-header-container-nav {
        display: flex;
        align-items: center;
        margin-right: 33px;

        &__welcome {
          display: flex;
          // font-size: 16px;
          // font-family: "Inter", sans-serif;
          // font-style: normal;
          // line-height: 26px;

          :hover path {
            fill: $primary-light;
          }

          p {
            color: $gray-200;
          }

          span {
            color: $primary-light;
            font-weight: bold;  
          }
        }

        &__logoff {
          margin-left: 33px;
          cursor: pointer;

          :hover path {
            fill: $primary-light;
          }
        }

        &__bell {
          margin-left: 33px;
          cursor: pointer;

          :hover path {
            fill: $primary-light;
          }
        }

        &__setting {
          margin-left: 33px;
          cursor: pointer;
          :hover path {
            stroke: $primary-light;
          }
        }

        &__arrow {
          margin-left: 8px;
          cursor: pointer;
          :hover path {
            fill: $primary-light;
          }
        }

        &__avatar {
          margin-left: 8px;
        }

        .dark-mode{
          path {
            stroke: $white;
          }
        }
      }
  }

  &__border {
    position: absolute;
    background-color: $gray-100;
    // width: 75px;
    width: calc(100% - 55px);
    right: 0;
  }
}
