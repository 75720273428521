@import "./../../../../styles/colors";

.filters-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding-top: 5px;

  &__date-range-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;

    .field-container {
      width: 40%;

      .dateRangeInput.MuiFormControl-root.MuiTextField-root{
        width: 100%;
      }
    }

    .dot-dates {
      position: relative;
      top: 12px;
    }
  }

  .advanced-filters-input {
    
    input {
      margin-top: 4px;
      width: 100%;
      height: 42px;
      border-radius: 4px;
      border: 2px solid $gray-125;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      color: $blue-selected-4; 
      padding: 14px;
    }
    
    input::placeholder {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-125;
    }
  }

  .advanced-filters-options {
    justify-content: 'start';
    align-items: 'center'; 
    display: 'flex'; 
    margin-top: '2px'; 
    margin-left: '8px';
    flex-wrap: 'wrap';
  }

  .filters-dashboard__controls {
    display: flex;
    align-items: flex-start;
    padding: 0px;

    border-bottom: 1px solid #B4B9C1;

    &-principal, &-others{
      display: flex;
      padding: 12px 10px;
      cursor: pointer;

      &.active {
        border-bottom: 1px solid #455CC7;
      }
    }
  }

  .filters-dashboard {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    height: 0px;
    overflow-y: auto;
    padding-right: 4px;
    padding-bottom: 4px;
    // margin-top: 16px;

    .filters-dashboard-inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 8px;

      &__filter {
        display: flex;
        align-items: center;
        gap: 11px;

        > svg {
          height: 20px;
          width: 20px;
          margin-right: 4px;
          fill: $primary-dark;
        }

        .typography-h6 {
          margin-right: auto;
        }

        > input {
          cursor: pointer;
          width: 12px;
          height: 18px;
          border-radius: 5px;
        }
        
        .inputs__filter-checkbox {
         position: relative;
	       cursor: pointer;
        }
        .inputs__filter-checkbox:before {
          content: "";
          display: block;
          position: absolute;
          width: 14px;
          height: 14px;
          top: 0;
          left: 0;
          background-color:$white;
          border: 1px solid black;
          border-radius: 5px;
        }
        .inputs__filter-checkbox:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 18px;
          height: 18px;
          top: 0;
          left: 0;
          background-color:$blue-selected-4;
          border: none;
        }
        .inputs__filter-checkbox:checked:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: 1px solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 1px;
          left: 6px;
        }
        
      }
    }
  
    &__divider {
      border-top: 1px solid $gray-125;

      &:last-child{
        margin-top: auto;
      }
    }
  
    .filters-dashboard-agents {
      display: flex;
      gap: 16px;
    }

    .filters-dashboard-container-tag {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .select-search{
        height: auto;
        min-height: 40px;
      }
    }

    &__buttons{
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding: 24px 0 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      .clean-icon{
        path{
          fill: $red-2;
        }
      }

      .disabled-icon{
        path{
          fill: $gray-125;
        }
      }

      .buttons-aplyfilters{
        padding: 8px 36.5px;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  &.dark-mode{
    .typography-h5.field-container__title{
      color: $white;
    }

    .dateRangeInput.MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root{
      border: 1px solid $white;
      background-color: rgba(255, 255, 255, 0.31);

      input {
        color: $white;
      }

      svg path{
        fill: $white;
      }
    }

    .select-searchTag-btn{
      background-color: rgba(255, 255, 255, 0.31);
      border: 1px solid #BFC3CA;
    }

    .select-searchTag-btn__input:disabled{
      background-color: transparent;
    }

    .MuiInputBase-root.MuiOutlinedInput-root{
      background-color: rgba(255, 255, 255, 0.31);
      border: 1px solid #BFC3CA;
    }

    .filters-dashboard-agents{
      svg path {
        fill: $white;
      }


      .btn-add-elements {
        svg path {
          fill: none;
          stroke: $white;
        }

      }
    }

    .filters-dashboard__buttons{
      border-top: 1px solid $white;
    }
  }
}


@media screen and (max-width: 700px) {
  .filters-dashboard{
    .field-container {
      width: 100%;
    }
  }

}
