@import "./../../../styles/colors";

.channel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  // background-color: $primary-dark;

  > svg {
    width: 24px;
    height: 24px;
    //fill: $white;
  }

  &--media {
    &-suporte {
      background-color: $gray-85;
    }
  }

  &--interactive {
    cursor: pointer;
  }

  // &--secondary-03-dark {
  //   // background-color: $secondary-03-dark;
  // }

  // &--green {
  //   // background-color: $green;
  // }

  // &--yellow {
  //   // background-color: $yellow;
  // }

  // &--primary-dark {
  //   background-color: $primary-dark;
  // }

  // &--orange {
  //   // background-color: $orange;
  // }

  // &--blue-selected-1 {
  //   // background-color: $blue-selected-1;
  // }

  // &--primary-light {
  //   // background-color: $primary-light;
  // }

  &--secondary-04-dark {
    background-color: $secondary-04-dark;
  }

  &--disabled {
    background-color: $gray-175;
    pointer-events: none;
  }
}
