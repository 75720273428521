@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.case-details {
  background-color: #ffffff;
  padding: 16px;
  // padding: 0 20px 20px 0;
  height: 100%;
  width: 100%;

  &.details-modify {
    border-top: 1px solid #d9dae1;
  }

  .case-details-header {
    display: flex;
    align-items: center;
    gap: 16px;
    //justify-content: space-between;
    padding: 0 0 12px 0;
    border-bottom: 1px solid $gray-75;
    background-color: #ffffff;
    position: sticky;
    z-index: 20;
    top: 0;

    &__title {
      display: flex;
      align-items: center;
      gap: 16px;
      // width: calc(50% - 12px);

      .case-details-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;

        .title-ticket {
          display: flex;
          align-items: center;
          // padding: 12px 16px;

          // width: 120px;
          // height: 40px;

          // background: rgba(255, 255, 255, 0.31);

          // border: 1px solid rgba(0, 0, 0, 0.1);
          // border-radius: 5px;
        }

        .typography-h3 {
          margin-top: 2px;
        }
      }

      > svg {
        cursor: pointer;
        fill: $gray-175;
        flex-shrink: 0;
      }
    }

    &__options {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 16px;
      // width: calc(50% - 12px);
      width: 100%;

      .case-users {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .case-priority__header {
        width: 166px;
        // height: 40px;

        .dropdown-priority-btn {
          gap: 12px;

          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;

          .dropdown-priority-btn-container {
            // padding: 8px 16px;

            &__content {
              gap: 12px;
            }
          }
        }
      }

      .case-status__header {
        min-width: 178px;
        height: 40px;

        .dropdown-status-btn {
          padding: 8px 16px;
          gap: 12px;
        }
      }

      .case-escalate__header {
        min-width: 202px;
        height: 40px;

        .dropdown-escalate-btn {
          height: inherit;
          padding: 10px 16px;

          background: rgba(255, 255, 255, 0.31);
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          gap: 12px;

          > svg:first-child {
            width: 20px;
            height: 20px;
          }

          > h4 {
            line-height: 112.5%;
          }

          > svg:last-child {
            width: 24px;
            height: 24px;
          }
        }
      }

      .delete {
        margin-left: auto;
      }
    }

    .case-details-header__profile {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: auto;

      > svg {
        fill: $gray-200;
      }

      .image-profile {
        width: 30px;
        height: 30px;
      }

      > h6 {
        margin-left: 6px;
      }

      .case-details-header-profile-info {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .case-details-main {
    height: calc(100% - 51px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "main-information tabs";
    gap: 16px;
    padding: 16px 0 0 0;
    // grid-auto-rows: 58px 58px 58px 24px 60px auto;
    // grid-auto-rows: max-content max-content min-content max-content auto;

    .popover-details-channel {
      .popover-menu-list__item {
        color: $primary-dark;
        border-bottom: 1px solid $gray-75;
      }
    }

    .case-details-information-main {
      grid-area: main-information;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-right: 8px;
      max-height: 100%;
      overflow: auto;
    }

    .case-details-field {
      display: grid;
      grid-template-columns: repeat(3, minmax(150px, 500px));
      gap: 16px;
      width: 100%;

      .grid-span-2 {
        grid-column: span 2;
      }

      .dropdown-priority,
      .dropdown-status,
      .dropdown-escalate,
      .dropdown-channel {
        width: 100%;
      }

      .dropdown-priority-btn,
      .dropdown-status-btn,
      .dropdown-escalate-btn,
      .dropdown-channel-btn {
        height: 40px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }

      .dropdown-status-btn {
        > svg:last-child {
          width: 24px;
          height: 24px;
          margin-left: auto;
        }
      }

      .dropdown-escalate-btn {
        justify-content: flex-start;
        gap: 8px;

        > svg:last-child {
          width: 24px;
          height: 24px;
          margin-left: auto;
        }
      }

      .dropdown-channel-btn {
        .dropdown-channel-btn-container {
          padding: 6px 8px;

          .dropdown-channel-btn-container__icon > svg {
            width: 24px;
            height: 24px;
          }
        }

        &.active {
          border: 1px solid #455cc7;
        }
      }
    }

    .case-details-client {
      // grid-area: client;
      // display: flex;
      // gap: 16px;

      .field-container {
        width: 100%;
      }

      .case-client {
        > h6 {
          max-width: 240px;
          @include text-overflow;
        }

        .select-search-btn {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      .case-company {
        h5 {
          // max-width: 125px;
          width: 100%;
          @include text-overflow;
          height: 38px;
          // height: 24px;
        }
      }

      .case-priority {
        &__list {
          > button {
            height: 40px;
          }

          .dropdown-priority-btn-container {
            padding-top: 0;
            padding-bottom: 0;
          }

          .dropdown-priority-list {
            &.active {
              top: 33px;
            }

            > button:first-child {
              padding-top: 8px;
            }
          }
        }
      }
    }

    .case-details-motive {
      // grid-area: motive;
      // display: flex;
      // gap: 16px;
    }

    .case-details-subject {
      grid-area: subject;
    }

    .textarea-subject {
      .typography-h5 {
        color: $gray-600;
      }

      textarea {
        border: none;
        padding: 8px 16px;
        overflow-y: hidden;
        height: 38px;
      }

      .typography-h5 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .textarea-description {
      textarea {
        border: none;
        padding: 0;
        border-radius: 5px;
      }
    }

    &__date {
      display: flex;
      gap: 8px;

      .time-rangeInput {
        // width: 216px;
        height: 40px;

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 8px 8px 8px 16px;
        }
      }
    }

    &__select {
      .select-btn {
        border: 1px solid rgba(0, 0, 0, 0.1);

        &.active {
          border: 1px solid #455cc7;
          border-bottom-color: #e8e8e9;
        }
      }
    }

    .case-priority {
      .dropdown-priority {
        width: 100%;
      }

      .dropdown-priority-btn {
        height: 40px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .case-channel {
      // min-width: max-content;
      // min-width: 208px;
      .case-channel-origin {
        display: flex;
        align-items: center;
        gap: 8px;
        //height: 40px;
      }

      .dropdown-channel {
        width: 100%;

        .dropdown-channel-btn {
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.1);

          .dropdown-channel-btn-container {
            padding: 6px 8px;

            .dropdown-channel-btn-container__icon > svg {
              width: 24px;
              height: 24px;
            }
          }

          &.active {
            border: 1px solid #455cc7;
          }
        }
      }
    }

    .case-details-interaction-channels {
      display: flex;
      flex-direction: row;
      grid-area: interaction-channels;
      align-items: center;
      gap: 8px;

      .typography-h4 {
        font-weight: 400;
      }

      &__content {
        display: flex;
        gap: 8px;

        .btn-channel-interaction {
          cursor: pointer;
        }
      }
    }

    .case-details-status {
      // display: flex;
      // gap: 16px;

      .case-priority,
      .case-status,
      .case-escalate {
        width: 100%;

        .dropdown-priority,
        .dropdown-status,
        .dropdown-escalate {
          width: 100%;
        }

        .dropdown-priority-btn,
        .dropdown-status-btn,
        .dropdown-escalate-btn {
          height: 40px;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .dropdown-status-btn {
          > svg:last-child {
            width: 24px;
            height: 24px;
            margin-left: auto;
          }
        }

        .dropdown-escalate-btn {
          justify-content: flex-start;
          gap: 8px;

          > svg:last-child {
            width: 24px;
            height: 24px;
            margin-left: auto;
          }
        }
      }
    }

    .field-container-case-variable {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      &-control {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px;
        background: $white;
        // background: rgba(230, 230, 230, 0.2);
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;

        svg {
          transform: rotate(0deg);

          path {
            fill: $gray-180;
          }
        }
      }

      &-fields {
        width: 100%;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(100px, 31%));
        grid-row-gap: 16px;
        row-gap: 16px;
        gap: 16px;

        .custom-field-container {
          .field-container__header {
            .field-container__icon-container {
              > svg {
                height: 17px;
                width: 17px;
              }
            }
          }
        }

        .field-below-textarea {
          grid-column: 1 / -1;
          width: 100%;
        }

        .textarea-container {
          grid-column: 1 / -1;
          width: 100%;
        }
      }

      &.active-variable {
        .field-container-case-variable-control {
          background: #ebf2ff;
          border-radius: 4px 4px 0px 0px;

          svg {
            transform: rotate(-180deg);

            path {
              fill: $blue-selected-4;
            }
          }
        }

        .field-container-case-variable-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 16px 8px;
          gap: 12px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-width: 0px 1px 1px 1px;
          border-radius: 0px 0px 4px 4px;

          .btn.btn-primary {
            align-self: flex-start;
          }
        }
      }
    }

    .case-details-description {
      grid-area: description;
      height: 100%;

      .field-container-edit {
        // height: 50%;

        .field-container-edit-title {
          > h5 {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: $gray-600;
          }
        }

        .field-container-edit-content {
          padding: 12px 16px;
          flex-grow: 1;
          min-height: 100px;
        }
      }
    }

    .case-details-tabs {
      grid-area: tabs;
      display: flex;
      flex-direction: column;
      gap: 22px;

      .case-details-task {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;

        &__title {
          display: flex;
          align-items: center;
          gap: 10px;

          .case-details-task__number-title {
            padding: 0 2px;
            background-color: $secondary-02-light;
            border-radius: 2px;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
          }
        }

        .case-details-task-list {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-template-rows: repeat(auto-fill, 200px);
          gap: 12px;
          flex-wrap: wrap;
          height: 0;
          flex-grow: 1;
          overflow-y: auto;
          padding-right: 12px;

          &__empty-state {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            height: 100%;
          }
        }
      }
    }

    .case-priority__list {
      display: flex;
      align-items: center;
      min-height: 40px;
    }

    .case-company__name {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 2px;
    }
  }
}

@media screen and (max-width: 700px) {
  .case-details {
    .case-details-header {
      // flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      padding: 0;
      border: none;

      &__title {
        width: 100%;

        .case-details-title {
          gap: 10px;
          .title-ticket {
            padding: 0;
            border: none;
          }
        }

        h2,
        h4 {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: $blue-selected-4;
        }
      }

      &__options {
        // display: none;
        justify-content: space-between;

        .delete {
          margin-left: 0;
        }
      }
    }

    .case-details-main {
      height: 95%;
      gap: 12px;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      grid-template-areas: "tabs";
      // grid-template-rows: 66px 66px 24px 383px;
      // grid-template-areas:
      // "tabs"
      // "client"
      // "deadline "
      // "interaction-channels";

      // &.case-details-main-interaction {
      //   grid-template-areas:
      //     "client"
      //     "deadline "
      //     "tabs";
      // }

      .case-details-client {
        display: none;
        .select-search {
          //width: 182px !important;
          width: 100% !important;
        }

        .case-company {
          min-width: 100px;

          h6 {
            max-width: 100px;
          }
        }
      }

      .case-details-motive {
        display: none;
      }

      .case-details-interaction-channels {
        display: none;
        flex-direction: row;
        gap: 8px;
      }

      .case-details-subject {
        display: none;
      }

      .case-details-description {
        display: none;
      }

      .case-details-deadline {
        display: none;

        .field-container {
          width: 100%;
        }
        .select {
          width: 100% !important;
        }
      }

      .case-details-tab-information {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 16px;

        &__header {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .agents-delete {
            display: flex;
            justify-content: space-between;
          }
        }

        &__client {
          // display: flex;
          // justify-content: space-between;
          // gap: 16px;

          .field-container {
            width: 100%;
          }
        }

        &__motive {
          // display: flex;
          // justify-content: space-between;
          // gap: 16px;

          .field-container {
            width: 100%;
          }
        }

        &__deadline {
          // display: flex;
          // justify-content: space-between;
          // gap: 16px;

          .field-container {
            width: 100%;
          }

          .time-rangeInput {
            height: auto;

            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
              padding: 8px 16px;
            }
            .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
              padding: 8px 4px 8px 8px;
            }
          }

          .case-priority {
            width: 100%;

            .dropdown-priority {
              width: 100%;
            }

            .dropdown-priority-btn {
              height: 40px;
              border-radius: 5px;
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
          }

          .case-channel-origin {
            display: flex;
            align-items: center;
            gap: 8px;
            //height: 40px;
          }

          .dropdown-channel {
            width: 100%;

            .dropdown-channel-btn {
              border-radius: 5px;
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }

        &__options {
          // display: flex;
          // gap: 16px;
          // width: 100%;

          .case-priority__header {
            width: 100%;

            .dropdown-priority-btn {
              width: 100%;
              gap: 12px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 5px;

              .dropdown-priority-btn-container {
                padding: 8px 16px;
              }
            }

            .dropdown-priority-list {
              width: 100%;
            }
          }

          .case-status__header {
            width: 100%;
          }

          .case-escalate__header {
            width: 100%;
            margin-left: auto;

            .dropdown-escalate-btn {
              width: 100%;
              height: 40px;
              justify-content: initial;
              padding: 10px 16px;
              background: rgba(255, 255, 255, 0.31);
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 5px;
              gap: 12px;

              > svg:first-child {
                width: 20px;
                height: 20px;
              }

              > h4 {
                line-height: 112.5%;
                max-width: calc(100% - 8px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              > svg:last-child {
                width: 24px;
                height: 24px;
              }
            }

            .dropdown-escalate-list__item {
              > h4 {
                white-space: nowrap;
              }
            }
          }

          .case-priority,
          .case-status,
          .case-escalate {
            width: 100%;

            .dropdown-priority,
            .dropdown-status,
            .dropdown-escalate {
              width: 100%;
            }

            .dropdown-priority-btn,
            .dropdown-status-btn,
            .dropdown-escalate-btn {
              height: 40px;
              border-radius: 5px;
              border: 1px solid rgba(0, 0, 0, 0.1);
            }

            .dropdown-status-btn {
              > svg:last-child {
                width: 24px;
                height: 24px;
                margin-left: auto;
              }
            }

            .dropdown-escalate-btn {
              justify-content: flex-start;
              gap: 8px;

              > svg:last-child {
                width: 24px;
                height: 24px;
                margin-left: auto;
              }
            }
          }
        }

        &-channels {
          width: 100%;
          flex-direction: row;
          gap: 8px;

          .typography-h4 {
            font-weight: 400;
          }
        }

        .field-container-case-variable {
          &-fields {
            .field-container {
              width: 100%;
            }
          }
        }

        &__description {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .field-container-edit-title > h5 {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #131313;
          }

          .field-container-edit-content {
            padding: 0;
          }
        }
      }

      .case-details-tabs {
        // padding-top: 30px;
        .case-details-task {
          .case-details-task-list {
            grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
            padding-right: 4px;
          }
        }
      }
    }
  }
}
