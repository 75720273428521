@import "./../../../styles/colors";

.tag-chips {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 4px 8px;
  background-color: $white-50;
  border: 1px solid $gray-100;
  border-radius: 24px;
  white-space: nowrap;
  transition: all 0.25s;

  &.selected {
    background-color: $blue-selected-4;
    border-color: transparent;

    .typography-h5 {
      color: $white;
    }
  }

  &:hover {
    &.unselected {
      background-color: $gray-75;
      border-color: transparent;
    }
  }

  &:disabled {
    background-color: $gray-75;
    border-color: $gray-175;
    pointer-events: none;

    .typography-h5 {
      color: $gray-175;
    }
  }
}
