@import "./../../../styles/colors";

.field-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  &:last-child{
    padding-bottom: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon-container {
    width: 24px;
    height: 24px;

    > svg {
      width: inherit;
      height: inherit;
      fill: $gray-200;
    }
  }

  .symbol-warning {
    color: $red;
  }

  &--horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;

    .field-container {
      &__title {
        margin: 0 8px 0 0;
      }
    }
  }
}

// @media screen and (max-width: 700px) {
//   .field-container {
//     width: 31%;
//   }
// }
