@import "../../styles/colors";

.contact-import {
    background-color: #ffffff;
    padding: 0px 15px;
    height: 100%;
    width: 100%;

    &__header {
        margin-top: 25px;
    }

    .success-message-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 75%;
        width: 100%;
        gap: 15px;

        &__icon {
            display: flex;
            background-color: #EBF6EB;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            border-radius: 60px;
        }
    }

    &__file-progess {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 75%;
        width: 100%;
        gap: 50px;

        .file-upload-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            .attachment {
                display: flex;
                width: 600px;
                height: 70px;
                padding: 8px 0px 8px 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                border: 1px dashed $gray-80;
                background: #fefefe;

                &.red-border {
                    border: 1px dashed $red-2;
                }

                .select-file-button {
                    color: $blue-selected-4;
                    font-feature-settings:
                        "clig" off,
                        "liga" off;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    text-decoration-line: underline;
                }

                > input {
                    visibility: hidden;
                    height: 0px;
                    width: 0px;
                }
            }

            .container-progress-bar {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 5px;
                width: 100%;
                // width: 600px;

                &__content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px 0px;
                    grid-gap: 8px;
                    gap: 8px;
                    width: 100%;
                    height: 48px;

                    > div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 0px;
                        grid-gap: 8px;
                        gap: 8px;
                        width: 100%;
                        height: 28px;
                    }

                    &__header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding: 0px;
                        grid-gap: 215px;
                        gap: 215px;
                        width: 100%;
                        height: 16px;

                        .file-information {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 0px;
                            grid-gap: 16px;
                            gap: 16px;
                            width: 300px;
                            height: 16px;

                            &__name-archive {
                                &__text {
                                }
                            }
                        }

                        .file-download {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            cursor: pointer;

                            svg {
                                width: 16px;
                                height: 16px;

                                path {
                                    fill: $blue-selected-4;
                                }
                            }
                        }
                    }

                    .remove-file-icon {
                        cursor: pointer;

                        path {
                            fill: $gray-180;
                        }
                    }
                }

                .progress {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    width: 100%;
                    height: 4px;
                    background: #edf2ff;
                    border-radius: 100px;
                }
            }

            .import-file-button {
                &:disabled {
                    background: #bfc3ca;
                    border: #bfc3ca;
                }
            }
        }

        .template-download-container {
            &__text {
                text-align: center;
            }

            .template-file {
                display: flex;
                width: 380px;
                padding: 8px 16px;
                align-items: center;
                justify-content: space-between;
                align-items: center;
                margin-top: 15px;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                background: #fff;

                > div {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                .template-download {
                    cursor: pointer;
                }
            }
        }
    }
}
