@import "./../../../styles/colors";

.dropdown-task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16.5px 14.5px;
  border: 1px solid $gray-85;
  border-radius: 7.5px;
  font-weight: bold;
  color: $gray-500;

  &.active {
    svg {
      transform: rotate(0);
    }
  }
  
  svg {
    transform: rotate(180deg);
    fill: $gray-300;
  }

}
.task-container {
  margin-top: 30px;

  &__title {
    display: flex;
    gap: 17px;
  }

  &__description {
    margin: 19.5px 0 16px 41px;
    font-size: 10px;
    text-align: justify;
  }

  &__information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;

    &__tags {
      display: flex;
      gap: 4px;

      .task-tag {
        padding: 4px 14px;
        border-radius: 18px;

        &.task-tag--red {
          background-color: rgba(175, 20, 76, 0.2);
          color: #AF144C;
        }

        &.task-tag--blue {
          background-color: rgba(47, 128, 237, 0.2);
          color: #2F80ED;
        }
      }
    }

    &__status {
      display: flex;
      gap: 24px;

      .btn-task-status {
        display: flex;
        align-items: center;
        height: 26px;
        gap: 4px;
        padding: 4px 8px;
        background-color: rgba(174, 255, 197, 0.4);
        border: 1px solid $green;
        border-radius: 5px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: $green;
        cursor: pointer;

        svg {
          transform: rotate(180deg);
          fill: $green;
          width: 16px;
          height: 16px;
        }
      }

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 23px;
    margin-bottom: 30px;
  }

  &__communication-channel {
    display: flex;
    flex-direction: column;
    gap: 15.5px;

    &__list {
      display: flex;
      gap: 8px;
      margin-left: 41px;

      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}