@import "./../../../styles/colors";

.popover-columns-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 6px;

  &__item {
    padding: 4px 0;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    h5{
      display: flex;
      gap: 5px;
    }
  }

  &__content{
    width: 250px;
    height: 198px;
    padding: 8px 4px;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.filters-columns{
  gap: 17px;
  padding: 16px;

  .columns-checkbox{
    display: flex;
    gap: 10px;

    &.noneDisplay{
      display: none;
    }

    .popover-checkbox{
      position: relative;
      cursor: pointer;
      width: 12px;
      border-radius: 5px;

      &:before{
        content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: -3px;
          left: -2px;
          background-color:$white;
          border: 1px solid black;
          border-radius: 5px;
      }

      &:checked:before{
        content: "";
          display: block;
          position: absolute;
          width: 18px;
          height: 18px;
          top: -3px;
          left: -2px;
          background-color:$blue-selected-4;
          border: none;
      }

      &:checked:after{
        content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: 1px solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: -1px;
          left: 4px;
      }
    }
  }

  .title-fieldsAdditional{
    display: flex;
    text-transform: uppercase;
    padding: 4px;
    background-color: #ebf2ff;
    font-weight: 500;
    font-size: 12px;
    line-height: 112.5%;
    letter-spacing: .005em;
    color: $gray-180;
  }
}

.input-popover{
  min-width: 250px;
}

.popover-icon{
  width: 16px;
  height: 16px;
  path {
    fill: $red;

  }
}


