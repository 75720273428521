@import "./../../../styles/colors";

.sending-comment {
  display: flex;
  align-items: center;
  width: 100%;
  // gap: 8px;
  


  &__btn-audio.btn{
    background: rgba(255, 255, 255, 0.2);
  }

  &__mic {
    cursor: pointer;

    path{
      stroke: $gray-180;
  }
  }

  &__input {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 16px;
    width: 100%;
    // padding: 18px 16px 18px 22px;
    padding: 12px 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px 0 0 4px;
    max-height: 50px;

  > svg {
    width: 24px;
    height: 24px;
  }

    .comment-icon__menu{
      cursor: pointer;

      path {
        fill: $gray-180;

      }
    }

    .loading {
      margin-top: -10px;
    }

    > input {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      height: 25px;
      border: unset;
      outline: unset;

      &::placeholder {
        color: $gray-175;
        font-size: 14px;
        line-height: 22px;
        font-family: "Inter", sans-serif;
      }
    }
  }

  .popover-send_comment{
    display: flex;
    flex-direction: column;
    padding: 16px;
    

    width: 172px;
    height: 136px;

    &__title{
      display: flex;
      padding: 5px 0 5px;
    }

    &__item{
      padding: 8px 0 8px;

      &:hover{
        background-color: $gray-80;
      }
    }

    .attach-file{
      svg{
        stroke: none;

        path{
          fill: $blue-selected-4;
        }
      }

      &__icon{
        > h6 {
          font-weight: 500;
          color: #3C51B1;
        }

      }
    }

  }

  &__btn-send.btn{
    padding: 0;
    border-radius: 0px 4px 4px 0px;
    width: 60px;
    height: 50px;

    >svg{
      path {
        fill: $white;
      }
    }
  }
}

.audio-recorder {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  // padding: 8px 10px;
  // background-color: $gray-50;
  // border: 1px solid $gray-175;
  // border-radius: 50px;
  padding: 8px 16px;
  background: $white;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;

  &.center {
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  > svg {
    fill: $gray-200;
    cursor: pointer;
  }

  .buttons-action {
    display: flex;
    align-self: flex-end;
    gap: 16px;

    &__cancelButton {
      color: $red-2;
    }

    &__sendButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;

      background: $blue-selected-4;
      border-radius: 24px;

      color: $white;

    }

    &__sendButton:disabled{
      cursor: default;
      background: $gray-150;
    }

    &__sendButton h6,
    &__cancelButton h6 {
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
  }

  &_audioComment{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12px;

    .wFull {
      width: 100%;
    }

    &__recordBar{
      width: 325px;
      height: 6px;

      background: rgba(0, 0, 0, 0.1);
      border-radius: 18px;
    }

    &__timeProgress{
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: $gray-180;
    }
  }

  .audio-recorder-send {
    display: flex;
    align-items: center;
    gap: 8px;

    > button {
      cursor: pointer;
    }

    .audio-recorder-send-time {
      width: 30px;
    }
  }
}
