@import "./../../../../styles/colors";

.typography-h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;

  // WEIGHTS
  &--bold, &--700 {
    font-weight: bold;
  }

  // COLORS
  &--color-white {
    color: $white;
  }

  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-500 {
    color: $gray-500;
  }

  &--color-gray-600 {
    color: $gray-600;
  }

  &--color-blue-selected-4{
    color: $blue-selected-4
  }

  &--color-blue-light3{
    color: $blue-light3
  }
}
