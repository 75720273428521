@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.client-list-select {
  position: relative;
  display: flex;
  transition: all 0.25s;

  &__input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 40px;
    padding: 8px;
    border: 1px solid $gray-200;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.25s;
  }

  &__current-option {
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: center;
    height: 40px;
    padding: 8px;
    border: 1px solid $gray-200;
    border-radius: 4px;
    cursor: pointer;

    .image-profile-client-list {
      width: 30px;
      height: 30px;
    }

    .current-option-info {
      flex-grow: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      text-align: left;

      .typography-h6,
      .typography-paragraph {
        height: 15px;
        @include text-overflow;
      }
    }

    .current-option-arrow {
      margin-left: auto;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 10;
    top: 44px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 8px;
    border: 1px solid $gray-200;
    border-radius: 4px;
    background-color: $white;

    visibility: hidden;
    opacity: 0;
    transition: all 0.25s;
    transform: translateY(-15px);

    .client-list-item {
      display: flex;
      align-items: center;
      gap: 16px;
      height: 44px;
      padding: 4px 8px;
      border-radius: 4px;
      transition: all 0.25s;
      cursor: pointer;
      text-align: left;

      &__info {
        flex-grow: 1;
        width: 0;

        .typography-h6,
        .typography-paragraph {
          @include text-overflow;
        }
      }

      &:hover {
        background-color: $gray-75;
      }
    }
  }

  &--active {
    .client-list-select__input,
    .client-list-select__current-option {
      border-color: $primary-dark;
    }

    .client-list-select__dropdown {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      border-color: $primary-dark;
    }

    .current-option-arrow {
      transform: scaleY(-1);
    }
  }

  &--error {
    .client-list-select__input {
      border-color: $red;
    }
  }
}
