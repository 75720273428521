@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.select-color {
    position: relative;
    width: 100%;

    .select-color-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: inherit;
        height: 38px;
        padding: 12px 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: #ffffff;
        text-align: left;
        white-space: nowrap;
        transition: all 0.25s;
        cursor: pointer;

        .option-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .color-option {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .label {
                flex-grow: 1;
                width: 0px;
                @include text-overflow;
            }
        }

        &.active {
            border: 1px solid $blue-selected-4;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: #e8e8e9;

            svg {
                transform: rotate(180deg);
            }
        }

        &--error {
            border-color: $red;
        }

        &--disabled {
            border-color: $gray-75;
            background-color: #d6d6d6;
            cursor: default;
        }
    }

    .select-color-list {
        position: absolute;
        z-index: 15;
        display: flex;
        flex-direction: column;
        width: inherit;
        max-height: 160px;
        border: 1px solid $gray-200;
        border-top: 0;
        border-radius: 0 0 5px 5px;
        background-color: #fff;
        overflow: auto;
        top: 30px;
        visibility: hidden;
        opacity: 0;
        transition:
            visibility 0s 0.25s,
            opacity 0.25s,
            top 0.25s;

        &__item {
            display: flex;
            align-items: center;
            min-height: min-content;
            padding: 12px;
            cursor: pointer;

            &:hover,
            &.active {
                background-color: $blue-selected-3;
            }

            .color-option {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .label {
                // flex-grow: 1;
                @include text-overflow;
            }
        }

        &.active {
            border: 1px solid $blue-selected-4;
            border-top: 0;
            top: 40px;
            visibility: visible;
            opacity: 1;
            transition:
                opacity 0.25s,
                top 0.25s;
        }

        &--error {
            border-color: $red;
        }
    }

    &--upwards {
        .select-color-btn.active {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top-color: #e8e8e9;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-color: $gray-100;

            svg {
                transform: rotate(180deg);
            }
        }

        .select-color-list {
            bottom: 28px;
            top: unset;
            flex-direction: column-reverse;
            border-top: 1px solid $gray-100;
            border-bottom: 0;
            border-radius: 5px 5px 0px 0px;

            &.active {
                bottom: 28px;
                top: unset;
            }
        }
    }
}
