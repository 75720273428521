@import "./../../../styles/colors";

.card-dashboard {
    display: flex;
    // width: 300px;
    // height: 120px;
    background-color: $white;
    border-radius: 8px;
    justify-content: space-between;
    padding: 16px;
    // margin: 0px 16px 0px 0px;

    &.icon-padding {
        padding: 16px 0 16px 16px;
    }

    &.customize-card-dashboard {
        flex: 1 1 calc(25% - 16px);
        box-sizing: border-box;
        min-width: 200px;
    }

    &.customize-card-dashboard:nth-last-child(2),
    &.customize-card-dashboard:nth-last-child(3) {
        flex: 1 1 calc(30% - 16px);
    }

    &-emptySpace {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        h6 {
            width: 60%;
        }

        &.column {
            height: 100%;
            gap: 16px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h5 {
                width: 80%;
            }
        }

        &.line {
            height: 80%;

            h5 {
                width: 60%;
                align-self: center;
            }
        }
    }

    .card-dashboard__container {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        gap: 12px;

        &-title {
            display: flex;
            // margin: 16px 0px 8px 16px;
            flex-direction: column;

            h6 {
                white-space: nowrap;
            }

            span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: $gray-180;
            }
        }

        &-info {
            display: flex;
            // margin: 0px 16px 0px 16px;
            flex-direction: column;

            h1 {
                font-size: 32px;
                line-height: 40px;
            }

            // .card-dashboard__container-info__text-secondary {

            //     font-size: 32px;
            //     line-height: 40px;
            //     color: $gray-600;
            // }
        }

        &-trend {
            display: flex;
            // margin: 2px 0px 0px 16px;
            align-items: center;
            gap: 4px;

            .card-dashboard__container-trend__text-primary {
                color: $green-2;
            }

            .card-dashboard__container-trend__text-secondary {
                color: $red-2;
            }
        }
    }

    &__view {
        // margin: 16px 17px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        cursor: pointer;

        > svg {
            cursor: pointer;
        }

        &-percentage {
            // margin: -60px 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            bottom: 46px;
            height: 0;
            // font-size: 20px;
            // font-family: 'Inter';
            // font-style: normal;
            // line-height: 28px;
            // font-weight: 500;
            // color: $gray-600;
        }
    }

    &__view-separator {
        // margin: 16px 0px 0px 0px;
        align-items: center;
        justify-content: center;
        position: relative;

        > svg {
            cursor: pointer;
        }
    }

    &__status-subtitle-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;

        &__boxes {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 9px;
            flex-wrap: wrap;
            height: 65px;

            .subtitle-boxe {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                grid-gap: 8px;
                gap: 8px;
            }
        }

        .control-container {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
                cursor: pointer;
                transform: rotate(270deg);

                &.control-container__icon {
                    cursor: default;

                    path {
                        fill: $gray-125;
                    }
                }
            }
        }
    }
}

.card-dashboard__solicited {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    padding: 24px;
    background-color: $white;
    border-radius: 5px;

    // &-title {
    //     display: flex;
    //     margin: 16px 0px 8px 24px;
    //     flex-direction: column;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     color: $gray-180;
    // }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        height: 100%;
        // justify-content: space-between;
        // margin: 32px 16px 0px 24px;

        &__graphic {
            width: 85%;
            height: 100%;
        }

        &__reasons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            width: 100%;
            // width: calc(100% - 250px);
            // margin: 32px;

            &-boxes {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 9px;
                // width: 78%;
                flex-wrap: wrap;
                height: 65px;
            }

            span {
                margin: 8px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 112%;
                color: $gray-180;
            }

            &-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 8px;

                &:hover {
                    cursor: pointer;

                    h5 {
                        text-decoration: underline;
                    }
                }
            }

            .control-subtitle {
                display: flex;
                align-items: center;
                gap: 8px;

                svg {
                    cursor: pointer;
                    transform: rotate(270deg);

                    &.control-subtitle__icon {
                        cursor: default;
                        // transform: rotate(270deg);

                        path {
                            fill: $gray-125;
                        }
                    }
                }
            }
        }
    }
}

.card-dashboard__varied {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: $white;
    border-radius: 5px;
    padding: 20px 24px;
    // margin-top: 16px;
    // margin-right: 16px;
    // height: 256px;

    // #### NEW STYLE ###
    min-width: 329px;
    height: 100%;

    // &-title {
    //     display: flex;
    //     // margin: 12px 24px 32px 24px;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     color: $gray-180;
    // }

    &-view {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        // text-align: center;
        // margin-bottom: 32px;
    }

    &-titleView {
        font-size: 32px;
        line-height: 40px;
        position: relative;
        bottom: 30px;
        height: 0;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__box {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $gray-180;
            text-align: center;
            align-items: center;
        }
    }

    &-subtitle {
        text-align: center;
    }
}

.card-dashboard__resolution {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 5px;
    padding-bottom: 20px;
    // margin-top: 16px;
    // margin-right: 16px;
    // height: 256px;

    // #### NEW STYLE ###
    min-width: 329px;
    height: 100%;

    &-box_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 12px 12px 24px;
    }

    // &-title {
    //     display: flex;
    //     margin: 12px 24px 0px 24px;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     color: $gray-180;
    // }

    .card-btn-options {
        // margin: 12px 12px 0px 0px;

        > svg {
            transform: rotate(270deg);
        }
    }

    &-view {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        height: 100%;
        padding: 0 24px;
    }

    &-viewValue {
        color: $blue-light1;
        font-size: 32px;
        line-height: 40px;
        position: relative;
        bottom: 85px;
        height: 0;
    }

    &-border {
        margin: 0;
        width: 100%;
        background-color: $gray-80;
        opacity: 30%;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__box {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            &-card {
                .darkModeIcon {
                    path {
                        fill: $white;
                    }
                }
            }
        }

        span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $gray-180;
            text-align: center;
            align-items: center;
        }
    }

    &-subtitle {
        text-align: center;
    }
}

.card-dashboard__activity {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: $white;
    border-radius: 8px;
    padding: 24px;
    // height: 271px;
    // margin-right: 16px;

    // &-title {
    //     display: flex;
    //     margin: 16px 0px 0px 24px;
    //     flex-direction: column;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: 24px;
    //     font-size: 16px;
    //     color: $gray-180;
    // }

    &-box {
        display: flex;
        justify-content: center;
        gap: 28px;
        height: 250px;

        // grid-template-columns: repeat(2, 1fr);
        // flex-direction: row;
        // margin-left: 24px;
        // align-items: center;

        &__graphic {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        &__amounts {
            display: flex;
            flex-direction: column;
            gap: 16px;

            // span {
            //     margin-top: 16px;
            //     font-family: 'Inter';
            //     font-style: normal;
            //     font-weight: 400;
            //     font-size: 12px;
            //     line-height: 20px;
            //     color: $gray-180;
            // }
        }
    }

    &-reasons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 12px;

        &__reason {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
            // margin-right: 8px;

            // span {
            //     margin-left: 8px;
            //     font-family: 'Inter';
            //     font-style: normal;
            //     font-weight: 400;
            //     font-size: 12px;
            //     line-height: 13.5px;
            //     color: $gray-180;
            // }
        }
    }
}

.card-dashboard__perfomance {
    display: flex;
    // margin-right: 16px;
    flex-direction: column;
    background-color: $white;
    border-radius: 8px;
    // height: 404px;
    gap: 24px;
    padding: 24px 24px;

    &-header {
        display: flex;
        justify-content: space-between;

        .section-dashboard-columns {
            .btn.btn-primary {
                background-color: transparent;
                border: none;

                .column-filter-dark {
                    line {
                        stroke: $white;
                    }
                }
            }
        }
    }
}

.card-dashboard__campaign {
    display: flex;
    // margin-left: 16px;
    flex-direction: column;
    background-color: $blue-selected-4;
    border-radius: 8px;
    padding: 16px 24px;
    gap: 8px;
    // height: 404px;

    // &-title {
    //     margin: 24px 0px 0px 24px;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 300;
    //     font-size: 12px;
    //     line-height: 16px;
    //     color: $white;
    // }

    // &-subtitle {
    //     margin: 8px 24px;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 24px;
    //     line-height: 32px;
    //     color: $white;
    // }

    // &-message {
    //     margin: 8px 24px;
    //     font-family: 'Inter';
    //     font-style: normal;
    //     font-weight: 300;
    //     font-size: 14px;
    //     line-height: 20px;
    //     color: $white;
    // }

    &-banner {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 100%;
            height: auto;
        }
    }
}

.section-dashboard-list {
    display: flex;
    flex-direction: column;
    max-height: 405px;
    overflow: auto;

    .collaborator-profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        .image-profile {
            width: 32px;
            height: 32px;
        }
    }
}

.dashboard-btn-options {
    > svg {
        transform: rotate(270deg);
        width: 16px;
    }
}
