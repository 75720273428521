@import "./../../../../styles/colors";

.modal-delete-status {
    .modal {
        &__body {
        }

        &__footer {
            justify-content: flex-end;
            padding-top: 1.6rem;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        // padding-top: 10px;

        &__select-status {
            padding-top: 1rem;
        }
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        background: $white;
        border: 1px solid $red-2;

        &:hover {
            background: $white-50;
        }
    }
}

@media screen and (max-width: 700px) {
    .ReactModal__Body--open {
        overflow: hidden;
    }
    .modal-change-sla {
        width: 100%;
        height: 100%;

        &__content {
        }
    }
}
