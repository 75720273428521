@import "../../../styles/colors";

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.checkbox-container input[type="checkbox"] {
    display: none;
}

.checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 2px solid $gray-175;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    transition:
        background-color 0.3s,
        border-color 0.3s;

    &:hover {
        border-color: $blue-selected-4;
    }
}

.checkmark.checked {
    background-color: $blue-selected-4;
    border: 2px solid $blue-selected-4;
}

.check-icon {
    color: #fff;
    font-size: 18px;
}
