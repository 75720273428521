@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.select {
  position: relative;
  width: 100%;

  .select-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: 38px;
    // padding: 12px 16px;
    padding: 12px 8px;
    // border: 1px solid $gray-125;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #ffffff;
    text-align: left;
    white-space: nowrap;
    transition: all 0.25s;
    cursor: pointer;

    .option-content {
      // flex-grow: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .option-labelResume {
        max-width: calc(100% - 24px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.max-width {
          max-width: none;
        }
      }
    }

    .typography-h6 {
      flex-grow: 1;
      width: 0px;
      @include text-overflow;
    }

    &.active {
      border: 1px solid $blue-selected-4;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: #e8e8e9;

      svg {
        transform: rotate(180deg);
      }
    }

    &--error {
      border-color: $red;
    }

    &--disabled {
      border-color: $gray-75;
      background-color: #d6d6d6;
      cursor: default;
    }
  }

  .select-list {
    position: absolute;
    z-index: 15;
    display: flex;
    flex-direction: column;
    // min-width: 150px;
    width: inherit;
    max-height: 160px;
    border: 1px solid $gray-200;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    overflow: auto;
    top: 30px;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s 0.25s,
      opacity 0.25s,
      top 0.25s;

    &__input {
      padding: 8px;
    }

    &__empty {
      padding: 10px;;

      > h5 {
        text-align: center;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      min-height: min-content;
      // padding: 10px 8px;
      padding: 12px;
      // margin: 0 4px;
      // border-bottom: 1px solid $gray-100;
      cursor: pointer;
      text-align: left;

      &:hover,
      &.active {
        background-color: $blue-selected-3;
      }

      h5 {
        max-width: 100%;
        @include text-overflow;
      }
    }

    &.active {
      border: 1px solid $blue-selected-4;
      border-top: 0;
      top: 40px;
      visibility: visible;
      opacity: 1;
      transition:
        opacity 0.25s,
        top 0.25s;
    }

    &--error {
      border-color: $red;
    }
  }

  &--center {
    .select-btn {
      text-align: center;

      > span {
        flex-grow: 1;
        text-align: center;
      }
    }

    .select-list {
      &__item {
        flex-grow: 1;
        justify-content: center;
      }

      &--empty {
        pointer-events: none;
      }
    }
  }

  &--upwards {
    .select-btn {
      &.active {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-color: #e8e8e9;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-color: $gray-100;

        .select-btn__icon {
          transform: rotate(90deg) scaleX(-1);
        }
      }
    }

    .select-list {
      bottom: 28px;
      top: unset;
      flex-direction: column-reverse;
      border-top: 1px solid $gray-100;
      border-bottom: 0;
      border-radius: 5px 5px 0px 0px;

      &.active {
        bottom: 28px;
        top: unset;
      }
    }
  }

  &--small {
    width: 140px;
  }

  &--medium {
    width: 280px;
  }

  &--large {
    width: 560px;
  }
}
