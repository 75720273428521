@import "./../../../styles/colors";

.dropdown-priority {
  position: relative;
  width: 101px;

  &.advanced-filters-case__priority{
    width: 100%;

    .dropdown-priority-btn {
      border: 1px solid $gray-125;
    }
  }

  .dropdown-priority-btn {
    width: inherit;
    height: 38px;
    border-radius: 4px;
    background-color: #ffffff;
    cursor: pointer;
    transition: border-radius 0.25s;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    &.autoWidth {
      width: 100%;
    }

    
    .dropdown-priority-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      padding: 8px;

      > svg {
        width: 20px;
        height: 20px;
      }

      &__icon {
        height: 20px;
        > svg {
          width: 20px;
          height: 20px;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

      .dropdown-priority-btn-container {
        &__icon {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .dropdown-priority-list {
    position: absolute;
    z-index: 15;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: inherit;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    padding: 0 8px 8px 8px;
    overflow: auto;
    top: 15px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.25s, opacity 0.25s, top 0.25s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    &__item {
      display: flex;
      align-items: center;
      min-height: 40px;
      gap: 4px;

      cursor: pointer;
      text-align: center;

      &:hover,
      &.active {
        background-color: $gray-75;
      }
    }

    &__item:last-child {
      border-bottom: 0;
    }

    &.active {
      top: 100%;
      // top: 39px;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.25s, top 0.25s;
    }
  }

  &--error {
    .dropdown-priority-btn {
      border: 1px solid $red;
    }

    .dropdown-priority-list {
      border: 1px solid $red;
      border-top: none;
    }
  }
}
