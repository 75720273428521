@import "./../../../../styles/colors";

.modal-update-contact {
    .modal {
        &__body {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        &__footer {
            justify-content: flex-end;
        }
    }

    &__content {
        // display: flex;
        // flex-direction: column;
        // row-gap: 16px;

        display: flex;
        flex-direction: column;
        grid-row-gap: 16px;
        row-gap: 16px;
        height: 600px;
        max-height: 500px;
        padding: 4px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track-piece {
            width: 0px;
            height: 0px;
        }

        .channels-content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 352px;
            height: 32px;
        }

        .img {
            border-radius: 9999px;
            width: 80px;
            left: 24px;
            top: 80px;
            background: rgba(0, 0, 0, 0.1);

            .icon-user {
                margin-top: 15px;
                margin-left: 15px;
                margin-bottom: 10px;
            }
        }

        .container-full-line {
            display: flex;
            justify-content: space-between;
            gap: 16px;
        }

        .container-full-line-contact {
            display: flex;
            gap: 16px;

            .container-add-minus {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                margin-top: 8px;
                gap: 16px;
                width: 64px;
                height: 24px;

                .remove-phone {
                    cursor: pointer;

                    path {
                        stroke: $red-2;
                    }

                    &.disabled {
                        cursor: not-allowed;

                        path {
                            stroke: #bfc3ca;
                        }
                    }
                }

                .add-phone {
                    cursor: pointer;

                    path {
                        stroke: $blue-selected-4;
                    }
                }
            }
        }

        .divisor {
            width: 952px;
            height: 0px;
            left: 1px;
            top: 272px;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .input-cell {
            width: 254px;
        }
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
