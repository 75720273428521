@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.dropdown-status {
  position: relative;
  //width: 100%;

  &__body {
    > .dropdown-escalate-btn.select {
      height: 48px;
      padding: 12px 16px;
      background: rgba(255, 255, 255, 0.31);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      justify-content: initial;
      gap: 12px;

      > svg:first-child {
        width: 20px;
        height: 20px;
      }

      > svg:last-child {
        width: 24px;
        height: 24px;
        margin-left: auto;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 16px;
    border-top: 1px solid $gray-100;

    > button {
      // flex-grow: 1;
      height: 40px;
      border-radius: 4px;
      font-family: "Inter", sans-serif;
      font-size: 14px;
    }

    > .dropdown-status__footer-cancel {
      // color: $red;
      display: flex;
      background: #ffffff;
      border: 1px solid #c90000;
      border-radius: 5px;

      &:hover {
        background: #f4f4f4;
      }
    }

    &-confirm {
      background-color: $primary-dark;
      color: white;
      font-weight: 600;
    }
  }

  &.dropdown-status--tag {
    .dropdown-status-btn {
      max-width: 120px;
      justify-content: center;
    }
  }

  .dropdown-status-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    width: inherit;
    width: 100%;
    // width: 140px;
    // height: 26px;
    height: 38px;
    padding: 4px 8px;
    // border-radius: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: border-radius 0.25s;
    background: $white;

    &.dropdown-status--border {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }

    &.flash-animation {
      animation: flash 2s infinite;
    }

    @keyframes flash {
      0%,
      100% {
        box-shadow: 0px 0px 0px 4px rgba(224, 235, 255, 1); // Full opacity
      }
      50% {
        box-shadow: 0px 0px 0px 4px rgba(224, 235, 255, 0.3); // 30% opacity
      }
    }

    .dropdown-chip-status {
      max-width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px 4px 6px;
      gap: 6px;

      // width: 106px;
      // height: 24px;

      background: #f4f4f4;
      border-radius: 24px;

      .circle-status {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        &.orange {
          background: $orange;
        }

        &.primary-dark {
          background: $primary-dark;
        }

        &.green {
          background: $green;
        }

        &.yellow {
          background: $yellow;
        }

        &.red {
          background: $red;
        }

        &.gray {
          background: $gray-250;
        }

        &.purple {
          background: $purple;
        }

        &.blue-light {
          background: $blue-light4;
        }

        &.blue {
          background: $blue-selected-4;
        }

        &.orange-f4511e {
          background: #f4511e;
        }
      }

      .label-tag {
        line-height: 112.5%;
        @include text-overflow;
      }

      @media screen and (max-width: 700px) {
        .label-tag__small {
          @include text-overflow;
        }
      }
    }

    &.tag {
      cursor: default;
    }

    > svg {
      width: 18px;
      height: 18px;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }

    &.dropdown-status--error {
      border-color: #f7404e;
    }
  }

  .dropdown-status-list {
    position: absolute;
    z-index: 15;
    display: flex;
    flex-direction: column;
    gap: 1px;
    // min-width: 150px;
    width: 100%;

    &__empty {
      display: flex;
      flex-direction: column;
      padding: 10px;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 5px;
      margin-top: 10px;
    }

    // border: 1px solid $primary-dark;
    // border-top: 0;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    top: 30px;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s 0.25s,
      opacity 0.25s,
      top 0.25s;

    &--search-status {
      max-height: 250px;
      padding: 10px;
      overflow: auto;
    }

    &__item {
      display: flex;
      align-items: center;
      min-height: 40px;
      // height: 26px;
      padding: 4px 8px;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;
      gap: 4px;

      &:hover {
        background-color: $gray-75;
      }

      .circle-status {
        width: 8px;
        height: 8px;

        border-radius: 50%;

        &.orange {
          background: $orange;
        }

        &.primary-dark {
          background: $primary-dark;
        }

        &.green {
          background: $green;
        }

        &.yellow {
          background: $yellow;
        }

        &.red {
          background: $red;
        }

        &.gray {
          background: $gray-250;
        }

        &.purple {
          background: $purple;
        }

        &.blue-light {
          background: $blue-light4;
        }

        &.blue {
          background: $blue-selected-4;
        }

        &.orange-f4511e {
          background: #f4511e;
        }
      }
    }

    &__item--green {
      border: 1px solid $green;
      background-color: $green-light;
    }

    &__item--primary-dark {
      border: 1px solid $primary-dark;
      background-color: $blue-light;
    }

    &__item--yellow {
      border: 1px solid $yellow;
      background-color: $yellow-light;
    }

    &__item--purple {
      border: 1px solid $purple;
      background-color: $purple-light;
    }

    &__item--orange {
      border: 1px solid $orange;
      background-color: $orange-light;
    }

    &__item--red {
      border: 1px solid $red;
      background-color: $red-light;
    }

    &.active {
      // top: 27px;
      top: 100%;
      visibility: visible;
      opacity: 1;
      transition:
        opacity 0.25s,
        top 0.25s;
    }
  }

  .dropdown-status--green {
    border: 1px solid $green;
    background-color: $green-light;

    > svg {
      fill: $green;
    }
  }

  .dropdown-status--primary-dark {
    border: 1px solid $primary-dark;
    background-color: $blue-light;

    > svg {
      fill: $primary-dark;
    }
  }

  .dropdown-status--yellow {
    border: 1px solid $yellow;
    background-color: $yellow-light;

    > svg {
      fill: $yellow;
    }
  }

  .dropdown-status--purple {
    border: 1px solid $purple;
    background-color: $purple-light;

    > svg {
      fill: $purple;
    }
  }

  .dropdown-status--orange {
    border: 1px solid $orange;
    background-color: $orange-light;

    > svg {
      fill: $orange;
    }
  }

  .dropdown-status--red {
    border: 1px solid $red;
    background-color: $red-light;

    > svg {
      fill: $red;
    }
  }

  .disable-display {
    display: none;
  }
}

.popover-status-comment {
  display: flex;
  flex-direction: column;
  width: 334px;

  &.escalate-tippy {
    width: 443px;
  }

  &__icon {
    align-self: flex-end;
    height: 24px;
    cursor: pointer;
  }

  &__text {
    // margin-bottom: 20px;
    margin-bottom: 32px;

    > h1 {
      line-height: 32px;
      text-align: initial;
    }
  }
}

.status-icon {
  cursor: default;
}

.disable-display {
  display: none;
}
