@import "./../../../../styles/colors";

.typography-h5 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  // WEIGHTS
  &--bold {
    font-weight: bold;
  }

  &--600 {
    font-weight: 600;
  }

  &--500 {
    font-weight: 500;
  }

  &--400 {
    font-weight: 400;
  }

  // COLORS
  &--color-gray-125 {
    color: $gray-125;
  }
  
  &--color-gray-175 {
    color: $gray-175;
  }

  &--color-gray-180{
    color:$gray-180;
  }
  
  &--color-gray-200 {
    color: $gray-200;
  }

  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-400 {
    color: $gray-400;
  }

  &--color-gray-500 {
    color: $gray-500;
  }

  &--color-gray-550 {
    color: $gray-550;
  }

  &--color-gray-600 {
    color: $gray-600;
  }

  &--color-black {
    color: $black;
  }

  &--color-blue-selected-4 {
    color: $blue-selected-4;
  }

  &--color-blue-selected-5 {
    color: $blue-selected-5;
  }

  &--color-green {
    color: $green;
  }

  &--color-yellow {
    color: $yellow;
  }

  &--color-red {
    color: $red;
  }

  &--color-red-2{
    color: $red-2;
  }

  &--color-primary-light {
    color: $primary-light;
  }

  &--color-white {
    color: $white;
  }

  &--color-primary-dark {
    color: $primary-dark;
  }
}
