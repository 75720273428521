@import "../../../../styles/colors";

.cases-details-splitview {
    display: flex;
    width: 100%;

    &.case-details-main-interaction {
        grid-template-areas:
            "client"
            "deadline "
            "tabs";
    }

    .splitView-table {
        flex-grow: 1;
        overflow: auto;
        // padding-left: 1.2rem;
        width: 50%;

        .table-splitView {
            width: 100%;
        }

        .cell-blue-text {
            cursor: pointer;
        }
    }

    .splitView-tabs {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        width: 50%;
        // padding: 10px;
        // width: 70%;

        .case-historic {
            margin-top: 1.7rem;
        }

        .comment-list {
            margin-top: 1.7rem;
        }

        .table-case-interactions-container {
            margin-top: 1.7rem;

            .table-case-interactions-audit {
                .table-case-interactions-audit__list {
                    .table-case-interactions-container__empty-state {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 100%;
                        padding-top: 5rem;
                    }
                }
            }
        }

        .case-communications {
            margin-top: 1rem;
            padding: 10px;
            overflow: auto;
        }

        .case-details-task {
            display: flex;
            flex-direction: column;
            gap: 24px;
            height: 100%;
            margin-top: 1rem;

            .case-details-task-list__empty-state {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                height: 100%;
            }

            .case-details-task-list {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                grid-template-rows: repeat(auto-fill, 200px);
                gap: 12px;
                flex-wrap: wrap;
                height: 0;
                flex-grow: 1;
                overflow-y: auto;
                padding-right: 12px;

                .card-task {
                    display: flex;
                    flex-direction: column;
                    grid-gap: 16px;
                    gap: 16px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    padding: 12px;
                    cursor: pointer;
                }
            }
        }

        .splitView-tab-information {
            flex-grow: 1;
            height: 0;
            padding: 0 10px;
            padding-top: 0.9rem;

            .case-splitView-client {
                overflow: auto;
                // height: 100%;
                // overflow: hidden;
                max-height: 100%;
                padding: 10px;
            }
        }

        .case-splitView-client {
            &__case-information {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .contact-client {
                    display: flex;
                    gap: 3rem;
                }
            }

            &__priority-status {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: 1.2rem;

                .case-priority {
                    // width: 166px;
                    width: 32%;

                    .dropdown-priority-btn {
                        gap: 12px;

                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 5px;

                        .dropdown-priority-btn-container {
                            &__content {
                                gap: 12px;
                            }
                        }
                    }
                }

                .case-status {
                    width: 32%;
                }
            }

            &__case-client {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                margin-top: 1.2rem;

                &__channel {
                    &__origin {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .dropdown-channel {
                        width: 100%;

                        .dropdown-channel-btn {
                            border-radius: 5px;
                            border: 1px solid rgba(0, 0, 0, 0.1);

                            .dropdown-channel-btn-container {
                                padding: 6px 8px;
                            }

                            &.active {
                                border: 1px solid #455cc7;
                            }
                        }
                    }
                }
            }

            &__case-deadline {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: 1.2rem;
            }

            .container-interection-channels {
                margin-top: 1.2rem;

                .popover-details-channel {
                    .popover-menu-list__item {
                        color: $primary-dark;
                        border-bottom: 1px solid $gray-75;
                    }
                }
            }

            &__interection-channels {
                display: flex;
                flex-direction: row;
                grid-area: interaction-channels;
                align-items: center;
                grid-gap: 8px;
                gap: 8px;

                &__content {
                    display: flex;
                    gap: 8px;
                }
            }

            .field-container-case-variable {
                margin-top: 0.6rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                cursor: pointer;

                &-control {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px;
                    background: rgba(230, 230, 230, 0.2);
                    border-radius: 4px;

                    svg {
                        transform: rotate(0deg);
                    }
                }

                &-fields {
                    width: 100%;
                    display: grid;
                    justify-content: space-between;
                    grid-template-columns: repeat(auto-fill, minmax(100px, 31%));
                    grid-row-gap: 16px;
                    row-gap: 16px;
                    padding: 16px 8px;
                    gap: 16px;

                    .custom-field-container {
                        .field-container__header {
                            .field-container__icon-container {
                                > svg {
                                    height: 17px;
                                    width: 17px;
                                }
                            }
                        }
                    }

                    .field-below-textarea {
                        grid-column: 1 / -1;
                        width: 100%;
                    }

                    .textarea-container {
                        grid-column: 1 / -1;
                        width: 100%;
                    }
                }

                &.active-variable {
                    .field-container-case-variable-control {
                        background: #ebf2ff;
                        border-radius: 4px 4px 0px 0px;

                        svg {
                            transform: rotate(-180deg);
                        }
                    }

                    .field-container-case-variable-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 16px 8px;
                        gap: 12px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-width: 0px 1px 1px 1px;
                        border-radius: 0px 0px 4px 4px;

                        .btn.btn-primary {
                            align-self: flex-start;
                        }
                    }
                }
            }

            &__subject {
                margin-top: 1.2rem;

                .textarea-subject textarea {
                    border: none;
                    padding: 8px 16px;
                    overflow-y: hidden;
                    height: 38px;
                }
            }

            &__description {
                margin-top: 1.2rem;
                // height: 45%;
            }
        }
    }

    .loading-cases-list-splitView {
        // width: 70%;
        width: 50%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .empty-state-cases-list-splitView {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 50%;
        // width: 70%;

        &__icon {
            display: flex;
            background-color: #ebf2ff;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            border-radius: 60px;
        }
    }
}

@media screen and (max-height: 800px) {
    .cases-details-splitview {
        .splitView-tabs {
            .table-case-interactions-container {
                margin-top: 1.7rem;

                .table-case-interactions-audit {
                    .table-case-interactions-audit__list {
                        .table-case-interactions-container__empty-state {
                            margin-top: -5rem;

                            > svg {
                                width: 200px;
                                height: 200px;
                            }

                            h2 {
                                margin-top: -1rem;
                            }
                        }
                    }
                }
            }

            .splitView-tab-information {
                padding-top: 0;
            }

            .case-splitView-client {
                &__priority-status {
                    margin-top: 0.6rem;
                }

                &__case-client {
                    margin-top: 0.6rem;
                }

                &__case-deadline {
                    margin-top: 0.6rem;
                }

                .container-interection-channels {
                    margin-top: 0.6rem;
                }

                &__subject {
                    margin-top: 0.6rem;
                }

                &__description {
                    max-height: 30%;
                    margin-top: 0.6rem;
                }
            }
        }
    }
}
