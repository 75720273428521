@import "./../../../styles/colors";

.page-user {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $white;
    padding: 25px 10px;
    gap: 30px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        &__title {
            display: flex;
            height: 40px;
            align-items: center;
            align-self: stretch;
            gap: 8px;

            > svg {
                cursor: pointer;
            }
        }
    }

    .table-card {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 4px;
        background: #fefefe;
        box-shadow:
            0px 0px 1px 0px rgba(25, 25, 28, 0.25),
            0px 1px 1px 0px rgba(21, 21, 24, 0.18);

        .table-card-content {
            display: flex;
            padding: 16px;
            flex-direction: column;
            gap: 16px;
            align-self: stretch;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &__table-container {
                flex: 1;
                overflow: auto;
                max-height: calc(100vh - 290px);

                .table-users {
                    .user-actions {
                        > svg {
                            transform: rotate(270deg);
                        }
                    }

                    .actions-users {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        &__edit {
                            > svg {
                                width: 24px;
                                height: 24px;

                                path {
                                    fill: $blue-selected-4;
                                }
                            }
                        }
                    }
                }
            }

            &__empty-state {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                gap: 20px;

                &__icon {
                    display: flex;
                    align-items: flex-start;
                    padding: 36px;
                    background: #ebf2ff;
                    border-radius: 100px;

                    > svg {
                        width: 46px;
                        height: 46px;
                    }
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;

                    .text-center {
                        text-align: center;
                    }

                    .text-bold {
                        font-weight: bold;
                    }
                }
            }

            &__loading {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
    }
}
