@import "./../../../styles/colors";

.tasks-and-cases {
  padding: 16px 18px 22px 13px;
  border-radius: 6px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    &__nav {
      display: flex;
      align-items: center;
      gap: 30px;

      .btn-tab {
        display: flex;
        gap: 13px;
        padding: 0 10px 11px 10px;
        cursor: pointer;

        &.active {
          color: $primary-dark;
          border-bottom: 3px solid #5B78FF;

          svg {
            fill: $primary-dark;
          }
        }

        svg {
          fill: $gray-200;
        }
      }
    }
    .header-buttons {
      display: flex;
      gap: 10px;
    }
  }

  .main-task {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 30px;
    max-height: calc(100vh - 96px);
    overflow: auto;
  }
}