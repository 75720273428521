.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  .left-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 38px;
  }
}
