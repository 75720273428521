@import "./../../../../styles/colors";

.modal-change-sla {
  overflow: auto;

   &.dark-mode{
    background-color: $secondary-05-dark;

    .input-wrapper__input{
      background-color: $secondary-05-dark;
      color: $white;
    }

    .modal-change-sla__content .MuiSlider-markLabel{
      color: $white;
    }
   }

  .modal {
    &__body {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    &__footer{
      justify-content: flex-end;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 20px;

    .input-container .input-wrapper__input{
      padding: 0;
      border: none;
      font-size: 16px;
      line-height: 24px;
    }

    .MuiSlider-thumb{
      background-color: #F2BD00;
    }

    .MuiSlider-markLabel{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      letter-spacing: 0.25px;

      // color: #6C7077;
    }

    
  }

  &__btn-confirm {
    display: flex;

  }

  &__btn-cancel.btn-primary {
    // flex-grow: 1;
    display: flex;
    background: $white;

    border: 1px solid $red-2;
    border-radius: 5px;

    &:hover{
      background: $white-50 ;
    }
  }
}


@media screen and (max-width: 700px) {
  .ReactModal__Body--open{
    overflow: hidden;
  }
  .modal-change-sla {
    width: 100%;
    height: 100%;

    &__content {
      .container-full-line {
        width: 100%;
  }
    
      .grid-span-2 {
        width: 100%;
      }

      .modal-change-sla__dropdown{
        width: 100%;
      }
    }
  }

  
}
