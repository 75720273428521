@import "./../../../styles/colors";

.communicated {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;

  .teste_texto{
    height: 32px;
    background-color: #f1f1f1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .popover-communicated {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__item:last-child {
      border-bottom: unset;
      padding-bottom: unset;
    }

    &__item {
      padding-bottom: 4px;
      border-bottom: 1px solid $gray-75;
      cursor: pointer;
    }
  }

  .communicated-information {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    // justify-content: space-between;
    // align-items: center;

    .communicated-information-user {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;

      &__icon {
        display: flex;
        align-items: flex-end;
      }

      &__box {
        display: flex;

        &-image {
          width: 22px;
          height: 22px;
          margin-right: 24px;
        }

        &-space {
          margin-left: 4px;
        }
      }
    }

    .communicated-information-date {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-left: 46px;
    }
  }

  .communicated-alert {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 8px 16px;
    margin-left: 32px;

    &.warning{
      background-color: $yellow-light2;
      border-bottom: 4px solid #F2BD00;
    }

    &.error{
      background-color: $red-light2;
      border-bottom: 4px solid $red-2;
    }

    &__icon{
      
      width: 24px;
      height: 24px;
    }

    &__content{
      display: flex;
      flex-direction: column;
      gap: 4px;
      width:calc(100% - 24px);

      &-title{
        display: flex;
        justify-content: space-between;

        svg{
          cursor: pointer;
        }
      }
    }
  }

  .communicated-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;
    margin-left: 32px;

      &__disabled {
        display: none;
      }

      &__recipient {
        display: flex;
        // padding: 4px 0px 0px 4px;
        margin-left: -4px;
        flex-direction: row;
        flex-wrap: wrap;

          // &__option {
          //   display: flex;
          // }
      }

    &__response {
      padding-bottom: 4px;
      border-bottom: 1px solid $gray-175;

      .typography-paragraph {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 31px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .typography-h6 {
        display: flex;
        align-items: center;
      }
    }

    &__text {
      display: flex;
      justify-content: space-between;

      &-crop {
        height: 32px;
        background-color: #f1f1f1;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .typography-h6 {      
        word-break: break-word;

        > div {
          display: flex;
      
          // .communicated-filtered--marked {
          //   background-color: yellow;
          // }
          // mark {
          //   background-color: $yellow;
          // }
        }

        a {
          color: #06c;
          text-decoration: underline;
        }

        img {
          max-width: 100%;
          object-fit: cover;
        }
      }

      .communicated-content__text-edit {
        width: 100%;
        padding: 0;
        border: 0;
        min-height: fit-content;
        resize: none;
        font-size: 12px;
        padding-right: 5px;
      }

      .communicated-content__text-edit-label {
        width: 100%;

        input {
          width: 100%;
          height: fit-content;
          padding: 0;
          border: 0;
          font-size: 12px;
        }
      }
    }

    .communicated-content-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .communicated-content-buttons-attachment-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex-grow: 1;
        // border-top: 1px solid $gray-175;
        padding-top: 8px;

        .communicated-content-buttons-attachment-list__attachment {
          display: flex;
          align-items: center;
        }

        .communicated-content-edit-buttons {
          display: flex;
          align-items: center;
          gap: 10px;

          svg {
            cursor: pointer;
          }
        }
      }
    }
  }

  .communicated-icon__menu {
    
      min-width: 24px;
      height: 24px;
      fill: $gray-175;
      cursor: pointer;
    
  }

  .communicated-carousel {
    background-color: $gray-50;
  }

  .communicated-content__files {
    display: flex;
    justify-content: space-between;
  }

  .communicated-attachment {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 1px solid $gray-175;
    padding-top: 8px;

    .communicated-attachment-container {
      display: flex;
      justify-content: space-between;

      > svg {
        min-width: 24px;
        height: 24px;
        fill: #bcbfd0;
        cursor: pointer;
      }

      .file-preview {
        width: 100%;
      }
    }
  }
}