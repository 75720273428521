@import "./../../../../styles/colors";

.column-container {
    display: flex;
    height: 100%;
    max-width: 400px;
    // padding: 10px 30px;
    gap: 13px;

    .column {
        display: flex;
        width: 290px;
        min-width: 290px;
        max-height: 800px;
        // max-height: 860px;
        background: $white-50;
        flex-basis: 290px;
        flex-direction: column;
        border-radius: 5px;
        gap: 30px;
        overflow: auto;

        &__header {
            display: flex;
            position: sticky;
            top: 0px;
            z-index: 15;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            gap: 10px;
            height: 40px;
            background: $white-50;
            border-radius: 8px 8px 0px 0px;

            &__text {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
            }

            &__add-case {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                > svg {
                    cursor: pointer;
                }
            }
        }

        &__cards {
            display: flex;
            flex-direction: column;
            padding: 10px;
            margin-top: -25px;
            gap: 10px;
        }
    }
}

@media screen and (max-height: 800px) {
    .column-container {
        .column {
            // max-height: 450px;
            // max-height: 500px;
            max-height: 470px;
        }
    }
}
