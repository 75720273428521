@import "./../../../styles/colors";

.drop-zone-container {
  display: flex;
  flex-direction: column;
  gap: 11px;

  &.drop-zone-container-large {
    gap: 18px;
    width: 100%;

    label {
      width: 100%;
      height: 104px;
    }

    .files-list {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 8px;
      max-height: 185px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      &__empty-state {
        align-self: center;

        > svg {
          width: 150px;
          height: 140px;
        }
      }
    }
  }

  .drop-zone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 109px;
    width: 100%;
    border: 1px dashed $gray-200;
    border-radius: 6px;
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      //visibility: hidden;
      cursor: pointer;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;

      svg {
        width: 16px;
        height: 16px;
        stroke: $gray-175
      }
    }
  }

  .files-list {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    width: 355px;

    .file {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }
  }
}