.three-dots-options-container {
  &__arrow {
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8px 0px 8px 8px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.03);
    right: -8px;
    top: 10px;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 8px 0px 8px 8px;
      border-color: transparent transparent transparent #ffffff;
      right: 2px;
      top: -8px;
      z-index: -1;
    }
  }
}
