@import "./../../../styles/colors";

.assessment {
  display: flex;
  justify-content: center;
  align-items: center;
  
  >svg {
    width: 20px;
    height: 20px;
  }

  &--interactive {
    cursor: pointer;
  }

  &--disabled {
    pointer-events: none;

    > svg {
      fill: $gray-175;
    }
  }
}