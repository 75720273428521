@import "./../../../styles/colors";

.menu-tabs {
  // border-bottom: 0.5px solid #9EAAE1;
  // margin-bottom: 17px;
  width: 100%;

  > ul {
    display: flex;
    justify-content: space-between;

    .menu-tabs-list {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray-160;
      width: 100%;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: auto;
    cursor: pointer;
    transition: all 0.25s;
    //width: 125px;
    padding: 10px;
    border-bottom: 1px solid transparent;

    .fill {
      height: 24px;

      > svg {
        fill: $gray-200;
      }
    }

    .stroke {
      height: 24px;
      > svg {
        stroke: $gray-200;
      }
    }

    &.active,
    &:hover {
      font-family: "Inter", sans-serif;
      color: #27282e;
      border-bottom: 2px solid $blue-selected-4;

      .typography-hyperlink {
        color: $blue-selected-4;
      }

      .fill {
        > svg {
          fill: $primary-dark;
        }
      }

      .stroke {
        > svg {
          stroke: $primary-dark;
        }
      }
    }
  }

  .menu-tabs__icon {
    fill: $gray-200;
    cursor: pointer;
  }
}