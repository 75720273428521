@import "./../../../../styles/colors";

.dateRangeInput.MuiFormControl-root.MuiTextField-root {
    width: 150px;

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary {
        top: -8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        // line-height: 24px;
        font-size: 14px;
        line-height: 21px;

        color: #757575;

        &.Mui-focused {
            color: $blue-selected-4;
        }
    }

    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
        border-radius: 5px;
        height: 38px;
        padding: 0;
    }

    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
        padding: 10px 14px;
        //14px
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        // font-size: 16px;
        // line-height: 24px;
        font-size: 14px;
        line-height: 21px;
    }

    .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium {
        margin: 0;

        .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
            margin: 0;
        }
    }
}

.MuiPaper-root .PrivatePickersSlideTransition-root {
    min-height: 230px;
}
