@import "./../../../styles/colors";

.button-agent-search {
  display: flex;
  gap: 8px;
  // height: 22px;
  

  .user-name{
    display: flex;
    height: 40px;
    padding: 12px 8px;
    align-items: center;
    border-radius: 5px;
    gap: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: rgba(255, 255, 255, 0.31);

    .create-icon{
      path {
        stroke: #757575;
      }
    }
    .tag-profile{
      display: flex;
      height: 32px;
      padding: 4px 8px 4px 4px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 24px;
      background: #F4F4F4;

    }
  }

  .popover-button-search {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 253px;
    height: 330px;
    background-color: $white;
    

    .input-container {
      .input-wrapper__input {
        height: 40px;
      }
      svg {
        fill: $secondary-02-light;
        stroke: none;
      }
    }

    &__value {
      display: flex;
      align-items: center;
      gap: 16px;
      // cursor: pointer;

      .user-name{
        display: flex;
        align-items: center;

        .create-user{
          position: relative;
          left: 5px;
        }
      }

      // .image-profile {
      //   width: 30px;
      //   height: 30px;
      //   cursor: pointer;
      // }

      .icon-down{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .icon-add {
        height: 24px;
        svg {
          fill: $gray-175;
          width: 24px;
          height: 24px;
        }
      }
    }


    &__list {
      display: flex;
      flex-direction: column;
      // gap: 8px;
      // padding-right: 6px;
      max-height: 288px;
      overflow: auto;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 8px;
      // padding: 4px 8px;
      padding: 16px;
      cursor: pointer;

      .content-text{
        text-align: initial;
      }

      &.active {
        background-color: $blue-selected-3;
        border-radius: 4px;
        cursor: pointer;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $primary-dark;
      }
    }
  }

}

.modal_change_owner{
  display: flex;
  flex-direction: column;
  gap: 32px;

  .user-name{
    display: flex;
    height: 40px;
    padding: 12px 8px;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: rgba(255, 255, 255, 0.31);

    .create-icon{
      path {
        stroke: #757575;
      }
    }
    .tag-profile{
      display: flex;
      height: 32px;
      padding: 4px 8px 4px 4px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 24px;
      background: #F4F4F4;

    }
  }

  .popover-button-search {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // width: 253px;
    height: 270px;
    background-color: $white;
    

    .input-container {
      .input-wrapper__input {
        height: 40px;
      }
      svg {
        fill: $secondary-02-light;
        stroke: none;
      }
    }

    &__value {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      .user-name{
        display: flex;
        align-items: center;

        .create-user{
          position: relative;
          left: 5px;
        }
      }

      // .image-profile {
      //   width: 30px;
      //   height: 30px;
      //   cursor: pointer;
      // }

      .icon-down{
        width: 24px;
        height: 24px;
      }

      .icon-add {
        height: 24px;
        svg {
          fill: $gray-175;
          width: 24px;
          height: 24px;
        }
      }
    }


    &__list {
      display: flex;
      flex-direction: column;
      // gap: 8px;
      // padding-right: 6px;
      max-height: 288px;
      overflow: auto;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 8px;
      // padding: 4px 8px;
      padding: 16px;
      cursor: pointer;

      .content-text{
        text-align: initial;
      }

      &.active {
        background-color: $blue-selected-3;
        border-radius: 4px;
        cursor: pointer;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: $primary-dark;
      }

      &:hover{
        background-color: #ebf2ff;
      }
    }
  }

  ul {
    list-style: inside disc;
  }

  .checkbox-box{
    display: flex;
    gap: 8px;

    .checkmark{
      margin-left: 0;
    }
  }

  &__buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 16px;
  }
}