@import "../../../styles/colors";

.page-records {
    background-color: #ffffff;
    padding: 0px 10px;
    height: 100%;
    width: 100%;

    .page-records-content {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 25px 10px;
        gap: 16px;

        &__header {
            display: flex;
            height: 40px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }

        &__container-buttons {
            display: flex;
            height: 137px;
            width: 100%;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 16px;

            .record-category {
                display: flex;
                width: 160px;
                height: 140px;
                padding: 24px 20px;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                background: $white;
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

                svg {
                    height: 32px;
                    width: 32px;

                    path {
                        fill: $blue-selected-4;
                    }
                }
            }
        }
    }
}
