@import "./../../../styles/colors";
@import "./../../../styles/fonts";


.quill-toolbar-mail{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  background: #F4F4F4;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;

  &.audio{
    display: none;
  }

  svg{
    cursor: pointer;
  }

  .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-family: "Inter", 'Arial', sans-serif;
    padding: 8px 0 8px 0;


    .ql-formats{
      display: flex;

      #buttons-quill {
        &.ql-variableEdit{
          width: auto;
          display: flex;
          align-items: center;
        }

      }
      
      .ql-active{
        svg{
          path {
            fill: $blue-selected-4;
            
          }
        }
        
      }

      .attach-file__icon-communicated{
        svg{
          path {
            fill: $gray-180;
            
          }
        }
      }

      
    }
    .ql-snow .ql-formats {
      display: flex;
      align-items: center;

      
    }

    .ql-undo, .ql-redo{
      &.disabled{
        pointer-events: none;
      }
    }
  }
}
