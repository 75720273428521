@import "./../../../styles/colors";
.rhap_volume-container {
  flex: 0 0 100px;
}

.rhap_volume-controls {
  flex: 0 0 100px;
}

.rhap_additional-controls {
  grid-area: image;
  gap: 4px;
  width: 45px;
  flex: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 24px;

  background-color: $white-50;
}

.rhap_container {
  background-color: inherit;
}

.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  background-color: #fff;

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  svg {
    vertical-align: initial;
  }
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 10px;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 8px;
}

.rhap_horizontal {
  flex-direction: row;

  .rhap_controls-section {
    margin-left: 8px;
  }
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;

  
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;

  .rhap_controls-section {
    margin-bottom: 8px;
  }
}

.rhap_progress-section {
  position: relative;
  display: flex;
  gap: 8px;
  // flex: 3 1 auto;
  align-items: center;
  width: 100%;

  > svg {
    min-width: 24px;
    height: 24px;
    fill: $gray-175;
    cursor: pointer;
  }
}

.rhap_progress-container {
  display: flex;

  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin-right: calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_time {
  color: $gray-180;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  user-select: none;
  -webkit-user-select: none;
  position: absolute;
  top: 20px;
}


.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #dddddd;
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: $blue-selected-4;
  border-radius: 30px;
}

.rhap_progress-bar-show-download {
  background-color: $gray-100;
}

.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: $gray-100;
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 12px;
  height: 12px;
  top: -4px;
  background: $white-50;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  color: $primary-dark;
  font-size: 28px;
  width: 28px;
  height: 28px;
}

.rhap_play-pause-button {
  font-size: 30px;
  width: 30px;
  height: 30px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.rhap_volume-button {
  flex: 0 0 26px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: $primary-dark;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 100px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: $blue-selected-4;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: $gray-200;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #868686;
  border-radius: 2px;
}

/* Utils */

.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    transition-duration: 0.2s;
  }

  &:active {
    opacity: 0.95;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}
