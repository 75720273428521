@import "./../../../styles/colors";

.custom-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 112.5%;
  fill: #FFFFFF;
}

.tooltip-text{
  padding: 8px 16px;

  background: rgba(24, 24, 27, 0.733333);
  border-radius: 4px;

  h6{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

}

.centered-text-1, .centered-text-2{
  font-family: "Inter";
  font-style: normal;

  &.dark-mode{
    fill: $white;
  }
}

.centered-text-1{
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.centered-text-2{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #757575;
}