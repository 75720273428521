@import "./../../../styles/colors";

.interaction-comment-container{
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  &.reverse-row{
    flex-direction: row-reverse;
  }

  .interaction-comment {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    position: relative;
    background: #EBF6EB;
    border-radius: 8px;

    &.blue-color{
      background: #EBF2FF;
    }

    &.comment-length-normal{
      width: 357px;
    }

    &.comment-length-alternate{
      min-width: 40%;
      max-width: 78%;
    }

    &.maps-width{
      max-width: 57%;
    }
  
    .comment-information {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .comment-information-user {
        display: flex;
        align-items: center;
        gap: 10px;
  
        &__image {
          width: 22px;
          height: 22px;
        }
      }
    }

    .comment-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 2px;

      &__response {
        display: flex;
        // padding-bottom: 4px;
        // border-bottom: 1px solid $gray-175;
  
        .response-divider{
          width: 4px;
          height: 100%;
          background: $blue-selected-4;
          border-radius: 4px 0px 0px 4px;
        }
  
        .response-content{
          background: $white;
          width: 100%;
          padding: 8px;

          .typography-h6{
            word-break: break-word;
          }
        }
  
        .typography-paragraph {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          // max-height: 31px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
  
        .typography-h6 {
          display: flex;
          align-items: center;
        }
      }

      .bubble-media__video-frame{
        width: 100%;
        height: 100%;
        position: relative;
      }

      .comment-content-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .comment-content-buttons-attachment-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          flex-grow: 1;
  
          &.column{
            flex-direction: column;
          }

  
          .attach-file__icon > svg {
            width: 16px;
            height: 16px;
            stroke: none;
            fill: $blue-selected-4;
        }
      }
    }

      .rhap_main{
        height: 56px;

        .rhap_time{
          top: 35px;
        }
      }
  
      &__text {
      display: flex;
        justify-content: space-between;

        .typography-h5 {      
          > p {           
            word-break: break-word;

          }
        }
      }
    }

    .comment-footer{
      align-self: flex-end;
      // padding-right: 10px;
      display: flex;
      gap: 8px;
  
      >h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .gray-tick{
        stroke: #757575;
      }

    }
  
    .comment-buttons{
      &__item-list{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__item{
        display: flex;
        align-items: center;
        padding: 10px 0px;
        gap: 8px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.1);
        border-radius: 0px;
  
        svg path{
          fill: $blue-selected-5;
        }
  
        &-list{
          .list-rows{
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .list-rows_item{
              h6{
                padding-left: 12px;
              }
            }
          }
        }
  
      }
    }
}

  .comment-list__profileImage {
    align-self: flex-end;
}
}



// .interaction-comment-container {
//   display: flex;
//   align-items: flex-end;
//   gap: 4px;
//   width: 100%;

//   .image-profile {
//     width: 22px;
//     height: 22px;
//   }

//   .interaction-comment {
//     border: 1px solid $gray-175;
//     border-radius: 16px;
//     padding: 16px 16px 8px 16px;
//     width: calc(50% - 26px);

//     &.right {
//       margin-left: auto;
//       border-bottom-right-radius: 0px;
//       background-color: $gray-50;
//     }

//     &.left {
//       margin-right: auto;
//       border-bottom-left-radius: 0px;
//       background-color: $white;
//     }

//     .interaction-comment-image {
//       display: flex;
//       flex-direction: column;
//       gap: 4px;
//     }

//     .interaction-comment-attachment {
//       display: flex;
//       flex-direction: column;
//       gap: 4px;

//       &--right {
//         .file-preview-audio {

//           .rhap_container {
//             background-color: $gray-50;
//           }
//         }
//       }
//     }

//     .interaction-comment-text {
//       display: flex;
//       flex-direction: column;
//       gap: 4px;

//       .typography-h6 {
//         word-break: break-word;
//       }
//     }

//     .interaction-comment-date {
//       align-self: flex-end;
//     }
//   }
// }