.filter-list {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  &__tags {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}
