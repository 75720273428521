@import "../../../../styles/colors";

.page-update-organization {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $white;
    padding: 25px 10px;
    gap: 30px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        &__title {
            display: flex;
            height: 40px;
            align-items: center;
            align-self: stretch;
            gap: 8px;

            > svg {
                cursor: pointer;
            }
        }

        &__save-or-cancel {
            display: flex;
            align-items: center;
            gap: 10px;

            .btn-remove-organization.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $red-2;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }

            .btn-cancel-organization.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $blue-selected-4;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }
        }
    }

    .section-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 15px;
        padding: 0 8px;
        overflow: auto;

        .basic-data-section,
        .shift-worker-section {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background: #fefefe;
            box-shadow:
                0px 0px 1px 0px rgba(25, 25, 28, 0.25),
                0px 1px 1px 0px rgba(21, 21, 24, 0.18);

            .basic-data-section-header,
            .shift-worker-section-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                align-self: stretch;

                &__content {
                    display: flex;
                    padding: 16px;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;
                }

                &__division {
                    width: 100%;
                    height: 1px;
                    background-color: #e5e7eb;
                }
            }

            .basic-data-content-container {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;

                    .columns-data {
                        display: flex;
                        width: 100%;
                        align-items: flex-start;
                        align-self: stretch;
                        gap: 16px;
                    }
                }
            }

            .basic-data-content-container {
                &__content {
                    .user-association {
                        display: flex;
                        padding-top: 30px;
                        gap: 4px;
                    }

                    .container-hour-association {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding-top: 30px;
                    }
                }
            }

            .shift-worker-content-container {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                > div {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 16px;
                }

                &__loading {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }

                &__content {
                    display: flex;
                    padding: 16px;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    .sla-column {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex: 0.45;
                        align-items: flex-start;
                        align-self: stretch;
                        gap: 16px;

                        .delete-sla {
                            display: flex;
                            gap: 8px;
                            cursor: pointer;
                        }
                    }

                    .days-week-column {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 16px;
                        flex: 1 0 0;

                        &__content {
                            display: flex;
                            align-items: center;
                            align-self: stretch;
                            width: 100%;
                            gap: 16px;

                            &__actions {
                                display: flex;
                                align-items: center;
                                padding-top: 30px;
                                gap: 16px;

                                .delete-day-week {
                                    cursor: pointer;

                                    path {
                                        stroke: $red-2;
                                    }

                                    &.disabled {
                                        cursor: not-allowed;

                                        path {
                                            stroke: #bfc3ca;
                                        }
                                    }
                                }

                                .add-day-week {
                                    cursor: pointer;

                                    path {
                                        stroke: $blue-selected-4;
                                    }
                                }
                            }
                        }
                    }
                }

                .add-sla {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 8px;
                    cursor: pointer;

                    > svg {
                        cursor: pointer;

                        path {
                            stroke: $blue-selected-4;
                        }
                    }
                }
            }
        }
    }
}
