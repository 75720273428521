@import "../../../../styles/colors";

.page-update-sla {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $white;
    padding: 25px 10px;
    gap: 30px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        &__title {
            display: flex;
            height: 40px;
            align-items: center;
            align-self: stretch;
            gap: 8px;

            > svg {
                cursor: pointer;
            }
        }

        &__save-or-cancel {
            display: flex;
            align-items: center;
            gap: 10px;

            .btn-cancel-sla.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $blue-selected-4;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }

            .btn-remove-sla.btn-primary {
                display: flex;
                background: $white;

                border: 1px solid $red-2;
                border-radius: 5px;

                &:hover {
                    background: $white-50;
                }
            }
        }
    }

    .section-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 15px;
        padding: 0 8px;
        overflow: auto;

        .basic-data-section,
        .associated-schedules {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 4px;
            background: #fefefe;
            box-shadow:
                0px 0px 1px 0px rgba(25, 25, 28, 0.25),
                0px 1px 1px 0px rgba(21, 21, 24, 0.18);

            .basic-data-section-header,
            .associated-schedules-header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                align-self: stretch;

                &__content {
                    display: flex;
                    padding: 16px;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;
                }

                &__division {
                    width: 100%;
                    height: 1px;
                    background-color: #e5e7eb;
                }
            }

            .basic-data-content-container,
            .hours-association-container {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;

                    .columns-data {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        width: 100%;
                        align-items: flex-start;
                        align-self: stretch;
                        grid-gap: 16px;
                        gap: 16px;
                    }
                }
            }

            .basic-data-content-container {
                &__content {
                    .user-association {
                        display: flex;
                        padding-top: 30px;
                        gap: 4px;
                    }

                    .container-hour-association {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding-top: 30px;
                    }
                }
            }

            .hours-association-container {
                &__content {
                    &__loading {
                        display: flex;
                        height: 100%;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                    }

                    .associated-hours-fields {
                        display: flex;
                        width: 100%;
                        align-items: flex-start;
                        gap: 16px;
                        align-self: stretch;

                        &__actions {
                            display: flex;
                            align-items: center;
                            padding-top: 30px;
                            gap: 16px;

                            .delete-hour {
                                cursor: pointer;

                                path {
                                    stroke: $red-2;
                                }

                                &.disabled {
                                    cursor: not-allowed;

                                    path {
                                        stroke: #bfc3ca;
                                    }
                                }
                            }

                            .add-hour {
                                cursor: pointer;

                                path {
                                    stroke: $blue-selected-4;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
