@import "./../../../styles/colors";

.dropdown-escalate {
  position: relative;
  //width: 100%;

  &__disable {
    pointer-events: none;
    border-radius: 8px;
  }

  &__footer {
    display: flex;
    gap: 16px;
    padding-top: 16px;
    border-top: 1px solid $gray-100;

    > button {
      flex-grow: 1;
      height: 40px;
      border-radius: 4px;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
    }

    &-cancel {
      color: $red;
    }

    &-confirm {
      background-color: $primary-dark;
      color: white;
      font-weight: 600;
    }
  }

  &.dropdown-escalate--tag {
    .dropdown-escalate-btn {
      max-width: 120px;
      justify-content: center;
    }
  }

  .dropdown-escalate-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    width: inherit;
    // width: 140px;
    height: 26px;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: border-radius 0.25s;

    &.tag {
      cursor: default;
    }

    > svg {
      width: 16px;
      height: 16px;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-escalate-list {
    position: absolute;
    z-index: 15;
    display: flex;
    flex-direction: column;
    gap: 1px;
    min-width: 150px;
    width: 100%;
    // border: 1px solid $primary-dark;
    // border-top: 0;
    // border-radius: 0 0 8px 8px;
    background-color: #fff;
    top: 30px;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s 0.25s,
      opacity 0.25s,
      top 0.25s;
    box-shadow:
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-height: 300px;
    overflow: auto;

    &__input {
      padding: 8px;
    }

    &__empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 10px;

      > h5 {
        text-align: center;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      // height: 26px;
      min-height: 40px;
      // padding: 4px 8px;
      padding: 11px 15px;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: $gray-75;
      }

      h5 {
        white-space: nowrap;
      }
    }

    &__item--green {
      border: 1px solid $green;
      background-color: $green-light;
    }

    &__item--primary-dark {
      border: 1px solid $primary-dark;
      background-color: $blue-light;
    }

    &__item--yellow {
      border: 1px solid $yellow;
      background-color: $yellow-light;
    }

    &__item--purple {
      border: 1px solid $purple;
      background-color: $purple-light;
    }

    &__item--orange {
      border: 1px solid $orange;
      background-color: $orange-light;
    }

    &__item--red {
      border: 1px solid $red;
      background-color: $red-light;
    }

    &__item--black {
      z-index: 999999;
      border: 1px solid #808080;
      background-color: white;
    }

    &.active {
      top: 100%;
      visibility: visible;
      opacity: 1;
      transition:
        opacity 0.25s,
        top 0.25s;
    }
  }

  .dropdown-escalate--black {
    border: 1px solid #808080;
  }

  .dropdown-escalate--primary-dark {
    border: 1px solid $primary-dark;
    background-color: $blue-light;

    > svg {
      fill: $primary-dark;
    }
  }

  .dropdown-escalate--yellow {
    border: 1px solid $yellow;
    background-color: $yellow-light;

    > svg {
      fill: $yellow;
    }
  }

  .dropdown-escalate--purple {
    border: 1px solid $purple;
    background-color: $purple-light;

    > svg {
      fill: $purple;
    }
  }

  .dropdown-escalate--orange {
    border: 1px solid $orange;
    background-color: $orange-light;

    > svg {
      fill: $orange;
    }
  }

  .dropdown-escalate--red {
    border: 1px solid $red;
    background-color: $red-light;

    > svg {
      fill: $red;
    }
  }

  &--error {
    border-color: #f7404e;
  }
}

.modal-create-case__dropdown {
  .dropdown-escalate--black {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .dropdown-escalate-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    // width: inherit;
    width: 100%;
    height: 38px;
    padding: 4px 8px;
    // border-radius: 5px;
    cursor: pointer;
    transition: border-radius 0.25s;
    background: #ffffff;
  }

  .dropdown-escalate--error {
    border-color: #f7404e;
  }
}

// .popover-status-comment {
//   display: flex;
//   flex-direction: column;
//   // width: 334px;

//   &__icon {
//     align-self: flex-end;
//     height: 24px;
//     cursor: pointer;
//   }

//   &__text {
//     margin-bottom: 20px;
//   }
// }
