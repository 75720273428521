@import "./../../../styles/colors";

.tag-list {
  display: flex;
  gap: 8px;

  &__tags {
    display: flex;
    gap: 8px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;

    &__view {
      width: 24px;
      height: 24px;

      > button {
        // width: 24px;
        // height: 24px;

        width: 19px;
        height: 16px;
        font-weight: 500;
        font-family: "Inter";
        font-style: normal;
        font-size: 14px;
        line-height: 112.5%;
        top: 4px;
        color: #757575;
      }
    }

    > button {
      width: 24px;
      height: 24px;
    }

    .tag-list__popover {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 8px 16px 28px;
      width: 330px;
      background-color: $white;
      // border: 1px solid $primary-dark;
      // border-radius: 8px;
      // position: absolute;
      // bottom: 38px;
      // z-index: 3;

      // &.tag-list__popover--view {
      //   left: -180.5px;
      // }

      // &.tag-list__popover--add {
      //   left: -155.5px;
      // }

      // &.tag-list__popover--view-only {
      //   left: -175.5px;
      // }

      // &.active {
      //   display: flex;
      // }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          fill: $gray-85 !important;
          cursor: pointer;
        }
      }

      .input-container {
        svg {
          fill: $secondary-02-light;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        max-height: 99px;
        overflow: auto;
        padding-right: 16px;
        padding-bottom: 1px;
      }
    }

    .tag-list-view {
      .tag {
        cursor: default;
      }
    }

    .tag-list__popover__after {
      content: "";
      padding: 10px;
      background-color: $primary-dark;
      transform: rotate(45deg);
      position: absolute;
      top: -26px;
      z-index: 2;

      // &.tag-list__popover__after--view {
      //   left: calc(50% - 20px);
      // }

      // &.tag-list__popover__after--add {
      //   left: calc(50% + 2px);
      // }

      // &.tag-list__popover__after--view-only {
      //   left: calc(50% - 7px);
      // }
    }
  }

  &--view-only {
    .tag {
      cursor: default;
    }
  }
}
