@import "./../../../styles/colors";

.tag {
  // display: flex;
  // align-items: center;
  // padding: 4px 14px;
  // background-color: $primary-dark;
  // border-radius: 50px;
  // height: 25px;
  // cursor: pointer;
  // transition: padding 0.25s;

  //* ======== NEW STYLE ========
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  height: 24px;
  background: $blue-selected-3;
  border-radius: 24px;
  border: 1px solid rgba(216, 213, 213, 0.25);
  transition: padding 0.25s;

  .tippy-box[data-theme~="popover-default"] {
    display: none;
  }

  > .typography-paragraph {
    max-width: 76px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
  }

  &.tag--options:hover {
    padding: 4px 8px;
    .tippy-box[data-theme~="popover-default"] {
      display: block;
      margin-top: 3px;
    }

    .tag__menu {
      opacity: 1;
      max-width: 12px;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    width: 12px;
    height: 12px;
    fill: $white;
    cursor: pointer;
    opacity: 0;
    max-width: 0;
    transition: opacity 0.25s, max-width 0.25s;

    .menu-tag {
      stroke: $blue-selected-4;
    }
  }

  &.tag-icon {
    gap: 4px;
    transition: padding 0.25s;

    > svg {
      //display: none;
      width: 9px;
      height: 9px;
      // background-color: #ffffff;
      background-color: $blue-selected-4;
      // fill: $primary-dark;
      fill: $white;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      max-width: 0;
      transition: opacity 0.25s, max-width 0.25s;
    }
  }

  &.tag-icon:hover {
    &.tag--options {
      padding: 4px 3.5px;
    }
    padding: 4px 9.5px;
    //transition: padding 0.25s;
    > svg {
      opacity: 1;
      max-width: 9px;
      //transition: opacity 0.25s, max-width 0.25s;
      //display: block;
    }
  }

  &.tag--disabled {
    background-color: $white;
    border: 1px solid $blue-selected-4;
    // border: 1px solid $primary-dark;

    .tag__menu {
      > svg {
        stroke: $primary-dark;
      }
    }
  }

  .popover-tag {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__item:last-child {
      border-bottom: unset;
      padding-bottom: unset;
    }

    &__item {
      padding-bottom: 4px;
      border-bottom: 1px solid $gray-75;
      cursor: pointer;
    }
  }
}

.tag-input {
  display: flex;
  max-width: 112px;
  align-items: center;
  height: 25px;
  padding: 4px 14px;
  border-radius: 50px;
  background-color: $blue-selected-3;
  // background-color: $primary-dark;

  &.tag-input--disabled {
    background-color: $white;
    border: 1px solid $primary-dark;

    .tag-input__input {
      background-color: $white;
      color: $primary-dark;
    }
  }

  &__input {
    border: 0;
    max-width: 80px;
    //width: 70px;
    // background-color: $primary-dark;
    background-color: $blue-selected-3;
    // font-size: 10px;
    font-size: 14px;
    // font-weight: bold;
    // color: $white;
    color: $blue-selected-4;
    // font-family: "Poppins", sans-serif;
    font-family: "Inter", sans-serif;
  }

  > svg {
    width: 9px;
    height: 9px;
    background-color: $blue-selected-4;
    fill: $white;
    // background-color: #ffffff;
    // fill: $primary-dark;
    border-radius: 50%;
    cursor: pointer;
  }
}
