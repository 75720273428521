.balloon{
    width: 8px;
    height: 8px;
    border-radius: 8px;

    &.pink { 
        background-color: #D81B60;
    }

    &.marine { 
        background-color: #5E35B1;
    }

    &.blue {  
        background-color: #00ACC1; 
    }

    &.red {        
        background-color: #F4511E;      
    }

    &.brow {         
        background-color: #6D4C41;        
    }

    &.yellow {         
        background-color: #FFB300;        
    }

    &.gray {        
        background-color: #D9D9D9;       
    }
}
