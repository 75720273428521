body,
button,
a,
ul {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

a {
	text-decoration: none;
	color: unset;
}

button {
	border: unset;
	background: unset;
	cursor: pointer;
}

input {
	outline: 0;
}
