@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.comment-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  gap: 8px;

  .comment-list-search {
    padding-right: 16px;
    input {
      border: 1px solid $gray-200;
    }
  }

  .comment-list-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__empty-state {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 24px;
      // max-width: 296px;
      height: 100%;
      text-align: center;

      .container-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        background-color: $blue-selected-3;
        border-radius: 50%;

        &.red-color{
          background-color: $red-light2;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    height: 0;
    overflow: auto;
    padding-right: 16px;
    padding-bottom: 1px;
    scroll-behavior: smooth;

    .comment-container{
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      &.reverse-row{
        flex-direction: row-reverse;
      }
    }

    .comment-list__profileImage{
      align-self: flex-end;
    }
  }

  .send-comment-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.disabled{
      pointer-events: none;
      background-color: #DBDDE2;

      input {
        background-color: #DBDDE2;
      }

      .sending-comment__btn-send.btn{
        background-color: $gray-180;
        border-color: $gray-180;
      }
      
    }
  }

  .comment-list-response-container {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-grow: 1;
    // background: $gray-50;
    border-radius: 8px;
    padding: 3px 10px;
    //margin: 0 32px;

    > svg {
      cursor: pointer;
    }

    .comment-list-response {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &__text {
        flex-grow: 1;
        width: 0px;
        min-width: 100%;
        @include text-overflow;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .comment-list-send {
    display: flex;
    align-items: center;
    gap: 8px;

    &__mic {
      cursor: pointer;
    }

    > svg {
      width: 24px;
      height: 24px;
    }

    &__input {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 12.5px 10px;
      border: 1px solid $gray-175;
      border-radius: 54px;

      > input {
        width: 100%;
        border: unset;
        outline: unset;

        &::placeholder {
          color: $gray-175;
        }
      }
    }
  }
}

.audio-recorder {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: $gray-50;
  border: 1px solid $gray-175;
  border-radius: 50px;

  > svg {
    fill: $gray-200;
    cursor: pointer;
  }

  .audio-recorder-send {
    display: flex;
    align-items: center;
    gap: 8px;

    > button {
      cursor: pointer;
    }

    .audio-recorder-send-time {
      width: 30px;
    }
  }
}

@media screen and (max-width: 700px) {
  .comment-list {
    &__list {
      
      max-height: 100vh;
    }
  }
}
