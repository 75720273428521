@import "./../../../../styles/colors";

.main-change-password {
    display: flex;
    flex-direction: column;
    // padding: 0 62px;
    // margin-top: 97px;
    margin: auto;

    &__section {
        display: flex;
        flex-direction: column;
        padding-top: 8%;

        .change-password-form {
            display: flex;
            flex-direction: column;

            &__content {
                display: flex;
                flex-direction: column;
                gap: 22px;

                .alert-message {
                    display: flex;
                    align-items: flex-start;
                    padding-top: 5px;
                    gap: 8px;
                }
            }

            &__buttons {
                display: flex;
                gap: 24px;
                padding-top: 30px;

                &__btn-cancel.btn-primary {
                    display: flex;
                    background: $white;

                    border: 1px solid $red-2;
                    border-radius: 5px;

                    &:hover {
                        background: $white-50;
                    }
                }

                h4 {
                    white-space: nowrap;
                }
            }
        }
    }

    &__logIn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 8%;
        gap: 30px;

        &__icon {
            display: flex;
            background-color: #008908;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            border-radius: 60px;

            > svg {
                path {
                    fill: $white;
                }
            }
        }
    }
}
