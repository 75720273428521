@import "./../../../../styles/colors";

.typography-h4 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  // WEIGHTS
  &--bold, &--700 {
    font-weight: bold;
  }

  &--500 {
    font-weight: 500;
  }

  &--400 {
    font-weight: 400;
  }

  // COLORS
  &--color-white {
    color: $white;
  }

  &--color-black {
    color: $black;
  }

  &--color-red-2{
    color: $red-2;
  }

  &--color-blue-selected-4 {
    color: $blue-selected-4;
  }

  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-180 {
    color: $gray-180;
  }

  &--color-gray-125 {
    color: $gray-125;
  }
  
  &--color-gray-200 {
    color: $gray-200;
  }

  &--color-gray-400 {
    color: $gray-400;
  }

  &--color-gray-600 {
    color: $gray-600;
  }
}
