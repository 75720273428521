@import "./../../../styles/colors";

.reminder {
  display: flex;
  align-items: center;
  gap: 10px;

  > svg {
    width: 12px;
    height: 12px;
    fill: $white;
    background-color: $primary-dark;
    border-radius: 50%;
    cursor: pointer;
  }
}