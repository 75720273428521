.toast-custom-success {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .toast-custom-success-content {
        display: flex;
        align-items: center;
        gap: 8px;

        &__icon {
            display: flex;
            align-items: flex-start;
            padding: 15px;
            background: #EBF6EB;
            border-radius: 100px;
        }

        &__message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
        }
    }
}
