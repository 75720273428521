@import "./../../../../styles/colors";

.modal-delete-solicitation {
    .modal {
        &__footer {
            justify-content: flex-end;
        }

        &__body {
            .information-text {
                text-align: center;
            }
        }

        &__header {
            .modal-delete-solicitation__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 48px;
                background: #fbebeb;
            }
        }
    }

    .container-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
        margin-top: 10px;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $blue-selected-4;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }

    &__btn-confirm.btn-primary {
        background-color: $red-2;
        border: 1px solid $red-2;

        &:hover{
            background: $red;
        }
    }
}
