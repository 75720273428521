@import "./../../../../styles/colors";

.modal-manage-motives {
    .container-footer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        position: absolute;
        width: 206px;
        height: 40px;
        right: 25px;
        top: 596px;
    }

    &__content {
        .input-search-motives {
            top: 70px;
            width: 271px;
            padding: 8px;
            position: absolute;
        }

        .rectangle-motives {
            box-sizing: border-box;
            position: absolute;
            width: 953px;
            height: 440px;
            left: 24px;
            top: 132px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;

            .container-motives {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                position: absolute;
                width: 100%;
                height: 408px;
                left: 20px;
                top: 20px;

                &__tags {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 6px;
                    width: 920px;
                    flex-wrap: wrap;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    .tag-item {
                        display: flex;
                        align-items: center;
                        grid-gap: 4px;
                        gap: 4px;
                        padding: 4px 4px 4px 8px;
                        background-color: #f4f4f4;
                        border-radius: 24px;
                        min-width: max-content;
                        margin-right: 8px;
                        margin-bottom: 4px;

                        .close {
                            height: 20px;
                            width: 20px;
                            background: #bfc3ca;
                            color: #fff;
                            border-radius: 50%;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 0.5em;
                            cursor: pointer;

                            svg {
                                width: 13px;
                                height: 13px;
                            }
                        }

                        .text {
                            font-family: "Inter", sans-serif;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 112.5%;
                            display: flex;
                            align-items: flex-end;
                            color: #455cc7;
                        }
                    }
                }

                .tags-input {
                    flex-grow: 1;
                    padding: 0.5em 0;
                    border: none;
                    outline: none;
                    color: #27282e;
                    margin-left: 10px;
                    font-family: "Inter", sans-serif;
                    font-size: 14px;
                    color: #27282e;
                    transition: all 0.25s;
                }

                .tags-input::placeholder {
                    color: #bcbfd0;
                    font-size: 14px;
                }
            }
        }
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
