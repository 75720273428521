@import "./../../../../../styles/colors";
@import "./../../../../../styles/mixins";

.history-element {
  display: flex;
  gap: 8px;

  &__comments {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    gap: 16px;
  }

  &__side {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      width: 32px;
      height: 32px;
      background-color: $blue-selected-3;
      border-radius: 2px;
      padding: 4px;

      svg{
        width: 24px;
        height: 24px;
      }
    }

    &__separator {
      height: 100%;
      border: 1px solid #ebf2ff;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .history-element-information {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      flex-direction: column;

      &__text {
        display: flex;
        width: 100%;

        .history-element-info {
          max-width: 210px;
          @include text-overflow;
        }
      }

      &__image {
        height: 24px;
        width: 24px;
      }

      > .typography-paragraph {
        display: flex;
        align-items: center;
      }

      // .history-element-channel {

      //   .channel {
      //     width: 18px;
      //     height: 18px;
      //   }
      // }
    }

    .history-element-date {
      display: flex;
      gap: 4px;
      align-items: center;

      &__button {
        height: 24px;
        cursor: pointer;
        margin-left: auto;

        svg {
          fill: $primary-dark;
        }

        &.active {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .comment-history {
    display: none;
    flex-direction: column;
    gap: 16px;
    position: relative;

    &.active {
      display: flex;
    }
  
    .comment-history-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 8px;

      &__files {
        .comment-carousel {
          background-color: $white;
        }
      }

      &--gray {
        background-color: $gray-50;

        .comment-history-carousel {
          background-color: $gray-50;
        }
      }
  
      &__text {
        display: flex;
        justify-content: space-between;
  
        .typography-h6 {      
          word-break: break-word;
  
          > div {
            display: flex;
        
            // .comment-filtered--marked {
            //   background-color: yellow;
            // }
            mark {
              background-color: $yellow;
            }
          }
        }
      }
  
      &__audio {
        width: 453px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px 16px 16px;

        background: $white;

        border: 1px solid #bcbfd0;
        border-radius: 8px;
      }

      .comment-history-content-answers {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__header {
          display: flex;
          align-items: center;
          gap: 8px;

          .history-element-date__button {
            height: 24px;
            cursor: pointer;

            svg {
              transform: rotate(270deg);
              fill: $primary-dark;
            }

            &.active {
              svg {
                transform: rotate(360deg);
              }
            }
          }

          .answers__header-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 112.5%;

            display: flex;
            align-items: center;
            letter-spacing: 0.005em;

            color: $blue-selected-4;
          }
        }

        &__container {
          display: none;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 32px;

          &.active {
            display: flex;
            gap: 16px;

            max-width: 452px;
          }

          .content-answers__container{
            display: flex;
            flex-direction: column;
            gap: 16px;

            .content-answers__container_header {
              display: flex;
  
              &-iconTitle {
                display: flex;
                gap: 8px;
              }
              &-icon {
                width: 40px;
                height: 40px;
                background-color: $blue-selected-4;
              }
  
              &-title {
                display: flex;
                flex-direction: column;
  
                .title_horario{
                  line-height: 16px;
                  color: $gray-125;
                }
              }
  
              &-iconMore {
                margin-left: auto;
                cursor: pointer;
              }
            }
          }
        }
      }

      .comment-history-content-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
  
        .comment-history-content-buttons-attachment-list {
          display: flex;
          align-items: center;
          gap: 8px;
          flex-grow: 1;
          border-top: 1px solid $gray-175;
          padding-top: 8px;
  
          .comment-history-content-buttons-attachment-list__attachment {
            display: flex;
            align-items: center;
          }
        }
      }

      &_editButtons {
        display: flex;
        gap: 16px;

        button {
          display: flex;
          align-items: center;
          gap: 8px;

          path {
            stroke: $blue-selected-4;
          }
        }
      }

      &_inputComment {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0px;

        width: 453px;

        .inputComment-principal {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 16px;

          box-sizing: border-box;
          border-radius: 8px;
          height: 54px
        }

        .buttons-action {
          display: flex;
          align-self: flex-end;
          gap: 16px;

          &__cancelButton {
            color: $red-2;
          }

          &__sendButton {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;

            background: $blue-selected-4;
            border-radius: 24px;

            color: $white;

          
          }

          &__sendButton:disabled{
            cursor: default;
            background: $gray-150;
          }

          &__sendButton h6,
          &__cancelButton h6 {
            font-weight: 500;
            line-height: 20px;
            display: flex;
            align-items: center;
          }
        }
      }

      &_audioComment{
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        width: 453px;

        background: $white;

        border: 1.5px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        border-radius: 8px;

        .history-content_audioComment{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 12px;

          &__recordBar{
            width: 325px;
            height: 6px;

            background: rgba(0, 0, 0, 0.1);
            border-radius: 18px;
          }

          &__timeProgress{
            font-size: 10px;
            line-height: 16px;
            display: flex;
            align-items: center;

            color: $gray-180;
          }
        }
      }
    }
  
    .comment-history-carousel {
      background-color: $white;
    }
  
    .comment-history-content__files {
      display: flex;
      justify-content: space-between;
    }
  
    .comment-attachment {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-top: 1px solid $gray-175;
      padding-top: 8px;
  
      .comment-attachment-container {
        display: flex;
        justify-content: space-between;
  
        > svg {
          min-width: 24px;
          height: 24px;
          fill: #bcbfd0;
          cursor: pointer;
        }
  
        .file-preview {
          width: 100%;
        }
      }
    }
  }

  .description-history-container {
    display: none;
    flex-direction: column;
    gap: 8px;

    &.active {
      display: flex;
    }

    .description-history-content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .description-history {
        display: flex;
        background-color: $white;
        border: 1px solid $gray-175;
        border-radius: 8px;
        padding: 16px;
        word-break: break-word;
      }
    }
  }
}
