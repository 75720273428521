@import "./colors";

.icon {

}


.icon-fill--gray-200 {
  fill: $gray-200;
}


.icon-stroke--gray-200 {
  stroke: $gray-200;
}