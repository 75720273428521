@import "./../../../styles/colors";
@import "./../../../styles/animation";

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 100px;

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    &__right-icon,
    &__left-icon {
      position: absolute;
      display: flex;
      width: 24px;
      height: 24px;

      &.noBreadth {
        width: fit-content;
      }

      &.withSpacing {
        gap: 5px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__right-icon {
      right: 16px;

      &.rightIcon-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: right;
        color: $gray-180;
      }
    }

    &__left-icon {
      left: 16px;
    }

    &__input {
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 0px;
      height: 38px;
      padding: 12px 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      color: $gray-400;
      transition: all 0.25s;

      &--left-icon {
        padding-left: 52px;
      }

      &--right-icon {
        padding-right: 56px;
      }

      &::placeholder {
        color: $gray-175;
        font-size: 14px;
      }

      // &:focus {
      //   border-color: $blue-selected-4;
      // }

      &:disabled {
        border-color: $gray-75;
        background-color: #d6d6d6;
        // background-color: $gray-75;
      }
    }

    // input::-webkit-outer-spin-button,
    // input::-webkit-inner-spin-button {
    //   -webkit-appearance: none;
    //   margin: 0;
    // }

    // input[type="number"] {
    //   -moz-appearance: textfield;
    // }
  }

  .input-msg {
    position: absolute;
    bottom: -20px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 8px;
    animation: fadeIn 0.5s;
    @include mixinFadeIn;

    &__icon {
      display: flex;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &--error {
      .input-msg {
        &__title {
          color: $red;
        }

        &__icon {
          svg {
            stroke: $red;
          }
        }
      }
    }
  }

  &--textarea {
    resize: vertical;
    height: 75px;
    // border: 1px solid $gray-200;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px 16px;
    border-radius: 4px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: $gray-400;
    transition: all 0.25s;

    &.input-container--error {
      border-color: $red;
    }

    &::placeholder {
      color: $gray-175;
    }

    &:focus {
      outline: none;
      border-color: $primary-dark;
    }
  }

  &--password {
    .input-wrapper {
      &__right-icon {
        svg {
          stroke: $gray-200;
          transition: all 0.25s;
        }

        &:focus-visible {
          outline: none;
          svg {
            stroke: $gray-400;
          }
        }

        &.active {
          svg {
            stroke: $primary-dark;
          }
        }
      }
    }
  }

  &--error {
    .input-wrapper__input {
      border-color: $red;
    }
  }

  &--alert {
    .input-wrapper__input {
      border-color: rgb(255, 183, 77);
    }
  }
}

// .input {
//   display: flex;
//   align-items: center;
//   border: 1px solid $gray-100;
//   border-radius: 4px;
//   padding: 0 16px;
//   height: 40px;

//   color: $gray-300;
//   font-family: "Poppins", sans-serif;
//   font-size: 12px;
//   line-height: 18px;

//   &--textarea {
//     resize: vertical;
//     height: 75px;
//   }

//   &:focus {
//     border-color: $primary-dark;
//   }

//   &::placeholder {
//     color: $gray-175;
//   }
// }
