.page-split {
  display: flex;
  height: 100vh;

  &__section {
    display: flex;

    &--a {
      flex-basis: 33%;
      flex-grow: 1;
      width: 0;
    }

    &--b {
      flex-basis: 66%;
      flex-grow: 1;
      width: 0;
    }
  }
}
