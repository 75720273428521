@import "./../../../../styles/colors";

.modal-update-sla {
    // overflow: auto;

    .container-footer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        position: absolute;
        // width: 206px;
        height: 40px;
        right: 15px;
        top: 585px;
    }

    .modal {
        &__body {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        &__footer {
            justify-content: flex-end;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        position: absolute;
        width: 323px;
        height: 384px;
        left: 24px;
        top: 80px;
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        display: flex;
        background: $white;

        border: 1px solid $red-2;
        border-radius: 5px;

        &:hover {
            background: $white-50;
        }
    }
}
