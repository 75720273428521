@import "./../../../../styles/colors";

.typography-h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;

  // WEIGHTS
  &--bold {
    font-weight: bold;
  }

  &--500 {
    font-weight: 500;
  }

  &--700 {
    font-weight: 700;
  }

  // COLORS
  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-180 {
    color: $gray-180;
  }

  &--color-white {
    color: $white;
  }

  &--color-gray-500 {
    color: $gray-500;
  }

  &--color-gray-600 {
    color: $gray-600;
  }

  &--color-blue-selected-4 {
    color: $blue-selected-4;
  }
}
