@import "./../../../../styles/colors";

.modal-update-status {
    .modal {
        &__body {
        }

        &__footer {
            justify-content: flex-end;
            padding-top: 1rem;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        gap: 12px;

        &__select-status {
            padding-top: 1rem;
        }

        .color-select {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            .color-preview {
                width: 36px;
                height: 36px;
                border: 2px solid #ccc;
                border-radius: 4px;
                margin-right: 10px;
                transition: border-color 0.3s;

                &:hover {
                    border-color: #007bff;
                }
            }

            .color-picker {
                position: fixed; 
                top: 55%; 
                left: 50%; 
                transform: translate(-38%, -20%); 
                z-index: 9999; 
                border: 1px solid #ddd;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                background-color: white;
                padding: 10px;
            }
        }
    }

    &__btn-confirm {
        display: flex;
    }

    &__btn-cancel.btn-primary {
        background: $white;
        border: 1px solid $red-2;

        &:hover {
            background: $white-50;
        }
    }
}

@media screen and (max-width: 700px) {
    .ReactModal__Body--open {
        overflow: hidden;
    }
    .modal-change-sla {
        width: 100%;
        height: 100%;

        &__content {
        }
    }
}
