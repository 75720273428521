.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-404 {
      margin-bottom: 18px;
    }

    .title-404 {
      margin-bottom: 4px;
    }
  }
}
