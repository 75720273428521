@import "./../../../../styles/colors";

.modal-edit-variable{
  display: flex;
  // justify-content: space-between;
  gap: 24px;

  .modal__body{
    height: 70%;
  }

  &_main{
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;

    
    .field-container:first-of-type{
      gap: 8px;
      height: calc(100% - 200px);

      .modal-edit-variable_main__message{
        font-family: "Inter";
        font-size: 16px;
        line-height: 24px;
        color: #757575;

        height: 100%;
        max-height: 90%;
        overflow: auto;
        padding: 12px 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        span{
          background-color: rgb(69 92 199 / 20%);
          
        }
      }
    }


    .container-variables-box{
      padding-right: 16px;

      .field-container__header{
        margin-bottom: 24px;
      }

      .variables-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .input-container  {
          width: 80%;
        }
      }
    }

  }

  .modal__footer{
    align-self: flex-end;
    margin-top: auto;
  }
}

@media screen and (max-width: 700px) {
  .ReactModal__Body--open{
    overflow: hidden;
  }
  .modal-edit-variable {
    width: 100%;
    height: 100%;

    .modal__body{
      height: 80%;

      .modal-edit-variable_main{
        .field-container{
          width: 100%;
        }

        .container-variables-box .variables-box .input-container {
          width: 75%;
        }
      }
    }

   
  }
    
  

  
}
