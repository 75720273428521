@import "./../../../styles/colors";

.column-attach {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  border-radius: 8px;
  //margin: 0 32px;

  &.column-attach--limit {
    max-height: 80px;
    overflow: auto;
  }

  .column-attach-list {
    display: flex;
    flex-direction: column;
    // padding: 0 16px;
    gap: 4px;
    width: 100%;

    .column-attach-list-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      padding: 8px 0;
      width: 100%;
      border-top: 1px solid #d9dae1;

      &_text-icon{
        display: flex;
        gap: 5px;
      }

      svg {
        cursor: pointer;
      }

      &__text {
        max-width: 150px;
        // width: 65px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
      }

      &__icon {
        fill: $red;
        width: 18px;
        height: 18px;
      }
    }
  }

  > svg {
    fill: $gray-100;
    min-width: 24px;
  }

  .column-attach__icon {
    fill: $gray-200;
    cursor: pointer;
  }
}
