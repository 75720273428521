@import "./../../../../styles/colors";

.typography-paragraph {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  &.editButtonsTypography {
    line-height: 112.5%;
    letter-spacing: 0.005em;
  }

  //FONT-CHANGES
  &--date-font {
    font-size: 14px;
    line-height: 20px;
  }

  // WEIGHTS
  &--500 {
    font-weight: 500;
  }

  &--bold {
    font-weight: bold;
  }

  // COLORS
  &--color-white {
    color: $white;
  }

  &--color-gray-50 {
    color: $gray-50;
  }

  &--color-gray-125 {
    color: $gray-125;
  }

  &--color-gray-200 {
    color: $gray-200;
  }

  &--color-gray-180 {
    color: $gray-180;
  }

  &--color-gray-300 {
    color: $gray-300;
  }

  &--color-gray-400 {
    color: $gray-400;
  }

  &--color-primary-dark {
    color: $primary-dark;
  }

  &--color-blue-selected-4 {
    color: $blue-selected-4;
  }

  &--color-blue-selected-5 {
    color: $blue-selected-5;
  }
}
