@import "./../../../styles/colors";

.reminder-list {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-top: 22px;

  > svg {
    width: 20px;
    height: 20px;
  }

  &.active {
    svg {
      fill: $primary-dark;
    }
  }
}

.reminder-list-popover {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 11px 7px;
  width: 320px;
  background-color: $white;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >svg {
      fill: $gray-85;
      cursor: pointer;
    }
  }

  &__dates {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 128px;
    overflow-y: auto;
  }

  >button {
    align-self: flex-start;
  }

  &__empty-state {
    display: flex;
    gap: 10px;
  }
}
