@import "./../../../styles/colors";

.page-cases-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 12px 12px 12px;
  gap: 16px;
  background-color: $white;

  .page-title__title {
    margin-right: unset;
    line-height: 28px;
  }

  .header-cases-list {
    z-index: 20;
    position: sticky;
    top: 0;
    padding-top: 12px;
    // margin-bottom: 36px;
    background-color: $white;

    .btn-more-filters {
      &__content {
        display: flex;
        align-items: center;
        gap: 8px;

        .total-filters {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          background-color: #f2bd00;

          span {
            color: #131313;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            margin-top: 3px;
          }
        }
      }
    }

    &__left-content {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
    }

    &__input-search {
      // flex-grow: 100;
      flex-shrink: 0;
      flex-basis: 280px;

      input::placeholder {
        font-size: 14px;
      }
    }

    &__btn-filter {
      width: 48px;
      height: 48px;
      border: 1px solid $gray-100;
      border-radius: 5px;
      margin-left: 8px;

      > svg {
        width: 24px;
        height: 24px;
        fill: $primary-light;
      }
    }

    &__date-range-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;

      .date-range-custom {
        flex-basis: 0;
        display: flex;
        min-width: 170px;

        .react-datepicker-wrapper {
          flex-grow: 1;

          .react-datepicker__input-container {
            display: flex;

            .input-date-range {
              flex-grow: 1;
              width: unset;
            }
          }
        }
      }
    }

    &__views-buttons {
      display: flex;
      gap: 8px;
      margin-left: auto;

      .btn-views-tabels {
        border: none;
        background-color: $white;
        padding: 8px;
        border-radius: 39px;

        &.selected {
          background-color: #ebf2ff;

          line {
            stroke: $blue-selected-4;
          }
        }
      }
    }

    &__right-content {
      align-self: flex-start;
      display: flex;
      gap: 30px;
    }

    .preview-options-cases {
      display: flex;
      align-items: center;
      gap: 12px;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;

        > svg {
          fill: $primary-dark;
        }

        &.active {
          background-color: $primary-dark;

          > svg {
            fill: $white;
          }
        }
      }
    }

    &__types-views {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .preview-option {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
          width: 40px;
          height: 40px;
          background: #ebf2ff;
          border-radius: 39px;
          transition: all 0.25s;

          > svg {
            rect {
              stroke: $blue-selected-4;
            }

            line {
              stroke: $blue-selected-4;
            }
          }
        }
      }

      .preview-option-splitView {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
          width: 40px;
          height: 40px;
          background: #ebf2ff;
          border-radius: 39px;
          transition: all 0.25s;

          > svg {
            rect {
              fill: $blue-selected-4;
            }

            line {
              stroke: $blue-selected-4;
            }
          }
        }
      }
    }

    &__three-dots {
      &__add-cases {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 8px 12px;
        border-top: 1px solid #0000001a;
        &__text {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #757575;
        }
      }
      &__list-view {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 8px 12px;

        .preview-option {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active {
            transition: all 0.25s;

            > svg {
              rect {
                stroke: $blue-selected-4;
              }

              line {
                stroke: $blue-selected-4;
              }
            }
          }
        }
        &__text {
          font-family: "Inter", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #757575;
          &.active {
            color: #455cc7;
          }
        }

        .preview-option-splitView {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active {
            transition: all 0.25s;

            > svg {
              rect {
                fill: $blue-selected-4;
              }

              line {
                stroke: $blue-selected-4;
              }
            }
          }
        }
      }
    }
  }

  .cases-list-separator {
    width: 100%;
    border: 1px solid #f4f4f4;
  }

  .container-sections-cases {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .container-sections-cases-filters-clean {
      margin-top: -5px;
      padding: 7px;

      .clean-icon {
        path {
          fill: $red-2;
        }
      }
    }

    .container-sections-cases-filters {
      justify-content: start;
      align-items: center;
      display: flex;
      margin-top: 2px;
      margin-left: 8px;
      flex-wrap: wrap;

      &.padding {
        padding: 0 10px;
      }
    }

    .loading-cases-list {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .empty-state-cases-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10vh 0;

      &__img {
        margin-bottom: 15px;
      }
    }

    .section-cases-filters {
      display: flex;
      justify-content: space-between;

      .section-cases-filters-status {
        // margin-left: auto;
      }

      .section-cases-filters-actions {
        display: flex;
        position: relative;
        align-items: center;
        gap: 10px;

        .excel-table {
          cursor: pointer;
          height: 24px;
        }

        .popover {
          position: absolute;
          top: 35px;
          left: 50%;
          transform: translateX(-50%);
          background: linear-gradient(135deg, #455cc7, #a3bffa);
          border-radius: 8px;
          padding: 16px;
          color: white;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          width: 100%;

          .popover-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .popover-header h3 {
              margin: 0;
              font-size: 18px;
              text-align: center;
            }

            .popover-buttons {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-top: 16px;
              width: 100%;

              button {
                background: white;
                border: none;
                border-radius: 4px;
                color: #6e8efb;
                padding: 8px 16px;
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                  background: rgba(255, 255, 255, 0.8);
                }
              }
            }
          }
        }
      }

      .section-cases-filters-columns {
        .btn-filters-columns {
          background-color: $white;
          border: none;
        }
      }
    }

    .section-cases-list {
      display: block;
      flex-grow: 1;
      height: 0;
      overflow: auto;

      .table-cases {
        .case-btn-options {
          > svg {
            transform: rotate(270deg);
          }
        }
      }
    }

    .section-cases-kanban {
      flex: 1 1;
      height: 100%;
      width: 100%;
      // padding-top: 20px;
      overflow-x: auto;
    }

    .section-cases-splitView {
      display: flex;
      flex-grow: 1;
      height: 0;
      overflow: auto;
    }
  }

  .manager-container__level-service__progress {
    display: flex;
    flex-direction: column;
    width: 100%;

    .progress-bar {
      height: 12px;
      position: relative;
      /* padding: 5px; */
      display: flex;
      background-color: #ccc;
      width: 100%;
      border-radius: 19px;
    }

    .progress-bar::before {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      content: "";
      width: var(--width);
      border-radius: 19px;
      transition: 1s;
      transition-delay: 1s;
    }

    .progress-bar.green::before {
      background-image: linear-gradient(90deg, #008908 97.11%, #ccc 100%);
    }
    .progress-bar.yellow::before {
      background-image: linear-gradient(90deg, #f2bd00 97.11%, #ccc 100%);
    }
    .progress-bar.orange::before {
      background-image: linear-gradient(90deg, #ff6b00 97.11%, #ccc 100%);
    }
    .progress-bar.red::before {
      background-image: linear-gradient(90deg, #c90000 97.11%, #ccc 100%);
    }

    .progress-bar__title {
      display: flex;
      margin-left: var(--level);
      position: absolute;
      margin-top: -2px;
      transition: 2s;
      transition-delay: 2s;

      .typography-h5 {
        font-size: 8px;
        line-height: 16px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .page-cases-list {
    .header-cases-list {
      margin-bottom: 18px;

      &__left-content {
        gap: 16px;
      }

      &__input-search {
        flex-basis: 28%;
      }

      &__date-range-container {
        // width: 26%;
        gap: 8px;
      }

      &__views-buttons {
        > button {
          display: none;
        }
      }

      .btn-more-filters {
        padding: 0;
        border: none;
        border-radius: 0;

        .typography-h5 {
          display: none;
        }
      }

      .btn-create-case {
        padding: 0;
        border: none;
        border-radius: 0;
        background-color: #fff;

        > svg {
          stroke: $blue-selected-4;
        }

        .typography-h5 {
          display: none;
        }
      }
    }

    .page-title__title {
      margin-right: 100%;
    }
    .header-cases-list__input-search {
      //margin-right: 40px;
    }

    .section-cases-filters-columns {
      display: flex;
      align-items: flex-end;
    }
  }
}
