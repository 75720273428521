@import "./../../../styles/mixins";
@import "./../../../styles/colors";
@import "../../../styles//animation";

.tabulated-table {
    width: 100%;

    .flex-container {
        display: flex;
        gap: 4px;

        &.justify-center {
            justify-content: center;
        }

        &.align-center {
            align-items: center;
        }

        .iconHeader-showup {
            display: block;

            &:hover {
                display: none;
            }
        }

        &.none {
            display: none;
        }
    }

    thead {
        height: 48px;
        background-color: $blue-selected-4;
        user-select: none;

        > tr {
            th {
                padding: 14px;
                width: auto;
                // width: 9.5%;

                &:first-child {
                    border-radius: 8px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 8px 0 0;
                }

                &:hover {
                    .order-button__icon_display {
                        display: block;
                        opacity: 0.6;
                        transition: 0.2s;
                    }
                }
            }
        }
    }

    tbody {
        > tr {
            &:nth-child(even) {
                background-color: $gray-50;
            }

            &.darkModeRow {
                box-shadow: inset 0px -1px 0px rgba(229, 231, 235, 0.6);
                &:nth-child(even) {
                    background-color: transparent;
                }
            }

            > td {
                height: 44px;
                padding: 10px;
                // text-align: center;

                &.none {
                    padding: 0px;
                    width: 0;
                }
            }
        }

        td {
            > .cell-container {
                display: flex;

                &.justify-center {
                    justify-content: center;
                }

                &.none {
                    display: none;
                }

                h6 {
                    white-space: nowrap;
                }
            }

            &.cell-ellipsis {
                .typography-h6 {
                    flex-grow: 1;
                    display: block;
                    width: 0;
                    @include text-overflow;
                }
            }

            &.cell-width {
                width: 161px;
            }

            &.cell-red-text {
                .typography-h6 {
                    color: $red;
                }
            }

            &.cell-blue-text {
                .typography-h6 {
                    color: $blue-selected-4;
                }
            }
        }
    }

    &--fixed-first-column {
        thead {
            tr {
                th {
                    &:first-of-type {
                        position: sticky;
                        left: -1px;
                        z-index: 10;
                        background-color: $blue-selected-4;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &:first-of-type {
                        position: sticky;
                        left: -1px;
                        z-index: 5;
                    }
                }
            }
        }
    }

    &--fixed-header {
        thead {
            position: sticky;
            top: -1px;
            z-index: 9;
        }
    }

    .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;

        background: #455cc7;
    }
}

.pagination-table {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    /* z-index: 9; */
    bottom: -1px;
    left: 1px;
    background: #fff;

    &.dark-mode {
        background: $secondary-06-dark;

        .MuiTablePagination-selectLabel {
            color: $white;
        }

        .MuiTablePagination-select.MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
            color: $white;
        }

        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
            color: $white;
        }

        .MuiTablePagination-displayedRows {
            color: $white;
        }

        .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text {
            color: $white;
        }
    }

    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
        padding: 0;
    }

    .MuiTablePagination-selectLabel {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        text-align: right;
        color: #757575;
        font-size: 12px;
        line-height: 16px;
    }

    .MuiInputBase-root.MuiInputBase-colorPrimary {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 0;
    }

    .MuiTablePagination-displayedRows {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-textPrimary.MuiPaginationItem-page {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &.Mui-disabled {
            color: #757575;
        }

        &.Mui-selected {
            color: #ffffff;

            background: #455cc7;
        }
    }

    .MuiPagination-ul {
        flex-wrap: nowrap;
    }

    .react-pagination-container {
        display: flex;
        gap: 15px;
        padding-right: 1rem;

        .page-item-previou {
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "Inter";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            fill: $gray-300;
            cursor: pointer;

            &.disabled {
                fill: $gray-150;
            }

            .page-link {
                > svg {
                    transform: rotate(90deg);
                }
            }
        }

        .page-item-next {
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "Inter";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            fill: $gray-300;
            cursor: pointer;

            &.disabled {
                fill: $gray-150;
            }

            .page-link {
                > svg {
                    transform: rotate(270deg);
                }
            }
        }

        .page-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
            cursor: pointer;

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            &.active {
                height: 30px;
                width: fit-content;
                border-radius: 999px;
                background-color: $blue-selected-4;
                color: $white;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .table {
        &--fixed-first-column {
            thead {
                tr {
                    th {
                        &:first-of-type {
                            position: relative;
                            left: 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &:first-of-type {
                            position: relative;
                            left: 0;
                        }
                    }
                }
            }
        }

        &--fixed-header {
            thead {
                position: relative;
                top: 0;
            }
        }
    }

    .pagination-table {
        .MuiTablePagination-displayedRows {
            display: none;
        }
    }
}
