@import "./../../../styles/colors";

.create-task {
  padding: 16px;
  border-radius: 6px;

  &__header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 12px;
  }

  .create-task-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__client {
      display: flex;
      align-items: center;
      gap: 16px;

      > img {
        width: 32px;
        height: 32px;
      }
  
      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $primary-dark;
      }
    }

    &__client-inputs {
      display: flex;
      gap: 8px;
      width: 100%;

      .field-container {
        width: 100%;
      }

    }
    .create-task-information {
      display: flex;
      gap: 90px;
  
      .date-details {
        margin-bottom: 8px;
      }
  
      &__tags-profile {
        display: flex;
        flex-direction: column;
        gap: 11px;
  
        .create-task-information__tags {
          display: flex;
          gap: 4px;
        }
      }
    }

    > button {
      height: 45px;
    }
  }
}