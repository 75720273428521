@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.select-search-item {
    position: relative;
    width: 100%;

    .input-search-enterprises {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 12px 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #27282e;
        transition: all 0.25s;
    }

    .select-btn {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // width: inherit;
        // height: 40px;
        // padding: 12px 16px;
        // border-radius: 5px;
        // background-color: #ffffff;
        // text-align: left;
        // white-space: nowrap;
        // transition: all 0.25s;
        // cursor: text;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
        width: inherit;
        width: 100%;
        height: 38px;
        padding: 4px 8px;
        border-radius: 5px;
        cursor: pointer;
        transition: border-radius 0.25s;
        background: $white;
        border: 1px solid $gray-125;

        &__input {
            flex-grow: 1;
            display: flex;
            border: none;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            line-height: 24px;
            color: $gray-400;
            max-width: calc(100% - 28px);

            &::placeholder {
                color: $gray-125;
            }
        }

        .input-container {
            width: 100%;

            input {
                border: none;
                height: 0;
            }
        }

        .option-content {
            // flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .option-labelResume {
                max-width: calc(100% - 24px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .typography-h6 {
            flex-grow: 1;
            width: 0px;
            @include text-overflow;
        }

        &.active {
            border: 1px solid $blue-selected-4;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: #e8e8e9;
            // padding: 0;

            // border: 1px solid #455cc7;
            // border-radius: 5px;

            svg {
                // transform: rotate(180deg);
            }
        }

        &--error {
            border-color: $red;
        }

        &--disabled {
            border-color: $gray-75;
            background-color: #d6d6d6;
            cursor: default;
        }

        &.active {
            > svg {
                transform: rotate(180deg);
            }
        }
    }

    .select-list {
        position: absolute;
        z-index: 15;
        display: flex;
        flex-direction: column;
        width: inherit;
        max-height: 160px;
        border: 1px solid $gray-200;
        border-radius: 0 0 5px 5px;
        background-color: #fff;
        overflow: auto;
        top: 30px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.25s, opacity 0.25s, top 0.25s;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-height: min-content;
            padding: 12px;
            cursor: pointer;
            text-align: left;

            &:hover,
            &.active {
                background-color: $blue-selected-3;
            }

            &.loadOnDemand {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-bottom: 3px solid rgba(0, 0, 0, 0.1);
                transition-property: font-size;
            }
        }

        &.active {
            border: 1px solid $blue-selected-4;
            top: 40px;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.25s, top 0.25s;

            // border: 1px solid #455cc7;
            // border-radius: 5px;
            // margin-top: 10px;

            .input-container {
                margin: 8px;
            }
        }

        &--error {
            border-color: $red;
        }
    }

    &--center {
        .select-btn {
            text-align: center;

            > span {
                flex-grow: 1;
                text-align: center;
            }
        }

        .select-list {
            &__item {
                flex-grow: 1;
                justify-content: center;
            }

            &--empty {
                pointer-events: none;
            }
        }
    }

    &--upwards {
        .select-btn {
            &.active {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top-color: #455cc7;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-bottom-color: $gray-100;

                .select-btn__icon {
                    transform: rotate(90deg) scaleX(-1);
                }
            }
        }

        .select-list {
            bottom: 28px;
            top: unset;
            flex-direction: column-reverse;
            border-top: 1px solid $gray-100;
            border-bottom: 0;
            border-radius: 5px 5px 0px 0px;

            &.active {
                bottom: 28px;
                top: unset;
                // top: -158px;
            }
        }
    }

    &--small {
        width: 140px;
    }

    &--medium {
        width: 280px;
    }

    &--large {
        width: 560px;
    }
}
