@import "./../../../styles/colors";

.dropdown-channel {
  position: relative;
  width: 130px;

  &.advanced-filters-case__channel {
    width: 100%;

    .dropdown-channel-btn {
      border: 1px solid $gray-125;
    }
  }

  .dropdown-channel-btn {
    width: inherit;
    height: 38px;
    border-radius: 4px;
    background-color: #ffffff;
    cursor: pointer;
    transition: border-radius 0.25s;
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    .dropdown-channel-btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      // padding: 8px;
      padding: 0px 8px;

      > svg {
        width: 20px;
        height: 20px;
      }

      &__icon {
        height: 20px;
        > svg {
          width: 20px;
          height: 20px;
        }
      }

      &__content {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

      .dropdown-channel-btn-container {
        &__icon {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .dropdown-channel-list {
    position: absolute;
    z-index: 15;
    display: flex;
    flex-direction: column;
    // gap: 8px;
    width: 100%;
    height: 150px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    // padding: 0 8px 8px 8px;
    overflow: auto;
    top: 15px;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s 0.25s,
      opacity 0.25s,
      top 0.25s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    &__input {
      padding: 8px;
    }

    &__empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 10px;

      > h5 {
        text-align: center;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      min-height: 24px;
      gap: 8px;
      padding: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      cursor: pointer;
      text-align: center;

      &:hover,
      &.active {
        background-color: $gray-75;
      }
    }

    &__item:last-child {
      border-bottom: 0;
    }

    &.active {
      // top: 39px;
      top: calc(100% + 6px);
      visibility: visible;
      opacity: 1;
      transition:
        opacity 0.25s,
        top 0.25s;
    }
  }

  &--error {
    .dropdown-channel-btn {
      border: 1px solid $red;
    }

    .dropdown-channel-list {
      border: 1px solid $red;
      border-top: none;
    }
  }
}

.text-channel {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 145px;
}

.text-channel-extern {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 94px;
}

.text-select-channel-extern {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 94px;
}
