@import "./../../../../styles/colors";

.modal-export-cases {
    .export-cases-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .export-cases-switch {
            display: flex;
            gap: 10px;
        }
    }

    .modal__footer {
        flex-direction: row-reverse;
        padding: 10px;
    }

    &__btn-cancel.btn-primary {
        background: $white;
        border: 1px solid $red-2;

        &:hover {
            background: $white-50;
        }
    }
}
