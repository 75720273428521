@import "./../../../styles/colors";

.MuiList-root.MuiList-padding.MuiMenu-list{
    .input-container{
        margin: 8px;
    }
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl{
    border-radius: 5px;
}

.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input{
    padding: 8.5px 16px;

    .multiple-chip-list{
        display: flex;
        flex-wrap: wrap;
    }
}

.MuiSelect-icon.MuiSelect-iconOutlined {
    right: 10px;
    top: calc(50% - 0.7em);
}

.menu-item{
    display: flex;
    align-items: center;
    cursor: pointer;

    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;

    gap: 6px;
    padding: 10px;

    &.priority-item{
        padding: 6px 10px;
    }

    &:hover{
        background-color: $blue-selected-3;
    }

    &.active{
        background-color: $blue-selected-3;
    }
}