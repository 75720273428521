@import "./../../../styles/colors";
@import "./../../../styles/mixins";

.custom-select {
    position: relative;
    width: 100%;

    .search-input-container {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        &--error {
            .search-input {
                border-color: $red;
            }
        }

        .search-icon {
            display: flex;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            cursor: pointer;
            font-size: 1.2em;
            color: #333;
            transition: color 0.3s ease;
        }
    }

    .search-input {
        width: 100%;
        // padding: 8px;
        padding: 0px 34px 0px 8px;
        height: 38px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        outline: none;
        cursor: auto;

        font-family: "Inter", sans-serif;
        font-size: 14px;
        line-height: 24px;
        color: #27282e;

        &.blue-border {
            border-color: $blue-selected-4;
        }

        &.error {
            border-color: $red;
        }

        &::placeholder {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            color: $gray-175;
        }

        &:disabled {
            background-color: #dbdde2;
            border: 1px solid #dbdde2;
        }
    }

    .options {
        position: absolute;
        // top: 100%;
        top: calc(100% + 6px);
        left: 0;
        right: 0;
        // border: 1px solid rgba(0, 0, 0, 0.1);
        border: 1px solid #828292;
        // border-top: none;
        // border-radius: 0 0 4px 4px;
        border-radius: 5px;
        background-color: #fff;
        max-height: 200px;
        overflow-y: auto;
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 1;
        opacity: 0;
        transition:
            max-height 0.5s ease,
            opacity 0.5s ease;

        &__label {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__item {
            padding: 8px;
            cursor: pointer;

            &:hover {
                background-color: $blue-selected-3;
            }

            &.selected {
                background-color: $blue-selected-3;
            }
        }

        &__more-information {
            display: flex; 
            gap: 8px;
        }

        .loading {
            padding: 8px;
            text-align: center;
            color: #888;

            // NOVO
            .loader {
                border: 4px solid #f3f3f3;
                border-top: 4px solid #3498db;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                animation: spin 2s linear infinite;
                margin: 0 auto;
                margin-top: 10px;
            }
        }

        .empty-state {
            padding: 8px;
            text-align: center;
            color: #888;
        }

        .custom-empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px 6px;
            gap: 10px;

            .empty-state-icon {
                background-color: #ebf2ff;
                padding: 36px;
                border-radius: 50%;
            }

            .customEmptyStateMessage {
                text-align: center;
            }
        }

        .load-more {
            padding: 8px;
            text-align: center;
            color: $blue-selected-4;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                background-color: $blue-selected-3;
            }
        }
    }

    &.open .options {
        max-height: 200px;
        opacity: 1;
    }

    &.open .options.custom-empty-options {
        max-height: none;
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
